import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUser } from "../../firebase/document-types";

interface UserState {
  user: null | AdminUser;
}

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AdminUser>) => {
      state.user = action.payload;
    },
    removeUser: () => {
      return { user: null };
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
