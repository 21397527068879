import React from "react";
import clsx from "clsx";
import { Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    textTransform: "none",
    fontSize: "0.8rem",
    minWidth: "unset",
  },
  selected: {
    backgroundColor: "#FF631980",
  },
};

const StyledTab = props => {
  const { classes, children, className, ...other } = props;

  return (
    <Tab
      className={clsx(
        classes.root,
        props.selected ? classes.selected : null,
        className,
      )}
      {...other}>
      {children}
    </Tab>
  );
};

export default withStyles(styles)(StyledTab);
