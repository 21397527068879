import React from "react";

/**
 * Renders a button with a "plus circle" icon before the text.
 * https://icons.getbootstrap.com/icons/plus-circle/
 * @param {*} children children components/elements, usually just text
 * @param {*} onClick onClick handler
 * @param {*} size Size of the plus circle icon, defaults to 20
 * @param {*} className Extra classes for the button element
 * @returns
 */
const AddButton = ({ children, onClick, size = 20, className = "" }) => {
  return (
    <button
      onClick={onClick}
      className={"btn add-btn d-flex align-items-center " + className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="currentColor"
        className="bi bi-plus-circle me-2"
        viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg>
      {children}
    </button>
  );
};

export default AddButton;
