import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { db, largeCustomerTransactionPath } from "../../firebase";
import _ from "lodash";
import { latestTargetDocs } from "../util/TargetUtils";
import { ASPHALT_DAMAGE } from "../image/imageClassifications";

const useTargetOffer = targets => {
  const [targetOfferMap, setTargetOfferMap] = useState({});
  const [targetm2Map, setTargetm2Map] = useState({});
  const [targetIds, setTargetIds] = useState([]);
  // kind of lazy way to do a refetch on interval,
  // see useEffect hook with setInterval.
  const [refetch, setRefetch] = useState(false);

  /**
   * Extract target Ids from targets
   */
  useEffect(() => {
    if (!targets) return;
    const ids = targets.reduce((idArr, target) => {
      idArr.push(target.key);
      return idArr;
    }, []);
    if (!_.isEqual(ids, targetIds)) {
      setTargetIds(ids);
    }
  }, [targets, targetIds]);

  /**
   * This is part of the lazy way to do a refetch every 30 seconds,
   * we just need a state variable that changes, which will be in the dependencies
   * of the useEffect hook that actually does the fetching.
   */
  useEffect(() => {
    const intr = setInterval(() => {
      //console.log("Refetching offers");
      setRefetch(!refetch);
    }, 30000);
    return () => clearInterval(intr);
  }, [setRefetch, refetch]);

  /**
   * Fetches the latest transaction with an offer for each target
   */
  useEffect(() => {
    let isNotCanceled = true;

    if (targetIds.length === 0) return;

    const targetRef = collection(db, largeCustomerTransactionPath);
    const idBatches = _.chunk(targetIds, 10);

    let batches = [];
    for (const idBatch of idBatches) {
      const q = query(targetRef, where("target", "in", idBatch));
      batches.push(
        new Promise(response => {
          getDocs(q).then(result =>
            response(
              result.docs.map(doc => {
                return { key: doc.id, ...doc.data() };
              }),
            ),
          );
        }),
      );
    }

    Promise.all(batches).then(content => {
      const fetchedDocs = content.flat();

      const sortedDocs = _.orderBy(fetchedDocs, ["target", "updatedAt"], "asc");
      const latestDocsForTargets = latestTargetDocs(sortedDocs);
      const offers = {};
      const m2s = {};
      for (const latestDoc of latestDocsForTargets) {
        offers[latestDoc.target] = latestDoc?.offer ?? null;
        const m2Sum = latestDoc.pictures.classified.reduce((sum, picture) => {
          if (!picture.rejected && picture.classification === ASPHALT_DAMAGE) {
            const size = parseInt(picture.size);
            if (!isNaN(size)) {
              return sum + size;
            }
          }
          return sum;
        }, 0);
        m2s[latestDoc.target] = m2Sum;
      }
      if (isNotCanceled) {
        setTargetOfferMap(offers);
        setTargetm2Map(m2s);
      }
    });

    return () => {
      isNotCanceled = false;
    };
  }, [targetIds, refetch, setTargetOfferMap, setTargetm2Map]);

  return [targetOfferMap, targetm2Map];
};

export default useTargetOffer;
