import { Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import useCsvImport from "../hooks/useCsvImport";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Papa from "papaparse";
import XLSX from "xlsx";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import {
  db,
  largeCustomerTargetPath,
  userCollectionPath,
} from "../../firebase";
import Loading from "../Loading";
import _ from "lodash";
import ExistingClientChooser from "./ExistingClientChooser";

/**
 * Imports targets from a CSV or excel file
 * Expects no header row
 * @returns
 */
const TargetImportModal = ({ visible = false, onHide, client = null }) => {
  const [file, setFile] = useState(null);
  const [parsedResult, setParsedResult] = useState(null);
  const [parsedTargets, setParsedTargets] = useState(null);
  const [resultContacts, setResultContacts] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const [chosenClient, setChosenClient] = useState(client);

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles[0]) {
        setParsedResult(null);
        setFile(acceptedFiles[0]);
      }
    },
    [setFile, setParsedResult],
  );

  const { getRootProps, getInputProps, isDragActive } = useCsvImport(onDrop);

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, { type: "array" });

      wb.SheetNames.forEach(name => {
        const sheet = wb.Sheets[name];
        const csv = XLSX.utils.sheet_to_csv(sheet, { header: 0 });
        const parsed = Papa.parse(csv);
        // filter invalid rows & remove header row (first row)
        setParsedResult(parsed.data.filter(row => row.length > 3).slice(1));
      });
    };

    reader.readAsArrayBuffer(file);
  }, [file]);

  const parseTarget = useCallback(
    csvRow => {
      const parsedTarget = {
        name: csvRow[0] ?? "",
        address: csvRow[1] ?? "",
        postalCode: csvRow[2] ?? "",
        postalDistrict: csvRow[3] ?? "",
        additionalInfo: csvRow[4] ?? "",
        organization: chosenClient?.key,
        createdAt: Date.now(),
        log: [],
        contact: csvRow[5] ?? "",
      };
      return parsedTarget;
    },
    [chosenClient],
  );

  const createTargets = () => {
    if (submitting) return;
    setSubmitting(true);

    const targets = parsedTargets;
    console.log("Creating targets", targets);
    const targetRef = collection(db, largeCustomerTargetPath);
    try {
      for (const target of targets) {
        // add contact to target
        target.contact = resultContacts[target.contact]?.key ?? "";
        if (!target.organization) {
          target.organization = chosenClient?.key;
        }
        addDoc(targetRef, target);
      }
    } catch (error) {
      console.warn("Error while creating targets", error);
    }
    setFile(null);
    setChosenClient(null);
    setParsedResult(null);
    setParsedTargets(null);
    setResultContacts(null);
    setSubmitting(false);
    onHide();
  };

  const fetchContactsByName = useCallback(
    names => {
      (async () => {
        const uniqueNames = _.uniq(names);
        const nameBatches = _.chunk(uniqueNames, 10);

        const foundContacts = [];

        for await (const nameBatch of nameBatches) {
          const q = query(
            collection(db, userCollectionPath),
            where("roles", "array-contains", "client"),
            where("name", "in", nameBatch),
          );
          const docs = await getDocs(q);

          docs.forEach(doc => {
            const docData = { key: doc.id, ...doc.data() };
            foundContacts[docData.name] = docData;
          });
        }

        setResultContacts(foundContacts);
      })();
    },
    [setResultContacts],
  );

  useEffect(() => {
    if (!parsedResult) return;
    const targets = [];
    const targetNames = [];
    for (const csvRow of parsedResult) {
      const target = parseTarget(csvRow);
      if (target.contact) {
        targetNames.push(target.contact);
      }
      targets.push(target);
    }
    fetchContactsByName(targetNames);

    setParsedTargets(targets);
  }, [parsedResult, parseTarget, setParsedTargets, fetchContactsByName]);

  const contactRow = useCallback(
    name => {
      if (name) {
        if (resultContacts && resultContacts[name]) {
          return (
            <p className="mb-0 text-success">{resultContacts[name].name}</p>
          );
        } else {
          return (
            <p className="mb-0 text-danger">{'"' + name + '"' + " ei löydy"}</p>
          );
        }
      }
      return <p className="mb-0 text-danger">{"Ei asetettu"}</p>;
    },
    [resultContacts],
  );

  return (
    <Modal
      show={visible}
      onHide={() => {
        setChosenClient(null);
        onHide();
      }}
      dialogClassName="modal-90w">
      <Modal.Body>
        <div className="text-center">
          <h4>
            Tuo kohteita{" "}
            {!_.isNil(chosenClient) ? "asiakkaalle " + chosenClient.name : ""}
          </h4>
          <p>
            Excel taulukon sarakkeet:
            <br />
            Nimi, osoite, postinumero, postitoimipaikka, lisätiedot ja
            yhteyshenkilön nimi
            <br />
            Ensimmäinen rivi excelissä voi olla tyhjä tai otsikko rivi, ja sen
            tietoja ei koskaan tulkita kohteeksi.
          </p>
          {!_.isNil(chosenClient) && (
            <button
              className="btn btn-primary"
              onClick={() => {
                setChosenClient(null);
              }}>
              Vaihda asiakas
            </button>
          )}
        </div>
        <Box m={2} />
        {_.isNil(chosenClient) && (
          <>
            <ExistingClientChooser
              onChooseClient={clientDoc => {
                setChosenClient(clientDoc);
              }}
            />
          </>
        )}
        {!_.isNil(chosenClient) && (
          <>
            <div
              className={
                isDragActive
                  ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
                  : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
              }
              {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="input-text">
                Vedä tiedostot tähän tai{" "}
                <span className="link">valitse ne tietokoneestasi</span>
              </p>
            </div>
            <div>
              {parsedTargets && (
                <>
                  <div className="text-center my-3">
                    {!submitting && (
                      <button
                        onClick={() => createTargets()}
                        className="btn btn-primary">
                        Luo kohteet
                      </button>
                    )}
                    {submitting && <Loading />}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nimi</TableCell>
                        <TableCell>Osoite</TableCell>
                        <TableCell>Postinumero</TableCell>
                        <TableCell>Postitoimipaikka</TableCell>
                        <TableCell>Lisätiedot</TableCell>
                        <TableCell>Yhteyshenkilö</TableCell>
                        <TableCell>Asiakas</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedTargets &&
                        parsedTargets.map((target, index) => {
                          return (
                            <TableRow key={"importedTargets" + index}>
                              <TableCell>{target.name}</TableCell>
                              <TableCell>{target.address}</TableCell>
                              <TableCell>{target.postalCode}</TableCell>
                              <TableCell>{target.postalDistrict}</TableCell>
                              <TableCell>{target.additionalInfo}</TableCell>
                              <TableCell>
                                {contactRow(target.contact)}
                              </TableCell>
                              <TableCell>{chosenClient.name}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TargetImportModal;
