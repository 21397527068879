import { FormControlLabel, Switch, TextField } from "@material-ui/core";
import React from "react";
import RemoveIcon from "../RemoveIcon";

/**
 * Email address input wrapper
 * @param {*} emailObject email object for contact {email: "example@examp.le", switchState: false, label: "Työ"}
 * @param {*} index index of the emailObject in the contact data, index 0 can not be removed
 * @param {*} onRemove remove handler
 * @param {*} setEmailObject emailObject update handler
 * @param {*} error Flag to indicate an error in validation
 * @returns
 */
const EmailInput = ({
  emailObject,
  index,
  onRemove,
  setEmailObject,
  error,
}) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row align-items-center justify-content-between">
        {index === 0 && (
          <>
            <FormControlLabel
              label={emailObject.label}
              control={
                <Switch
                  checked={emailObject.switchState}
                  onChange={e => {
                    const newEmail = { ...emailObject };
                    newEmail.switchState = !newEmail.switchState;
                    newEmail.label =
                      newEmail.switchState === true ? "Oma" : "Työ";
                    setEmailObject(newEmail);
                  }}
                />
              }
            />
            <p className="mb-0">Käyttäjätunnus</p>
          </>
        )}
        {index > 0 && (
          <>
            <FormControlLabel
              label={emailObject.label}
              control={
                <Switch
                  checked={emailObject.switchState}
                  onChange={e => {
                    const newEmail = { ...emailObject };
                    newEmail.switchState = !newEmail.switchState;
                    newEmail.label =
                      newEmail.switchState === true ? "Oma" : "Työ";
                    setEmailObject(newEmail);
                  }}
                />
              }
            />
            <RemoveIcon
              size={30}
              onClick={() => {
                onRemove();
              }}
            />
          </>
        )}
      </div>

      <TextField
        error={error}
        helperText={error ? "Tarkista sähköposti" : ""}
        fullWidth={true}
        placeholder="matti@meikalainen.fi"
        value={emailObject.email}
        label="Sähköposti"
        onChange={e => {
          setEmailObject({ ...emailObject, email: e.target.value });
        }}
      />
    </div>
  );
};

export default EmailInput;
