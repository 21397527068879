import { collection, query, where, getDocs } from "firebase/firestore";
import { db, userCollectionPath } from "../firebase";

/**
 * Attempts to return an email labeled "Työ" from contacts email array.
 * @param {*} contact
 * @returns
 */
const contactEmail = contact => {
  if (!contact) {
    throw new Error("You must define a contact");
  }
  if (!contact.emails) return "";
  // attempt to find an email labeled "Työ"
  for (const email of contact.emails) {
    if (email.label === "Työ") {
      return email.email;
    }
  }
  // if we didn't already return, let's attempt to return
  // the first email
  if (contact.emails.length > 0) {
    return contact.emails[0].email;
  }
  return "";
};

/**
 * Attempts to return a number labeled "Työ" from contacts number array.
 * @param {*} contact
 * @returns
 */
const contactPhoneNumber = contact => {
  if (!contact) {
    throw new Error("You must define a contact");
  }
  if (!contact.numbers) return "";
  // attempt to find an number labeled "Työ"
  for (const number of contact.numbers) {
    if (number.label === "Työ") {
      return number.number;
    }
  }
  // if we didn't already return, let's attempt to
  // return the first phone number
  if (contact.numbers.length > 0) {
    return contact.numbers[0].number;
  }
  return "";
};

/**
 * Checks for duplicate user emails.
 * Returns an object with "valid" field, and an "email" field
 * if valid was false, which has the invalid email in it.
 * {valid: false, email: "this@email.com"}
 * @param {*} userEmails array of email addresses
 */
const validUserEmails = async userEmails => {
  return new Promise(async (resolve, reject) => {
    for await (const email of userEmails) {
      const q = query(
        collection(db, userCollectionPath),
        where("email", "==", email),
      );
      const docs = await getDocs(q);
      if (docs.size > 0) {
        return resolve({ valid: false, email: email });
      }
    }
    // if we didn't already resolve with false, resolve with true here
    resolve({ valid: true });
  });
};

export { contactEmail, contactPhoneNumber, validUserEmails };
