import { collection, onSnapshot } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
} from "../../firebase";
import { setClients } from "./largeCustomerSlice";
import { setAllTargets, setAllFilteredTargets } from "./targetSlice";
import AllTargetsTable from "../../components/target/AllTargetsTable";
import Loading from "../../components/Loading";
import { Box, MenuItem, Select, TextField } from "@material-ui/core";
import NewTargetModal from "../../components/target/NewTargetModal";
import { addDoc } from "firebase/firestore";
import useTargetStatus from "../../components/target/useTargetStatus";
import _ from "lodash";
import useTargetOffer from "../../components/target/useTargetOffer";
import { lastLogEntry } from "../../components/logging/logUtils";
import moment from "moment";
import TargetImportModal from "../../components/target/TargetImportModal";
import {
  downloadLatestOffers,
  targetsToExcelData,
} from "../../components/target/targetUtil";
import ContactAutocomplete from "../../components/client/ContactAutocomplete";
import {
  GIVEN_FEEDBACK,
  stateTranslations,
} from "../../components/transaction/TransactionStates";
import { Autocomplete } from "@material-ui/lab";
import MassOfferLinks from "../../components/MassOfferLinks";
import XLSX from "xlsx";

const YEAR_ALL_OPTION = "Kaikki";
const years = [
  ..._.range(2021, moment().year() + 1).map(year => year.toString()),
  YEAR_ALL_OPTION,
];

/**
 * "Kohteet" view
 * @returns
 */
const Targets = () => {
  const { allTargets, filteredAllTargets } = useSelector(state => state.target);
  const { clients } = useSelector(state => state.largeCustomer);
  const dispatch = useDispatch();

  const [clientsFetched, setClientsFetched] = useState(false);
  const [targetsFetched, setTargetsFetched] = useState(false);

  const [targetModalVisible, setTargetModalVisible] = useState(false);
  const [targetSubmitError, setTargetSubmitError] = useState("");
  const [targetSubmitting, setTargetSubmitting] = useState(false);
  const [newTarget, setNewTarget] = useState({
    additionalInfo: "",
    address: "",
    postalCode: "",
    postalDistrict: "",
    contact: null,
    name: "",
    organization: null,
  });

  const [targetImportModalVisible, setTargetImportModalVisible] =
    useState(false);

  const [checkedItems, setCheckedItems] = useState([]);
  const [massDownloadInProgress, setMassDownloadInProgress] = useState(false);

  const [contactFilter, setContactFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState(moment().year().toString());
  const [massOfferLinks, setMassOfferLinks] = useState([]);

  const [massExcelSubmitting, setMassExcelSubmitting] = useState(false);

  useEffect(() => {
    let _filteredTargets = allTargets;
    if (contactFilter) {
      _filteredTargets = _filteredTargets.filter(target => {
        return target.contact.includes(contactFilter);
      });
    }
    if (cityFilter) {
      _filteredTargets = _filteredTargets.filter(target => {
        return target.postalDistrict
          .toLowerCase()
          .includes(cityFilter.toLowerCase());
      });
    }
    if (nameFilter) {
      _filteredTargets = _filteredTargets.filter(target => {
        return target.name.toLowerCase().includes(nameFilter.toLowerCase());
      });
    }

    if (statusFilter.length > 0) {
      _filteredTargets = _filteredTargets.filter(target => {
        return statusFilter.includes(target.latestState);
      });
    }
    if (yearFilter !== YEAR_ALL_OPTION) {
      _filteredTargets = _filteredTargets.filter(target => {
        return (
          moment(target.logTime, "DD.MM.YYYY").year() === Number(yearFilter)
        );
      });
    }
    dispatch(setAllFilteredTargets(_filteredTargets));
  }, [
    dispatch,
    contactFilter,
    nameFilter,
    cityFilter,
    allTargets,
    statusFilter,
    yearFilter,
  ]);

  /**
   * Fetches all targets and clients from firebase
   */
  useEffect(() => {
    const targetUnSub = onSnapshot(
      collection(db, largeCustomerTargetPath),
      docSnapshot => {
        const targetDocs = [];
        docSnapshot.forEach(doc => {
          targetDocs.push({ key: doc.id, ...doc.data() });
        });
        dispatch(setAllTargets(targetDocs));
        setTargetsFetched(true);
      },
      error => {
        console.warn("Error while fetching targets", error);
      },
    );

    const clientUnSub = onSnapshot(
      collection(db, largeCustomerCollectionPath),
      docSnapshot => {
        const clientDocs = [];
        docSnapshot.forEach(doc => {
          clientDocs.push({ key: doc.id, ...doc.data() });
        });
        dispatch(setClients(clientDocs));
        setClientsFetched(true);
      },
      error => {
        console.warn("Error while fetching clients", error);
      },
    );

    return () => {
      targetUnSub();
      clientUnSub();
    };
  }, [dispatch]);

  const targetStateMap = useTargetStatus(allTargets);
  const [targetOfferMap, targetm2Map] = useTargetOffer(allTargets);

  /**
   * Adds extra fields to targets from hook results.
   * In example, adds the latest state of a transaction to a target.
   * These fields can later be sorted in the table component.
   */
  useEffect(() => {
    let isNotCanceled = true;
    const editedTargets = [];
    for (const target of allTargets) {
      const state = targetStateMap[target.key] ?? null;
      const offer = targetOfferMap[target.key] ?? null;
      const m2 = targetm2Map[target.key] ?? null;
      const editedTarget = { ...target };
      // add state to the target data
      editedTarget.latestState = state;
      // add offer to the target data
      if (!_.isNil(offer) && !_.isEmpty(offer)) {
        if (offer.price) {
          editedTarget.latestOfferPrice = offer.price;
        }
        if (offer.startDate && offer.endDate) {
          editedTarget.latestOfferStartDate = offer.startDate;
          editedTarget.latestOfferEndDate = offer.endDate;
        }
      }
      editedTarget.latestOfferm2 = m2;
      const lastLog = lastLogEntry(target);
      if (!_.isNil(lastLog)) {
        editedTarget.logUser = lastLog.user.name;
        editedTarget.logTime = moment(lastLog.time).format("DD.MM.YYYY");
      }
      editedTargets.push(editedTarget);
    }
    if (!_.isEqual(allTargets, editedTargets) && isNotCanceled) {
      dispatch(setAllTargets(editedTargets));
    }
    return () => {
      isNotCanceled = false;
    };
  }, [targetStateMap, targetm2Map, allTargets, targetOfferMap, dispatch]);

  /**
   * New target submit handler
   * @param {*} targetDoc
   * @returns
   */
  const onNewTarget = targetDoc => {
    //console.log("onNewTarget", targetDoc);
    if (targetSubmitting) return;
    setTargetSubmitting(true);
    addDoc(collection(db, largeCustomerTargetPath), targetDoc)
      .then(() => {
        setTargetSubmitting(false);
        setTargetModalVisible(false);
        setNewTarget({
          additionalInfo: "",
          address: "",
          postalCode: "",
          postalDistrict: "",
          contact: null,
          name: "",
          organization: null,
        });
      })
      .catch(error => {
        setTargetSubmitError("Virhe kohdetta luodessa");
        console.log("Error while creating target", error);
        setTargetSubmitting(false);
      });
  };

  if (!clientsFetched || !targetsFetched) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <NewTargetModal
        isVisible={targetModalVisible}
        target={newTarget}
        setTarget={setNewTarget}
        submitError={targetSubmitError}
        onSubmit={targetDoc => onNewTarget(targetDoc)}
        onHide={() => setTargetModalVisible(false)}
        submitting={targetSubmitting}
      />
      <TargetImportModal
        visible={targetImportModalVisible}
        onHide={() => {
          setTargetImportModalVisible(false);
        }}
      />
      <Box m={5} />
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-primary"
          onClick={() => {
            setTargetModalVisible(true);
          }}>
          LUO KOHDE
        </button>
        <Box m={2} />
        <button
          className="btn btn-transparent"
          onClick={() => {
            console.log("import targets");
            setTargetImportModalVisible(true);
          }}>
          TUO KOHTEITA
        </button>
        {checkedItems.length > 0 && (
          <>
            <Box m={2} />
            <button
              className="btn btn-transparent d-flex flex-row align-items-center"
              disabled={massDownloadInProgress}
              onClick={async () => {
                if (massDownloadInProgress) return;
                setMassDownloadInProgress(true);
                console.log("download all offers");
                try {
                  const offerLinks = await downloadLatestOffers(checkedItems);
                  setMassDownloadInProgress(false);
                  setMassOfferLinks(offerLinks);
                } catch (error) {
                  console.error("Error while mass downloading offers", error);
                  setMassDownloadInProgress(false);
                }
              }}>
              VIE KORJAUSEHDOTUKSET
              {massDownloadInProgress && <Loading />}
            </button>
            <Box m={2} />
            <button
              className="btn btn-transparent d-flex flex-row align-items-center"
              disabled={false}
              onClick={() => {
                console.log("mass excel");
                if (massExcelSubmitting) return;
                setMassExcelSubmitting(true);

                targetsToExcelData(checkedItems)
                  .then(data => {
                    const workSheet = XLSX.utils.json_to_sheet(data);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(
                      workBook,
                      workSheet,
                      "Kohteet",
                    );
                    XLSX.writeFile(workBook, "kohteet.xlsx");
                    setMassExcelSubmitting(false);
                  })
                  .catch(err => {
                    setMassExcelSubmitting(false);
                    console.error("Error while exporting excel", err);
                  });
              }}>
              LATAA EXCEL
            </button>
          </>
        )}
      </div>
      <Box m={2} />
      {Object.keys(massOfferLinks).length > 0 && (
        <MassOfferLinks massOfferLinks={massOfferLinks} />
      )}

      <Box m={2} />
      <div className="d-flex flex-row">
        <ContactAutocomplete
          onSelectContact={contact => {
            console.log("selected contact", contact);
            setContactFilter(contact);
          }}
        />
        <Box m={1} />
        <TextField
          value={cityFilter}
          onChange={e => {
            setCityFilter(e.target.value);
          }}
          placeholder="Hae paikkakunnalla"
        />
        <Box m={1} />
        <TextField
          value={nameFilter}
          onChange={e => {
            setNameFilter(e.target.value);
          }}
          placeholder="Hae nimellä"
        />
        <Box m={1} />
        <Select
          value={yearFilter}
          onChange={e => {
            setYearFilter(e.target.value);
          }}>
          {years.map(year => {
            return <MenuItem value={year}>{year}</MenuItem>;
          })}
        </Select>
        <Box m={1} />
      </div>
      <Box m={1} />
      <Autocomplete
        multiple={true}
        options={Object.keys(stateTranslations)
          .filter(state => state != GIVEN_FEEDBACK)
          .map(state => {
            return { id: Number(state), value: stateTranslations[state] };
          })}
        onChange={(e, selectedOptions) => {
          if (selectedOptions) {
            setStatusFilter(selectedOptions.map(option => option.id));
          } else {
            setStatusFilter([]);
          }
        }}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={option => option.value}
        renderInput={params => (
          <TextField {...params} placeholder="Suodata statuksilla"></TextField>
        )}
      />
      <Box m={2} />
      <AllTargetsTable
        clients={clients}
        targets={filteredAllTargets}
        statesFetched={!_.isEmpty(targetStateMap)}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
    </>
  );
};

export default Targets;
