/**
 * https://icons.getbootstrap.com/icons/chevron-right/
 * Renders an angle icon, angled to the right. Takes onClick and size as props
 * @param {*} onClick onClick handler
 * @param {*} size size of the icon
 * @returns
 */
const AngleRightIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="currentColor"
      className="bi bi-chevron-right hover-icon"
      viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export default AngleRightIcon;
