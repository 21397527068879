import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LargeCustomer } from "../../firebase/document-types";

interface LargeCustomerState {
  clients: LargeCustomer[];
  filteredClients: LargeCustomer[];
  client?: null | LargeCustomer;
}

const initialState: LargeCustomerState = {
  clients: [],
  filteredClients: [],
  client: null,
};

export const largeCustomerSlice = createSlice({
  name: "largeCustomer",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<LargeCustomer[]>) => {
      state.clients = action.payload;
      state.filteredClients = action.payload;
    },
    setFilteredClients: (state, action: PayloadAction<LargeCustomer[]>) => {
      state.filteredClients = action.payload;
    },
    removeClients: state => {
      state.clients = [];
    },
    setClient: (state, action: PayloadAction<LargeCustomer>) => {
      state.client = action.payload;
    },
    removeClient: state => {
      state.client = null;
    },
  },
});

export const {
  setClients,
  setClient,
  removeClients,
  removeClient,
  setFilteredClients,
} = largeCustomerSlice.actions;

export default largeCustomerSlice.reducer;
