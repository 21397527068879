import {
  Table,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import SortableHeader from "../largeCustomers/SortableHeader";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";
import { ADMIN, OFFICE_WORKER, WORKER } from "./userRoles";

const columns = [
  { label: "" },
  { label: "Nimi", property: "name" },
  { label: "Sähköposti", property: "email" },
  { label: "Puhelin", property: "phoneNumber" },
  { label: "Käyttäjätaso" },
  { label: "" },
];

const UserTable = ({ users }) => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const history = useHistory();

  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Descending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        // flip sorting type
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, users ?? []);

  const [checkedItems, setCheckedItems] = useState([]);

  const onRowClick = item => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.uid === item.uid,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  const renderAccessLevel = user => {
    const roles = user.roles;
    if (roles.includes(ADMIN)) {
      return "Admin";
    } else if (roles.includes(OFFICE_WORKER)) {
      return "Rajoitettu admin";
    } else if (roles.includes(WORKER)) {
      return "Työntekijä";
    }
    return "Tuntematon";
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );
              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, index) => {
              return (
                <TableRow
                  key={user.uid}
                  hover
                  onClick={event => {
                    if (event.nativeEvent.target.id === "userOpenButton") {
                      return;
                    }
                    onRowClick(user);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(item => item.uid === user.uid) >
                        -1
                      }
                    />
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>{renderAccessLevel(user)}</TableCell>

                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: "/kayttaja",
                        search: "?id=" + user.uid,
                        user: user,
                      });
                    }}>
                    <button id="userOpenButton" className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        rowsPerPage={rowsPerPage}
        count={sortedRows.length}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default UserTable;
