import { Box } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";

/**
 * Modal to display mass function results.
 * Expects results to be an object with fields 'errors' and 'sent'.
 * Example: {errors: [{key: "some-target-key", message: "error message"}], sent: ["some-other-target-key"]}
 * @param {*} results
 * @param {*} onHide
 * @param {*} isVisible
 * @param {*} checkedItems
 * @returns
 */
const MassFunctionResultModal = ({
  results,
  onHide,
  isVisible = false,
  checkedItems,
}) => {
  const findItemById = key => {
    for (const checkedItem of checkedItems) {
      if (checkedItem.key === key) {
        return checkedItem;
      }
    }
    return null;
  };

  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Body>
        <p className="mb-0">
          {results.sent.length} / {checkedItems.length} onnistui
        </p>
        <p>Statukset sivulla eivät päivity automaattisesti</p>
        {results.errors.length > 0 && (
          <>
            <p className="mb-0 text-danger">Virheet:</p>
            {results.errors.map((err, index) => {
              const targetKey = err.key;
              const errorMessage = err.message;
              const item = findItemById(targetKey);
              return (
                <React.Fragment key={targetKey}>
                  <p className="mb-0">
                    <strong>{item?.name ?? ""}</strong>
                  </p>
                  <p className="mb-0">{errorMessage}</p>
                  <Box m={1} />
                </React.Fragment>
              );
            })}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MassFunctionResultModal;
