import { Box, TextField } from "@material-ui/core";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { db, largeCustomerCollectionPath } from "../../firebase";

const ExistingClientChooser = ({ onChooseClient }) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Constructs a firebase query based on is the search string empty or not.
   * Empty string will returns up to 5 contacts from the current client, sorted by name.
   * A defined string will act like an autocomplete feature, returning names that are greater
   * than "capitalized" string but less than "limitString", which is lexicographically greater
   * than the "capitalized" string. See {@link constructLimitString} for details on that.
   *
   * @param {*} capitalized
   * @param {*} limitString
   * @returns
   */
  const constructQuery = useCallback((capitalized, limitString) => {
    if (capitalized.length === 0) {
      return query(
        collection(db, largeCustomerCollectionPath),
        orderBy("name"),
        limit(5),
      );
    }
    return query(
      collection(db, largeCustomerCollectionPath),
      where("name", ">=", capitalized),
      where("name", "<", limitString),
      orderBy("name"),
      limit(5),
    );
  }, []);

  useEffect(() => {
    let isNotCanceled = true;

    const capitalized = _.startCase(keyword);
    const limitString = constructLimitString(capitalized);
    const q = constructQuery(capitalized, limitString);

    if (isNotCanceled) {
      getDocs(q).then(snapshot => {
        const documents = [];
        snapshot.forEach(doc => {
          documents.push({ key: doc.id, ...doc.data() });
        });
        if (isNotCanceled) setResults(documents);
      });
    }

    return () => {
      isNotCanceled = false;
    };
  }, [constructLimitString, constructQuery, keyword]);

  return (
    <>
      <div className="text-center">
        <p className="mb-0">Valitse asiakas</p>
        <p className="mb-0">
          Kaikki tiedostossa olevat kohteet luodaan tälle asiakkaalle
        </p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <TextField
          label="Asiakkaan nimi"
          placeholder="Hakusana"
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
        />
        <Box m={1} />

        <div>
          {results.map((clientDoc, index) => {
            return (
              <div
                key={clientDoc.key}
                onClick={() => {
                  onChooseClient(clientDoc);
                }}>
                <p className="mb-1 hover-primary">{clientDoc.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ExistingClientChooser;
