import React, { useCallback, useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import ThumbnailEditIcon from "../ThumbnailEditIcon";
import ThumbnailRemoveIcon from "../ThumbnailRemoveIcon";
import AngleLeftIcon from "../AngleLeftIcon";
import AngleRightIcon from "../AngleRightIcon";
import "pintura/pintura.css";
import {
  openEditor,
  getEditorDefaults,
  createMarkupEditorToolbar,
  createMarkupEditorToolStyle,
  createDefaultColorOptions,
} from "pintura";
import locale from "../image/pintura_locale_fi";
import { getImageURLs } from "../image/imgUtil";
import ClassifiedImages from "../image/ClassifiedImages";
import { setTransaction } from "../../route/admin/transactionSlice";
import { useDispatch } from "react-redux";
import ImageClassifier from "../image/ImageClassifier";
import { tabLabels } from "../image/imageClassifications";
import TransactionOffer from "./TransactionOffer";
import { WAITING, WAITING_REVISION } from "./TransactionStates";
import Loading from "../Loading";
import { getAuth } from "@firebase/auth";
import { contactEmail } from "../../functions";
import moment from "moment";

const IMAGE_PAGE_SIZE = 3;

const iconLeft = {
  paddingBottom: "20px",
};

const iconRight = {
  paddingBottom: "20px",
};

const TAG = "[TransactionInfo]";

/**
 * Renders information about a transaction
 * @param {*} onUpdate onUpdate handler
 * @param {*} transactionDocument transaction document from firebase
 * @param {*} submitting flag to indicate is the form submitting
 * @returns
 */
const TransactionInfo = ({
  transactionDocument,
  submitting,
  submittingPdf,
  submittingReportPdf,
  pdfError,
  reportPdfError,
  classifiedNotUploaded,
  setClassifiedNotUploaded,
  onSubmit,
  onCancel,
  onSave,
  downloadPDF,
  sendPDF,
  sendReportPDF,
  contacts,
  downloadReportPDF,
  onDelete,
}) => {
  const [files, setFiles] = useState([]);
  const [filePage, setFilePage] = useState(0);
  const [images, setImages] = useState({});
  const dispatch = useDispatch();

  const [imageClassifier, setImageClassifier] = useState({
    visible: false,
    image: null,
    imageUri: null,
  });

  /**
   * Downloads classified images from firebase storage
   */
  useEffect(() => {
    // return early if tx doc is not defined
    if (!transactionDocument) return;
    // basically keeps track are we mounted or not
    let isCanceled = false;

    getImageURLs(transactionDocument.pictures.classified).then(
      resolvedImages => {
        //console.log("fetched image urls", resolvedImages);
        // resolvedImages is an object, with arrays of images under classification keys
        // {Asfalttivauriot: [], ...}, it uses tabLabels as the keys instead of the original classification
        for (const image of classifiedNotUploaded) {
          const label = tabLabels[image.classification];
          const _images = resolvedImages[label] ?? [];
          _images.push(image);
          resolvedImages[label] = _images;
        }
        if (!isCanceled && !_.isEqual(resolvedImages, images)) {
          setImages(resolvedImages);
        }
      },
    );
    return () => {
      isCanceled = true;
    };
  }, [transactionDocument, classifiedNotUploaded, images]);

  /**
   * Handler for when user drags and drops files into the file zone
   */
  const onDrop = useCallback(
    acceptedFiles => {
      const oldFiles = [...files];

      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      // merge arrays but replace same keys in old files with new files
      setFiles(_.unionBy([...oldFiles, ...newFiles], "name"));
    },
    [files],
  );

  /**
   * File drop zone handler
   */
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const sendButtonEnabled = useCallback(() => {
    return (
      transactionDocument.state < WAITING ||
      transactionDocument.state === WAITING_REVISION
    );
  }, [transactionDocument]);

  if (!transactionDocument) {
    return null;
  }
  const offer = transactionDocument?.offer;
  if (!offer) {
    return <Typography>No offer defined</Typography>;
  }

  /**
   * Edit image handler
   * This function will open the image editor modal, and call done callback
   * when the user clicks on "Done/Valmis" button.
   * @param {*} image
   * @param {*} done
   */
  const editImage = (image, done) => {
    const imageFile = image.pintura ? image.pintura.file : image;
    const imageState = image.pintura ? image.pintura.data : {};
    // get editor defaults
    const defs = getEditorDefaults();
    // replace utils with only the utils we want
    defs.utils = ["annotate", "crop"];
    // replace editor toolbar items with only the ones we want
    const toolBar = createMarkupEditorToolbar([
      "line",
      "rectangle",
      "ellipse",
      "text",
    ]);
    defs.markupEditorToolbar = toolBar;
    const ColorOptions = createDefaultColorOptions();
    // replace some toolbar item default options
    // create rectangle
    const rectangle = createMarkupEditorToolStyle("rectangle", {
      strokeWidth: "1%",
      strokeColor: ColorOptions.red,
    });
    // create ellipse
    const ellipse = createMarkupEditorToolStyle("ellipse", {
      strokeWidth: "1%",
      strokeColor: ColorOptions.red,
    });
    // create text styles
    const text = createMarkupEditorToolStyle("text", {
      color: ColorOptions.red,
      fontSize: "12px",
    });
    // replace rectangle & ellipse
    defs.markupEditorToolStyles.rectangle = rectangle;
    defs.markupEditorToolStyles.ellipse = ellipse;
    defs.markupEditorToolStyles.text = text;
    // open editor with our image and state, and set editor options
    const editor = openEditor({
      src: imageFile,
      imageState: imageState,
      ...defs,
      locale,
    });

    editor.on("close", () => {
      // cancel editing
    });

    editor.on("process", ({ dest, imageState }) => {
      // revoke old preview
      URL.revokeObjectURL(imageFile.preview);
      Object.assign(dest, {
        pintura: { file: imageFile, data: imageState },
        preview: URL.createObjectURL(dest),
      });
      // call callback
      done(dest);
    });
  };

  /**
   * onRemove handler for ClassifiedImages
   * If the user removes a classified image, this function will handle it.
   * @param {*} image
   * @param {*} index
   */
  const onRemoveClassifiedImage = (image, index) => {
    const oldImages = [...transactionDocument.pictures.classified];
    const foundIndex = oldImages.findIndex(
      classifiedImage => classifiedImage.imageUri === image.imageUri,
    );

    // index > -1 = image found
    if (foundIndex > -1) {
      oldImages.splice(foundIndex, 1);
      const newTx = { ...transactionDocument };
      const newPictures = { ...newTx.pictures };
      newPictures.classified = oldImages;
      newTx.pictures = newPictures;
      dispatch(setTransaction(newTx));
    }
    const foundNotUploaded = classifiedNotUploaded.findIndex(
      img => img.imageURL === image.imageURL,
    );
    if (foundNotUploaded > -1) {
      const notUploaded = [...classifiedNotUploaded];
      notUploaded.splice(foundNotUploaded, 1);
      setClassifiedNotUploaded(notUploaded);
    }
  };

  /**
   * onEdit handler for ClassifiedImages
   * If the user edits an classified image, this function will handle
   * the image.
   * @param {*} image
   * @param {*} index
   */
  const onEditClassifiedImage = (image, index) => {
    editImage(image.downloadURL ?? image.imageURL, output => {
      const newTx = { ...transactionDocument };
      const newPictures = { ...newTx.pictures };
      // update classified, we don't have to worry about the classified
      // array not existing here (if we're editing an image it should be there)
      const classified = [...newPictures.classified];
      const foundClassified = classified.findIndex(
        img => img.imageUri === image.imageUri,
      );
      // remove original image from classified array, if found
      if (foundClassified > -1) {
        classified.splice(foundClassified, 1);
        newPictures.classified = classified;
      }
      // the original image can also be in classifiedNotUploaded, look for it there
      const foundNotUploaded = classifiedNotUploaded.findIndex(
        img => img.imageURL === image.imageURL,
      );
      const notUploaded = [...classifiedNotUploaded];
      // if found in not uploaded, remove it
      if (foundNotUploaded > -1) {
        notUploaded.splice(foundNotUploaded, 1);
      }
      // create a new classified image object
      const classifiedImage = {
        // keep original data
        ...image,
        file: output,
        // classifiedNotUploaded exclusive
        imageURL: output.preview,
      };
      // still remove downloadURL from original data
      delete classifiedImage.downloadURL;
      // update redux state
      newTx.pictures = newPictures;
      dispatch(setTransaction(newTx));
      // we can't actually set this to the redux state, since it's a file which is not serializable
      // so we'll keep track of these in a local state
      setClassifiedNotUploaded([...notUploaded, classifiedImage]);
    });
  };

  /**
   * Handler for opening details about a classified image
   * @param {*} image
   */
  const onClickClassifiedImage = image => {
    setImageClassifier({
      visible: true,
      image: image,
      // imageURL exists on classifiedNotUploaded images
      imageUri: image.downloadURL ?? image.imageURL,
    });
  };

  /**
   * Handler for ImageClassifier
   * @param {*} image
   * @param {*} imageUri
   * @param {*} classification
   */
  const onClassifiedImage = (image, imageUri, classification) => {
    // figure out if this submit was an existing classified image, or
    // a new image

    // handle classified images (existing, already uploaded ones)
    if (image.downloadURL) {
      // find the index of this image
      const foundIndex = transactionDocument.pictures.classified.findIndex(
        pic => pic.imageUri === image.imageUri,
      );
      if (foundIndex > -1) {
        // if index was found (it should have been)
        // override the images classification data fields (classification, comment and size)
        const newTx = { ...transactionDocument };
        const newPictures = { ...newTx.pictures };
        const newClassified = [...newPictures.classified];
        const oldPicture = newClassified[foundIndex];
        newClassified[foundIndex] = { ...oldPicture, ...classification };
        newPictures.classified = newClassified;
        newTx.pictures = newPictures;
        dispatch(setTransaction(newTx));
      }
    }
    // handle unclassified and classified not uploaded
    else {
      if (image.imageURL) {
        // classified not uploaded

        const foundIndex = classifiedNotUploaded.findIndex(
          pic => pic.imageURL === image.imageURL,
        );
        if (foundIndex > -1) {
          const newNotUploaded = [...classifiedNotUploaded];
          const newPictureData = {
            ...newNotUploaded[foundIndex],
            ...classification,
          };
          newNotUploaded[foundIndex] = newPictureData;
          setClassifiedNotUploaded(newNotUploaded);
        }
      } else {
        // unclassified

        // remove from unclassified
        const foundIndex = files.findIndex(
          file => file.path === image.file.path,
        );
        if (foundIndex > -1) {
          const newFiles = [...files];
          const file = newFiles[foundIndex];
          // revoke old URL
          URL.revokeObjectURL(file.preview);
          newFiles.splice(foundIndex, 1);
          setFiles(newFiles);
        }

        const notUploaded = [...classifiedNotUploaded];
        // push a new not uploaded classified image into the array
        notUploaded.push({
          file: image.file,
          // we need to create a new URL
          imageURL: URL.createObjectURL(image.file),
          ...classification,
        });
        setClassifiedNotUploaded(notUploaded);
      }
    }
  };

  /**
   * Renders thumbnails from files state
   */
  const thumbnails = files
    .slice(filePage, filePage + IMAGE_PAGE_SIZE)
    .map((file, index) => {
      return (
        <React.Fragment key={file.name}>
          <div
            className="thumbnail"
            // if a whole row of images is not filled, add some right margin to images
            style={{ marginRight: files.length < 3 ? "5%" : "0%" }}>
            <div className="thumbnail-inner ">
              <img
                src={file.preview}
                alt=""
                onClick={() => {
                  // create a dummy object, which has the file object in it, this way
                  // we don't load the files size into the "korjauksen koko" size
                  const image = { file: file };
                  setImageClassifier({
                    visible: true,
                    image: image,
                    imageUri: file.preview,
                  });
                }}
              />
            </div>
            <div className="d-flex flex-row mt-1">
              <ThumbnailEditIcon
                onClick={() => {
                  editImage(file, output => {
                    // updates files when callback is called
                    const _files = [...files];
                    _files[index] = output;
                    setFiles(_files);
                  });
                }}
              />
              <div className="mx-1" />
              <ThumbnailRemoveIcon
                onClick={() => {
                  const _files = [...files];
                  const file = _files[index];
                  if (file.preview) URL.revokeObjectURL(file.preview);
                  _files.splice(index, 1);
                  setFiles(_files);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });

  return (
    <>
      <div className="transaction-info-padder">
        <p className="input-title">Kartoituspäivämäärä</p>
        <div className="d-flex justify-content-center white-bg input-box">
          <p className="input-text">
            {moment(offer.createdAt).format("DD.MM.YYYY")}
          </p>
        </div>
        <Box m={5} />
        <p className="input-title">Kuvat</p>
        <div
          className={
            isDragActive
              ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
              : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
          }
          {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="input-text">
            Vedä tiedostot tähän tai{" "}
            <span className="link">valitse ne tietokoneestasi</span>
          </p>
        </div>
      </div>
      <Box m={5} />
      {files.length > 0 && (
        <div className="d-flex flex-row align-items-center">
          <div style={iconLeft}>
            <AngleLeftIcon
              size={30}
              onClick={() => {
                if (filePage > 0) {
                  setFilePage(filePage - 1);
                }
              }}
            />
          </div>
          <div
            className={
              // if a whole row of images is not filled, don't justify the content using space-between
              files.length > 2 ? "d-flex justify-content-between" : "d-flex"
            }>
            {thumbnails}
          </div>
          <div style={iconRight}>
            <AngleRightIcon
              size={30}
              onClick={() => {
                if (filePage < files.length - IMAGE_PAGE_SIZE) {
                  setFilePage(filePage + 1);
                }
              }}
            />
          </div>
        </div>
      )}
      <Box m={5} />
      <ClassifiedImages
        images={images}
        onRemoveImage={onRemoveClassifiedImage}
        onEditImage={onEditClassifiedImage}
        onClickImage={onClickClassifiedImage}
      />
      <ImageClassifier
        visible={imageClassifier.visible}
        image={imageClassifier.image}
        imageUri={imageClassifier.imageUri}
        onCancel={() => {
          setImageClassifier({
            visible: false,
            image: null,
            imageUri: null,
          });
        }}
        onComplete={(image, imageUri, classification) => {
          //console.log("Save classification", image, imageUri, classification);
          setImageClassifier({
            visible: false,
            image: null,
            imageUri: null,
          });
          onClassifiedImage(image, imageUri, classification);
        }}
      />
      <Box m={5} />
      <TransactionOffer
        transactionDocument={transactionDocument}
        classifiedNotUploaded={classifiedNotUploaded}
      />
      <Box m={5} />
      <div className="transaction-info-padder">
        <div className="d-grid gap-2 d-md-block">
          <div className="d-flex flex-md-row flex-column">
            {!submitting && (
              <>
                <button
                  disabled={!sendButtonEnabled()}
                  className="btn btn-success btn-send flex-fill"
                  onClick={() => {
                    if (sendButtonEnabled()) {
                      onSubmit();
                    }
                  }}>
                  LÄHETÄ SOVELLUKSEEN
                </button>
                <Box m={1} />
                <button
                  className="btn btn-primary flex-fill"
                  onClick={() => {
                    onSave();
                  }}>
                  TALLENNA
                </button>
              </>
            )}
            {submitting && (
              <>
                <button className="btn btn-success btn-send flex-fill">
                  <Loading />
                </button>
                <Box m={1} />
                <button className="btn btn-transparent flex-fill">
                  <Loading />
                </button>
              </>
            )}
            <Box m={1} />
            <button
              className="btn btn-transparent flex-fill"
              onClick={onCancel}>
              PERUUTA
            </button>
          </div>
        </div>
        <Box m={2} />
        {pdfError.length > 0 && <p className="text-danger">{pdfError}</p>}
        <div className="d-grid gap-2 d-md-block">
          <div className="d-flex flex-md-row flex-column">
            {!submittingPdf && (
              <>
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    console.log(TAG, "Send PDF client");
                    for (const contact of contacts) {
                      const recipient = contactEmail(contact);
                      sendPDF(recipient);
                    }
                  }}>
                  LÄHETÄ SOVELLUKSEEN JA SÄHKÖPOSTIIN
                </button>
                <Box m={1} />
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    console.log(TAG, "Send PDF self");
                    const auth = getAuth();
                    const user = auth.currentUser;
                    const recipient = user.email;
                    sendPDF(recipient);
                  }}>
                  TARJOUS PDF ITSELLE
                </button>
                <Box m={1} />
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    downloadPDF();
                  }}>
                  LATAA TARJOUS PDF
                </button>
              </>
            )}
            {submittingPdf && <Loading />}
          </div>
        </div>
        <Box m={2} />
        {reportPdfError.length > 0 && (
          <p className="text-danger">{reportPdfError}</p>
        )}
        <div className="d-grid gap-2 d-md-block">
          <div className="d-flex flex-md-row flex-column">
            {!submittingReportPdf && (
              <>
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    console.log(TAG, "Send report PDF client");
                    for (const contact of contacts) {
                      const recipient = contactEmail(contact);
                      sendReportPDF(recipient);
                    }
                  }}>
                  RAPORTTI PDF ASIAKKAALLE
                </button>
                <Box m={1} />
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    const auth = getAuth();
                    const user = auth.currentUser;
                    const recipient = user.email;
                    sendReportPDF(recipient);
                  }}>
                  RAPORTTI PDF ITSELLE
                </button>
                <Box m={1} />
                <button
                  className="btn btn-transparent flex-fill"
                  onClick={() => {
                    downloadReportPDF();
                  }}>
                  LATAA RAPORTTI PDF
                </button>
              </>
            )}
            {submittingReportPdf && <Loading />}
          </div>
        </div>
        <Box m={2} />
        <div className="d-grid gap-2 d-md-block">
          <div className="d-flex flex-md-row flex-column">
            <button onClick={onDelete} className="btn btn-danger">
              POISTA
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionInfo;
