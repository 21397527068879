import { Box } from "@material-ui/core";
import React from "react";
import EditIcon from "../EditIcon";

/**
 * Renders users name and isWorker status
 * @param {*} userDocument User document from firebase
 * @param {*} onEditUser handler to show update modal
 * @returns
 */
const UserInfo = ({ userDocument, onEditUser }) => {
  return (
    <>
      <div className="col info-container text-center">
        <div className="icon-title d-flex flex-row flex-wrap justify-content-evenly align-items-center">
          <Box m={1} />
          <h3 className="d-flex flex-shrink">{userDocument.name}</h3>
          <EditIcon
            size={24}
            onClick={() => {
              onEditUser();
            }}
          />
        </div>
        {userDocument.isWorker && <p className="mb-0">Työntekijä</p>}
        <Box m={2} />
        <p className="mb-0">{userDocument.email}</p>
        <p className="mb-0">{userDocument.phoneNumber}</p>
      </div>
    </>
  );
};

export default UserInfo;
