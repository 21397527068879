import React, { useEffect, useState } from "react";
import TargetInfo from "./TargetInfo";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setTarget } from "../../route/admin/targetSlice";
import qs from "qs";
import {
  doc,
  onSnapshot,
  query,
  where,
  collection,
  updateDoc,
} from "firebase/firestore";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
  userCollectionPath,
} from "../../firebase";
import { setClient } from "../../route/admin/largeCustomerSlice";
import { setTransactions } from "../../route/admin/transactionSlice";
import TransactionTable from "../transaction/TransactionTable";
import moment from "moment";
import { CREATED } from "../transaction/TransactionStates";
import { useHistory } from "react-router-dom";
import TargetUpdateModal from "./TargetUpdateModal";
import _ from "lodash";
import ContactUpdateModal from "../contact/ContactUpdateModal";
import {
  appendLog,
  currentUserLogInfo,
  lastLogEntry,
  logEvents,
} from "../logging/logUtils";
import TransactionImportModal from "../transaction/TransactiomImportModal";

const TAG = "[TargetDetails]";

const TargetDetails = ({ location }) => {
  const { target } = useSelector(state => state.target);
  const { client } = useSelector(state => state.largeCustomer);
  const { transactions } = useSelector(state => state.transaction);
  const dispatch = useDispatch();
  const history = useHistory();
  // target update modal states
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [targetSubmitting, setTargetSubmitting] = useState(false);
  const [targetSubmitError, setTargetSubmitError] = useState("");

  const [importModalVisible, setImportModalVisible] = useState(false);

  /**
   * Sets target to the state, uses either the target found in
   * router location parameters, or loads it from firebase
   * by parsing the ID from URL query parameters
   */
  useEffect(() => {
    let targetUnSub;
    const target = location?.client;
    if (target) {
      dispatch(setTarget(target));
    } else {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const id = queryParams.id;
      if (!id) {
        console.warn("Target ID was not defined!");
        return;
      }
      targetUnSub = onSnapshot(
        doc(db, largeCustomerTargetPath, id),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          dispatch(setTarget({ key: key, ...data }));
        },
        error => {
          console.warn("Error while fetching target data", error);
        },
      );
    }
    return () => {
      if (targetUnSub) {
        targetUnSub();
      }
      // when user navigates away, remove target from redux
      dispatch(setTarget(null));
    };
  }, [location, dispatch]);

  /**
   * Fetches this targets transactions
   */
  useEffect(() => {
    if (!target) return;
    const key = target.key;
    const q = query(
      collection(db, largeCustomerTransactionPath),
      where("target", "==", key),
    );

    const unsub = onSnapshot(
      q,
      docSnapshot => {
        const txDocs = [];
        docSnapshot.forEach(doc => {
          const data = doc.data();
          let txDoc = {
            key: doc.id,
            ...data,
            // add two custom fields for sorting
            offerPrice: data.offer?.price ?? null,
            offerStartDate: moment(data.offer?.startDate, true).format(
              "DD.MM.YYYY",
            ),
          };
          // add last editor and last edited time if this document
          // has log entries
          const lastLog = lastLogEntry(data);
          if (!_.isNil(lastLog)) {
            txDoc.logUser = lastLog.user.name;
            txDoc.logTime = moment(lastLog.time).format("DD.MM.YYYY");
          }
          txDocs.push(txDoc);
        });
        dispatch(setTransactions(txDocs));
      },
      error => {
        console.warn("Error while fetching transactions", error);
      },
    );

    return () => {
      unsub();
    };
  }, [target, dispatch]);

  /**
   * Fetches this targets largeCustomer document
   */
  useEffect(() => {
    // exit early if target is not defined
    if (!target) return;
    // if client is already defined, don't fetch
    if (client) return;

    const clientId = target.organization;
    const unsub = onSnapshot(
      doc(db, largeCustomerCollectionPath, clientId),
      docSnapshot => {
        const data = docSnapshot.data();
        const key = docSnapshot.id;
        dispatch(setClient({ key: key, ...data }));
      },
      error => {
        console.warn("Error while fetching client data", error);
      },
    );
    return () => {
      unsub();
    };
  }, [target, client, dispatch]);

  /**
   * Target document update handler
   * @param {*} targetDoc
   */
  const onUpdate = targetDoc => {
    if (targetSubmitting) return;
    setTargetSubmitting(true);
    const fields = [
      "additionalInfo",
      "address",
      // 'contact' is slowly being phased out.
      // we can keep it here for backwards compatibility
      // for now
      "contact",
      "contacts",
      "createdAt",
      "name",
      "organization",
      "postalCode",
      "postalDistrict",
      "log",
    ];
    // remove any unnecessary fields
    const filteredTarget = _.pick(targetDoc, fields);
    (async () => {
      const loggedTarget = appendLog(
        logEvents.LOG_UPDATE,
        null,
        null,
        filteredTarget,
        await currentUserLogInfo(),
      );
      const targetRef = doc(db, largeCustomerTargetPath, target.key);
      updateDoc(targetRef, loggedTarget)
        .then(() => {
          setTargetSubmitting(false);
          setUpdateModalVisible(false);
        })
        .catch(error => {
          console.log("Virhe päivittäessä kohdetta", error);
          setTargetSubmitError("Virhe päivittäessä");
          setTargetSubmitting(false);
        });
    })();
  };

  if (!target || !client) {
    return null;
  }

  return (
    <>
      <TargetUpdateModal
        isVisible={updateModalVisible}
        onHide={() => setUpdateModalVisible(false)}
        target={target}
        setTarget={targetDoc => {
          dispatch(setTarget(targetDoc));
        }}
        client={client}
        contacts={contacts}
        onSubmit={targetDoc => onUpdate(targetDoc)}
        targetSubmitError={targetSubmitError}
        submitting={targetSubmitting}
        title={"Muokkaa kohdetta"}
      />
      {target && client && (
        <TransactionImportModal
          visible={importModalVisible}
          onHide={() => {
            setImportModalVisible(false);
          }}
          target={target}
          client={client}
        />
      )}
      <Box m={5} />
      {/* button section */}
      <div className="row mb-4">
        <div className="d-flex flex-row-reverse">
          <button
            className="btn btn-primary"
            onClick={() => {
              //console.log(TAG, "New TX press");
              // create new TX, initialize redux state with "empty data"
              const newTx = {
                key: "NEW",
                additionalInfo: "",
                offer: {
                  createdAt: moment().format("YYYY-MM-DD"),
                  endDate: null,
                  startDate: null,
                  price: null,
                },
                pictures: {
                  classified: [],
                  classifiedNotUploaded: [],
                  unclassified: [],
                },
                state: CREATED,
                target: target.key,
                updatedAt: moment().unix(),
                organization: client.key,
              };
              history.push({
                pathname: "/tilaus",
                transaction: newTx,
              });
            }}>
            UUSI KARTOITUS
          </button>
          <button
            className="btn btn-transparent me-5"
            onClick={() => {
              console.log(TAG, "Import tx");
              setImportModalVisible(true);
            }}>
            TUO KARTOITUKSIA
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <TargetInfo
            target={target}
            showUpdateModal={() => setUpdateModalVisible(true)}
            contacts={contacts}
            setContacts={setContacts}
          />
        </div>
        <div className="col-xs-12 col-lg-8">
          <TransactionTable transactions={transactions} />
        </div>
      </div>
    </>
  );
};

export default TargetDetails;
