import {
  TextField,
  InputAdornment,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { classifications, ASPHALT_DAMAGE } from "./imageClassifications";
import _ from "lodash";

/**
 * Image classifier modal, allows the user to classify an image, comment it
 * and record the damages size in m2.
 *
 * Validates that at least a classification and size is defined when user attempts
 * to save the form.
 * @param {*} visible modal visibility
 * @param {*} image image object, can be classified/classified not uploaded/unclassified
 * @param {*} imageUri URL for the img element
 * @param {*} onComplete called when user wants to save any changes
 * @param {*} onCancel on cancel handler (hides modal)
 * @returns
 */
const ImageClassifier = ({
  visible,
  image,
  imageUri,
  onComplete,
  onCancel,
}) => {
  const [classification, setClassification] = useState(null);
  const [comment, setComment] = useState("");
  const [size, setSize] = useState(null);
  const [errorText, setErrorText] = useState("");

  /**
   * Injects (possibly) already existing classification, comment and size values
   * into the state from image passed as props
   */
  useEffect(() => {
    if (image) {
      setClassification(image.classification);
      setComment(image.comment ?? "");
      setSize(image.size);
    }
    return () => {
      setClassification(null);
      setComment(null);
      setSize(null);
      setErrorText("");
    };
  }, [image]);

  const onSave = () => {
    setErrorText("");
    if (!isValid()) return;
    onComplete(image, imageUri, {
      size: size,
      comment: comment,
      classification: classification,
    });
  };

  /**
   * Returns true if the form is valid, false if not
   * @returns
   */
  const isValid = () => {
    console.log(classification, size);
    if (_.isNil(classification)) {
      setErrorText("Luokitus ei voi olla tyhjä!");
      return false;
    }
    if (
      classification === ASPHALT_DAMAGE &&
      (_.isNil(size) || size?.length === 0)
    ) {
      setErrorText("Korjausalueen koko ei voi olla tyhjä");
      return false;
    }

    return true;
  };

  return (
    <Modal show={visible} onHide={onCancel}>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          {/* image section */}
          <div className="d-flex flex-column align-items-center">
            <a href={imageUri} target="_blank" rel="noopener noreferrer">
              <img
                src={imageUri}
                alt=""
                width={300}
                height={300}
                style={{ objectFit: "contain" }}
              />
            </a>
          </div>
          {/* form section */}
          <div className="d-flex flex-column align-items-start">
            <Box m={2} />
            <InputLabel id="classification-label">Luokitus</InputLabel>
            <Select
              fullWidth={true}
              labelId="classification-label"
              id="classification"
              // this will output a warning to the console in dev mode, but
              // we actually want the default value to be empty. I suppose we could add an empty
              // MenuItem as a child. but I don't think it's necessary
              value={classification}
              onChange={e => {
                setClassification(e.target.value);
                if (e.target.value !== ASPHALT_DAMAGE) {
                  setSize(0);
                }
              }}>
              {classifications.map((classification, index) => {
                return (
                  <MenuItem key={classification} value={classification}>
                    {classification}
                  </MenuItem>
                );
              })}
            </Select>

            <TextField
              fullWidth={true}
              id="comment"
              label="Selite"
              value={comment ?? ""}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={e => {
                console.log("e", e.nativeEvent);
                setComment(e.target.value);
              }}
            />

            <TextField
              disabled={classification !== ASPHALT_DAMAGE}
              id="size"
              label="Korjausalueen koko"
              value={size ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m2</InputAdornment>
                ),
              }}
              inputProps={{ style: { textAlign: "center" } }}
              type="number"
              onChange={e => setSize(e.target.value)}
            />
          </div>
          <Box m={2} />
          {errorText.length > 0 && <p className="text-danger">{errorText}</p>}

          <div className="d-flex flex-row align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log("save classification");
                // call a local function to handle validation and submitting
                onSave();
              }}>
              TALLENNA
            </button>
            <button
              className="btn btn-transparent ms-3"
              onClick={() => {
                console.log("cancel classification");
                onCancel();
              }}>
              PERUUTA
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageClassifier;
