import React, { useCallback, useMemo } from "react";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  setTransaction,
  updateOffer,
} from "../../route/admin/transactionSlice";
import { useDispatch } from "react-redux";
import { itemTranslations } from "./TransactionStates";
import _ from "lodash";
import {
  calculateAutoprice,
  calculateAcceptedPictureTotalSize,
} from "./transactionUtil";

const TransactionOffer = ({ transactionDocument, classifiedNotUploaded }) => {
  const dispatch = useDispatch();

  /**
   * Considers both: classified and accepted images
   * not uploaded classified images.
   */
  const totalImagesSum = useCallback(() => {
    const classifiedSum = calculateAcceptedPictureTotalSize(
      transactionDocument.pictures.classified,
    );
    const notUploadedSum = classifiedNotUploaded.reduce((sum, picture) => {
      const size = parseInt(picture.size);
      if (!isNaN(size)) {
        sum += size;
      }
      return sum;
    }, 0);
    return classifiedSum + notUploadedSum;
  }, [transactionDocument, classifiedNotUploaded]);

  const autoPrice = useMemo(() => {
    const sum = totalImagesSum();
    if (sum > 10) {
      return "-";
    }
    return calculateAutoprice(
      _.concat(classifiedNotUploaded, transactionDocument.pictures.classified),
    );
  }, [transactionDocument, classifiedNotUploaded, totalImagesSum]);

  return (
    <div className="transaction-info-padder">
      <p className="section-title">Tarjous</p>
      <Box m={2} />
      <p className="input-title">Arvioitu toimitusaika</p>
      <div className="d-flex flex-row justify-content-between">
        <div className="input-box white-bg">
          <KeyboardDatePicker
            value={transactionDocument.offer.startDate}
            format="DD.MM.YYYY"
            onChange={val => {
              const offer = { ...transactionDocument.offer };
              offer.startDate = val.format("YYYY-MM-DD");
              dispatch(updateOffer(offer));
            }}
          />
        </div>
        <div className="input-box white-bg">
          <KeyboardDatePicker
            value={transactionDocument.offer.endDate}
            format="DD.MM.YYYY"
            onChange={val => {
              const offer = { ...transactionDocument.offer };
              offer.endDate = val.format("YYYY-MM-DD");
              dispatch(updateOffer(offer));
            }}
          />
        </div>
      </div>
      <Box m={2} />
      <p className="input-title">Hinta-arvio</p>
      <div className="input-box white-bg d-flex justify-content-center">
        <TextField
          type="text"
          fullWidth={true}
          onChange={e => {
            if (e.target.value) {
              const offer = { ...transactionDocument.offer };
              let inputPrice = e.target.value;
              const lastChar = inputPrice[inputPrice.length - 1];
              if ([",", "."].includes(lastChar)) {
                if (inputPrice.includes(",")) {
                  inputPrice = inputPrice.replace(",", ".");
                }
                offer.price = inputPrice;
                dispatch(updateOffer(offer));
              } else {
                offer.price = Number(inputPrice);
                dispatch(updateOffer(offer));
              }
            } else {
              const offer = { ...transactionDocument.offer };
              offer.price = null;
              dispatch(updateOffer(offer));
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          inputProps={{ style: { textAlign: "center" } }}
          placeholder={autoPrice + " (automaattinen hinta-arvio)"}
          value={transactionDocument.offer.price ?? ""}
        />
      </div>
      {transactionDocument.offer?.previousPrice && (
        <>
          <p className="input-title mt-1">Vanha hinta-arvio</p>
          <p>{transactionDocument.offer.previousPrice} €</p>
        </>
      )}
      <p></p>
      <>
        <Box m={2} />
        <p className="input-title">Korjausneliöt yhteensä</p>
        <p className="mb-0">{totalImagesSum()} m2</p>
      </>

      <Box m={2} />
      <p className="input-title">Tilauksen status</p>
      <div className="input-box white-bg text-center">
        {itemTranslations[transactionDocument.state] ?? "Unknown"}
      </div>
      <Box m={2} />
      <p className="input-title">Lisätiedot</p>
      <div className="input-box white-bg">
        <TextField
          multiline
          fullWidth={true}
          placeholder="Lisätiedot"
          rows={4}
          value={transactionDocument.additionalInfo}
          onChange={e => {
            const tx = { ...transactionDocument };
            tx.additionalInfo = e.target.value;
            dispatch(setTransaction(tx));
          }}
        />
      </div>
    </div>
  );
};

export default TransactionOffer;
