/**
 * https://icons.getbootstrap.com/icons/dash-circle/
 * Renders an circle icon with a dash inside it, takes onClick handler and size as props
 * @param {*} onClick onClick handler
 * @param {*} size size of the icon
 * @returns
 */
const ThumbnailRemoveIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="currentColor"
      className="bi bi-dash-circle edit-icon"
      viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
    </svg>
  );
};

export default ThumbnailRemoveIcon;
