import { useState, useEffect } from "react";
import {
  collection,
  documentId,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  db,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import {
  CREATED,
  WAITING_REVISION,
} from "../../components/transaction/TransactionStates";
import _ from "lodash";
import CreatedTransactionTargetTable from "../../components/createdTransactions/CreatedTransactionTargetTable";

const CreatedStatusTransactions = () => {
  const [targetKeys, setTargetKeys] = useState([]);
  const [targetDocuments, setTargetDocuments] = useState([]);

  /**
   * Subscribe to created transactions,
   * and extracts unique target keys
   */
  useEffect(() => {
    const q = query(
      collection(db, largeCustomerTransactionPath),
      where("state", "in", [CREATED, WAITING_REVISION]),
    );
    const unSub = onSnapshot(q, docSnapshot => {
      const targets = [];
      docSnapshot.forEach(doc => {
        const _data = doc.data();
        targets.push(_data.target);
      });
      setTargetKeys(_.uniq(targets));
    });

    return () => unSub();
  }, [setTargetKeys]);

  useEffect(() => {
    let isNotCanceled = true;

    const targetRef = collection(db, largeCustomerTargetPath);

    const idBatches = _.chunk(targetKeys, 10);
    let batches = [];
    for (const idBatch of idBatches) {
      const q = query(targetRef, where(documentId(), "in", idBatch));
      batches.push(
        new Promise(resolve => {
          getDocs(q).then(results =>
            resolve(
              results.docs.map(doc => {
                const data = doc.data();
                return {
                  key: doc.id,
                  ...data,
                  // combine address with postalCode and district
                  wholeAddress:
                    data.address +
                    ", " +
                    data.postalCode +
                    " " +
                    data.postalDistrict,
                };
              }),
            ),
          );
        }),
      );
    }

    Promise.all(batches).then(targetBatches => {
      const fetchedTargets = targetBatches.flat();
      if (isNotCanceled) {
        setTargetDocuments(fetchedTargets);
      }
    });

    return () => {
      isNotCanceled = false;
    };
  }, [targetKeys, setTargetDocuments]);

  return (
    <>
      <CreatedTransactionTargetTable targets={targetDocuments} />
    </>
  );
};

export default CreatedStatusTransactions;
