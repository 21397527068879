import { configureStore } from "@reduxjs/toolkit";
import frontpageSlice from "../route/admin/frontpageSlice";
import largeCustomerReducer from "../route/admin/largeCustomerSlice";
import targetReducer from "../route/admin/targetSlice";
import transactionReducer from "../route/admin/transactionSlice";
import userReducer from "../components/user/userSlice";

const store = configureStore({
  reducer: {
    largeCustomer: largeCustomerReducer,
    target: targetReducer,
    transaction: transactionReducer,
    frontpage: frontpageSlice,
    user: userReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
