/**
 * Renders an "X" icon, takes a onClick handler and size as props
 * https://icons.getbootstrap.com/icons/x/
 * @param {*} onClick onClick handler
 * @param {*} size size of the icon
 */
const RemoveIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="currentColor"
      className="bi bi-x remove-icon hover-icon"
      viewBox="0 0 16 16">
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};

export default RemoveIcon;
