import React, { useState, useEffect, useCallback } from "react";
import { TextField, Box } from "@material-ui/core";
import _ from "lodash";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, userCollectionPath } from "../../firebase";
import { useSelector } from "react-redux";

const NewExistingContact = ({ visible = false, onSelectContact }) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  const { client } = useSelector(state => state.largeCustomer);

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Constructs a firebase query based on is the search string empty or not.
   * Empty string will returns up to 5 contacts from the current client, sorted by name.
   * A defined string will act like an autocomplete feature, returning names that are greater
   * than "capitalized" string but less than "limitString", which is lexicographically greater
   * than the "capitalized" string. See {@link constructLimitString} for details on that.
   *
   * @param {*} capitalized
   * @param {*} limitString
   * @returns
   */
  const constructQuery = useCallback(
    (capitalized, limitString) => {
      if (capitalized.length === 0) {
        return query(
          collection(db, userCollectionPath),
          where("organization", "==", client.key),
          orderBy("name"),
          limit(5),
        );
      }
      return query(
        collection(db, userCollectionPath),
        where("name", ">=", capitalized),
        where("name", "<", limitString),
        where("organization", "==", client.key),
        orderBy("name"),
        limit(5),
      );
    },
    [client],
  );

  /**
   * Queries firebase user collection with keyword.
   * Tries to match keyword to the name field.
   */
  useEffect(() => {
    let isCanceled = false;
    const capitalized = _.startCase(_.toLower(keyword));
    const limitString = constructLimitString(capitalized);
    const q = constructQuery(capitalized, limitString);
    if (!isCanceled) {
      (async () => {
        const contacts = await getDocs(q);
        const contactDocs = [];
        contacts.forEach(doc => {
          contactDocs.push(doc.data());
        });
        if (!isCanceled) setResults(contactDocs);
      })();
    }
    return () => {
      isCanceled = true;
    };
  }, [keyword, constructLimitString, client, constructQuery]);

  if (!visible) return null;

  return (
    <div>
      <TextField
        fullWidth={true}
        label="Yhteyshenkilön nimi"
        placeholder="Hakusana"
        value={keyword}
        onChange={e => {
          setKeyword(e.target.value);
        }}
      />
      <Box m={2} />
      {_.take(results, 5).map((contactResult, index) => {
        return (
          <div
            key={"contactResult" + index}
            className="d-flex flex-row align-items-center justify-content-between">
            <p>{contactResult.name}</p>
            <button
              onClick={() => {
                onSelectContact(contactResult);
              }}
              className="btn btn-primary">
              Valitse
            </button>
          </div>
        );
      })}
      {results.length === 0 && keyword.length > 0 && (
        <p>
          Ei tuloksia, voit luoda uuden yhteystiedon organisaatiolle
          "Yhteyshenkilöt" välilehdeltä
        </p>
      )}
    </div>
  );
};

export default NewExistingContact;
