import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment/min/moment-with-locales";
import { COMPLETED } from "../transaction/TransactionStates";
import { Box } from "@material-ui/core";

const SalesPerMonth = ({ transactions }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = [
      { m: "Jan", soldPrice: 0, offerPrice: 0 },
      { m: "Feb", soldPrice: 0, offerPrice: 0 },
      { m: "Mar", soldPrice: 0, offerPrice: 0 },
      { m: "Apr", soldPrice: 0, offerPrice: 0 },
      { m: "May", soldPrice: 0, offerPrice: 0 },
      { m: "Jun", soldPrice: 0, offerPrice: 0 },
      { m: "Jul", soldPrice: 0, offerPrice: 0 },
      { m: "Aug", soldPrice: 0, offerPrice: 0 },
      { m: "Sep", soldPrice: 0, offerPrice: 0 },
      { m: "Oct", soldPrice: 0, offerPrice: 0 },
      { m: "Nov", soldPrice: 0, offerPrice: 0 },
      { m: "Dec", soldPrice: 0, offerPrice: 0 },
    ];
    for (const tx of transactions) {
      if (_.isNil(tx.offer)) continue;
      const month = moment(tx.offer.createdAt, "YYYY-MM-DD").format("MMM");

      const price = _.toNumber(tx.offer.price);
      if (_.isNumber(price)) {
        const dataIndex = data.findIndex(obj => obj.m === month);
        if (dataIndex === -1) continue;
        const obj = data[dataIndex];
        if (tx.state >= COMPLETED) {
          // update both prices
          obj.soldPrice += price;
          obj.offerPrice += price;
        } else {
          // update offer price
          obj.offerPrice += price;
        }
        data[dataIndex] = obj;
      }
    }
    setChartData(data);
  }, [transactions, setChartData]);

  return (
    <>
      <div
        style={{ width: "100%", height: "500px" }}
        className="stats-container">
        <h4>Myynti kuukausittain</h4>
        <Box m={1} />
        <ResponsiveContainer width="100%" height="94%">
          <LineChart width={500} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="m" />
            <YAxis />
            <Tooltip
              formatter={(value, name, index) => {
                if (name === "offerPrice") {
                  return [value + " €", "Tarjouksen yhteensä"];
                } else if (name === "soldPrice") {
                  return [value + " €", "Myynti"];
                }
                return [value + " €", name];
              }}
            />
            <Legend
              verticalAlign="top"
              formatter={(value, entry, index) => {
                if (value === "offerPrice") {
                  return "Tarjoukset yhteensä ";
                } else if (value === "soldPrice") {
                  return "Myynti";
                }
                return value;
              }}
              text
            />
            <Line type="monotone" dataKey="soldPrice" stroke="#3DD598" />
            <Line type="monotone" dataKey="offerPrice" stroke="#0058FF" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default SalesPerMonth;
