import React from "react";

const MassOfferLinks = ({ massOfferLinks }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Kohteen nimi</th>
          <th>Linkki</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(massOfferLinks).map((targetName, index) => {
          return (
            <tr>
              <td>{targetName}:</td>
              <td>
                {" "}
                <a href={massOfferLinks[targetName]}>
                  {massOfferLinks[targetName]}
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MassOfferLinks;
