import { useState, useCallback } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import { SortingType } from "../sort/SortingTypes";
import FrontpageSortableHeader from "../largeCustomers/FrontpageSortableHeader";
import useSortedRows from "../sort/useSortedRows";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";
import { useDispatch } from "react-redux";
import { setTarget } from "../../route/admin/targetSlice";
import { useHistory } from "react-router-dom";

const columns = [
  { label: "Kohde", property: "name" },
  { label: "Osoite", property: "wholeAddress" },
  { label: "" },
];

const CreatedTransactionTargetTable = ({ targets }) => {
  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Ascending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, targets);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <Table>
        <FrontpageSortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((target, index) => {
              return (
                <TableRow key={target.key} hover>
                  <TableCell>{target.name}</TableCell>
                  <TableCell>{target.wholeAddress}</TableCell>
                  <TableCell
                    onClick={() => {
                      dispatch(setTarget(target));
                      history.push({
                        pathname: "/kohde",
                        search: "?id=" + target.key,
                        target: target,
                      });
                    }}>
                    <button className="btn btn-light">AVAA</button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default CreatedTransactionTargetTable;
