import { useMemo } from "react";
import linq from "linq";
import { SortingType } from "./SortingTypes";
import _ from "lodash";

/**
 * A custom hook to sort an array of items based
 * on sortConfig
 * @param {*} sortConfig Object containing the property name and SortingType
 * { propertyName: "name", sortType: SortingType.Ascending }
 * @param {*} tableItems array of items to sort
 * @returns
 */
export default <T>(
  sortConfig: {
    propertyName: string;
    sortType: typeof SortingType.Ascending | typeof SortingType.Descending;
  },
  tableItems: Array<T>,
): Array<T> => {
  const sortedRows = useMemo<Array<T>>(() => {
    let sorted = linq.from(tableItems).orderBy(() => 1);
    if (sortConfig.sortType === SortingType.Ascending) {
      sorted = sorted
        .thenBy(dataRow =>
          // @ts-ignore
          !_.isNil(dataRow[sortConfig.propertyName]) ? -1 : 1,
        )
        // @ts-ignore
        .thenBy(dataRow => dataRow[sortConfig.propertyName]);
    } else {
      sorted = sorted
        .thenByDescending(dataRow =>
          // @ts-ignore
          !_.isNil(dataRow[sortConfig.propertyName]) ? 1 : -1,
        )
        // @ts-ignore
        .thenByDescending(dataRow => dataRow[sortConfig.propertyName]);
    }
    return sorted.toArray();
  }, [sortConfig, tableItems]);

  return sortedRows;
};
