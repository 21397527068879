import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import ThumbnailRemoveIcon from "../ThumbnailRemoveIcon";
import { collection, doc, setDoc } from "@firebase/firestore";
import {
  db,
  largeCustomerReportPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import { appendLog, currentUserLogInfo, logEvents } from "../logging/logUtils";
import { arrayUnion, updateDoc } from "firebase/firestore";
import { REPORTED, REPORT_CREATED } from "../transaction/TransactionStates";
import { nextImageIndex } from "../image/imgUtil";
import { ASPHALT_DAMAGE } from "../image/imageClassifications";
import ReportExtraWork from "./ReportExtraWork";
import moment from "moment";

/**
 * Renders a form which can be used to create a report for a transaction.
 * @param {*} param0
 * @returns
 */
const ReportCreateModal = ({
  visible = false,
  onHide,
  transactionDocument,
  title = "Uusi raportti",
  onComplete,
  reportDoc,
}) => {
  const [report, setReport] = useState({
    feedback: null,
    log: [],
    organization: transactionDocument.organization,
    pictures: [],
    shortReport: "",
    target: transactionDocument.target,
    transaction: transactionDocument.key,
  });

  const [extraWork, setExtraWork] = useState({
    shortReport: "",
    pictures: [],
    price: 0,
  });
  const [extraWorkFiles, setExtraWorkFiles] = useState([]);
  const [extraWorkImageUrls, setExtraWorkImageUrls] = useState([]);

  useEffect(() => {
    if (reportDoc) {
      setReport(reportDoc);
      if (reportDoc.extraWork) {
        setExtraWork(reportDoc.extraWork);
      }
    }
  }, [reportDoc]);

  const [files, setFiles] = useState([]);
  const [reportImageUrls, setReportImageUrls] = useState([]);

  const repairm2Sum = useMemo(() => {
    return transactionDocument.pictures.classified.reduce((sum, picture) => {
      if (!picture.rejected && picture.classification === ASPHALT_DAMAGE) {
        const size = parseInt(picture.size);
        if (!isNaN(size)) {
          sum += size;
        }
      }
      return sum;
    }, 0);
  }, [transactionDocument]);

  const onDrop = useCallback(
    acceptedFiles => {
      const oldFiles = [...files];
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles(_.unionBy([...oldFiles, ...newFiles], "name"));
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    (async () => {
      const urls = [];
      const storage = getStorage();
      for await (const imageKey of report.pictures) {
        const url = await getDownloadURL(ref(storage, imageKey));
        urls.push({ url: url, key: imageKey });
      }
      setReportImageUrls(urls);
    })();
  }, [report.pictures, setReportImageUrls]);

  useEffect(() => {
    if (report.extraWork?.pictures) {
      (async () => {
        const urls = [];
        const storage = getStorage();
        for await (const imageKey of report.extraWork.pictures) {
          const url = await getDownloadURL(ref(storage, imageKey));
          urls.push({ url: url, key: imageKey });
        }
        setExtraWorkImageUrls(urls);
      })();
    }
  }, [report.extraWork?.pictures, setExtraWorkImageUrls]);

  /**
   * Report submit handler
   */
  const onSave = async (state = REPORT_CREATED) => {
    // create report
    console.log("Save report");
    // pre-create report ref for ID
    let reportRef = doc(collection(db, largeCustomerReportPath));
    if (report.key) {
      reportRef = doc(collection(db, largeCustomerReportPath), report.key);
    }
    //const reportRef = doc(collection(db, largeCustomerReportPath));

    const storage = getStorage();
    // upload files
    let index = nextImageIndex(report.pictures);
    const uploadedImages = [];
    for await (const file of files) {
      const key = reportRef.id + "/" + reportRef.id + "_" + index + ".jpg";
      index++;
      const imgRef = ref(storage, key);
      try {
        await uploadBytes(imgRef, file);
        uploadedImages.push(key);
      } catch (error) {
        console.log("Error while uploading image", error);
      }
    }

    // handle extra work
    // upload its files
    index = nextImageIndex(extraWork.pictures);
    const uploadedExtraWorkImages = [];
    for await (const file of extraWorkFiles) {
      const key =
        reportRef.id + "/" + reportRef.id + "_extra_work" + index + ".jpg";
      index++;
      const imgRef = ref(storage, key);
      try {
        await uploadBytes(imgRef, file);
        uploadedExtraWorkImages.push(key);
      } catch (error) {
        console.log("Error while uploading image", error);
      }
    }

    // parse price as number, if it isn't already
    let extraWorkPrice = extraWork.price;
    if (typeof extraWorkPrice !== "number") {
      // finnish use comma as decimal separator
      // so we'll replace it with a dot, so we can attempt to
      // parse it below
      extraWorkPrice = extraWorkPrice.replace(",", ".");
      extraWorkPrice = Number(extraWorkPrice);
      if (isNaN(extraWorkPrice)) {
        throw new Error("Failed to parse extra work price", extraWork.price);
      }
    }

    const newExtraWork = { ...extraWork, price: extraWorkPrice };
    newExtraWork.pictures = [...extraWork.pictures, ...uploadedExtraWorkImages];
    const extraWorkFields = ["shortReport", "pictures", "price"];
    const filteredExtraWork = _.pick(newExtraWork, extraWorkFields);

    const currentUserInfo = await currentUserLogInfo();
    // append log and merge report + extra work
    const newReport = appendLog(
      reportDoc ? logEvents.LOG_UPDATE : logEvents.LOG_CREATE,
      null,
      null,
      { ...report, extraWork: { ...filteredExtraWork } },
      currentUserInfo,
    );
    newReport.pictures = [...newReport.pictures, ...uploadedImages];

    // keep only important fields
    const fields = [
      "feedback",
      "organization",
      "pictures",
      "shortReport",
      "target",
      "transaction",
      "log",
      "extraWork",
    ];
    const filteredReport = _.pick(newReport, fields);

    setDoc(reportRef, filteredReport)
      .then(() => {
        // clear file states
        setFiles([]);
        setExtraWorkFiles([]);
        // update TX state
        // and updated at
        const txRef = doc(
          db,
          largeCustomerTransactionPath,
          transactionDocument.key,
        );
        updateDoc(txRef, {
          state: state,
          updatedAt: Math.floor(Date.now() / 1000),
          log: arrayUnion({
            changedValues: null,
            event: logEvents.LOG_UPDATE,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
            user: currentUserInfo,
          }),
        })
          .then(() => {
            onComplete(state);
          })
          .catch(err => {
            console.log("Error while updating TXs state", err);
          });
      })
      .catch(err => {
        console.log("Error while uploading report", err);
      });
  };

  const thumbnails = files.map((file, index) => {
    return (
      <React.Fragment key={file.name}>
        <div className="thumbnail" style={{ marginRight: "5%" }}>
          <div className="thumbnail-inner">
            <img src={file.preview} alt="" />
          </div>
          <ThumbnailRemoveIcon
            onClick={() => {
              const _files = [...files];
              const thisFile = _files[index];
              if (thisFile.preview) URL.revokeObjectURL(thisFile.preview);
              _files.splice(index, 1);
              setFiles(_files);
            }}
          />
        </div>
      </React.Fragment>
    );
  });

  const uploadedThumbnails = reportImageUrls.map((url, index) => {
    return (
      <React.Fragment key={url.key}>
        <div className="thumbnail" style={{ marginRight: "5%" }}>
          <div className="thumbnail-inner">
            <img src={url.url} alt="" />
          </div>
          <ThumbnailRemoveIcon
            onClick={() => {
              const imgs = [...reportImageUrls];

              const reportPictures = [...report.pictures];
              const foundIndex = reportPictures.findIndex(
                imgKey => imgKey === url.key,
              );
              if (foundIndex >= 0) {
                reportPictures.splice(foundIndex, 1);
                imgs.splice(index, 1);
                setReportImageUrls(imgs);
                setReport({ ...report, pictures: reportPictures });
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  });

  const projectTotalPrice = useMemo(() => {
    let price = Number(transactionDocument.offer.price);
    if (extraWork) {
      price += Number(extraWork.price);
    }
    return price;
  }, [extraWork, transactionDocument]);

  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <div className="text-center">
          <h4>{title}</h4>
        </div>
        <Box m={2} />
        <TextField
          disabled
          fullWidth={true}
          label="Korjausneliöt yhteensä"
          value={repairm2Sum}
          InputProps={{
            endAdornment: <InputAdornment position="end">m2</InputAdornment>,
          }}
        />
        <TextField
          multiline={true}
          label="Korjauksen tiedot"
          placeholder="Lyhyt raportti"
          rows={3}
          value={report.shortReport}
          fullWidth={true}
          onChange={e => {
            const _report = { ...report };
            _report.shortReport = e.target.value;
            setReport(_report);
          }}
        />
        <Box m={2} />
        <p className="input-title">Kuvat</p>
        <div
          className={
            isDragActive
              ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
              : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
          }
          {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="input-text">
            Vedä tiedostot tähän tai{" "}
            <span className="link">valitse ne tietokoneestasi</span>
          </p>
        </div>
        <Box m={2} />
        {/* new images */}
        {files.length > 0 && (
          <div className="d-flex flex-row align-items-center flex-wrap justify-content-center">
            {thumbnails}
          </div>
        )}
        {/* already uploaded images */}
        {report.pictures.length > 0 && (
          <div className="d-flex flex-row align-items-center flex-wrap justify-content-center">
            {uploadedThumbnails}
          </div>
        )}
        <Box m={2} />
        <ReportExtraWork
          extraWork={extraWork}
          setExtraWork={setExtraWork}
          extraWorkFiles={extraWorkFiles}
          setExtraWorkFiles={setExtraWorkFiles}
          extraWorkImageUrls={extraWorkImageUrls}
          setExtraWorkImageUrls={setExtraWorkImageUrls}
        />
        <Box m={2} />
        <p className="mb-0">
          <span>Asfalttityön hinta</span>
          {": "}
          {transactionDocument.offer.price} €
        </p>
        <p className="mb-0">
          <span>Lisätöiden hinta</span>
          {": "}
          {extraWork.price} €
        </p>
        <p>
          <strong>
            <span>Kokonaishinta</span>
            {": "} {projectTotalPrice} €
          </strong>
        </p>
        <Box m={2} />
        <div className="d-flex flex-row justify-content-evenly">
          <button className="btn btn-primary" onClick={() => onSave(REPORTED)}>
            TALLENNA
          </button>
        </div>
        <div className="d-flex flex-row justify-content-evenly mt-2">
          <button
            className="btn btn-primary"
            onClick={() => onSave(REPORT_CREATED)}>
            TALLENNA LUONNOKSENA
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReportCreateModal;
