import React from "react";
import clsx from "clsx";
import { Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    minWidth: "unset",
  },
};

const StyledTabs = props => {
  const { classes, children, className, ...other } = props;
  return (
    <Tabs className={clsx(classes.root, className)} {...other}>
      {children}
    </Tabs>
  );
};

export default withStyles(styles)(StyledTabs);
