import React, { useEffect, useState, useCallback } from "react";
import {
  onSnapshot,
  collection,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  setClient,
  setClients,
  setFilteredClients,
} from "./largeCustomerSlice";
import _ from "lodash";
import useClientTargetCount from "../../components/client/useClientTargetCount";

import ClientTable from "../../components/largeCustomers/ClientTable";
import { Box, TextField } from "@material-ui/core";
import ClientCreateModal from "../../components/client/ClientCreateModal";
import ClientImportModal from "../../components/client/ClientImportModal";
import ClientRemoveConfirmationModal from "../../components/client/ClientRemoveConfirmationModal";
import {
  deleteClientContacts,
  deleteClientReports,
  deleteClientTargets,
  deleteClientTransactions,
} from "./util/functions";
import NewExistingContact from "../../components/client/NewExistingContact";
import ContactAutocomplete from "../../components/client/ContactAutocomplete";

const TAG = "[Clients]";

/**
 * "Asiakkaat" view
 * @returns
 */
const Clients = () => {
  const dispatch = useDispatch();
  const { filteredClients, clients } = useSelector(
    state => state.largeCustomer,
  );
  const { targets } = useSelector(state => state.target);
  const [clientNameFilter, setClientNameFilter] = useState("");
  const [clientContactFilter, setClientContactFilter] = useState("");
  const [clientCityFilter, setClientCityFilter] = useState("");

  const [clientModalVisible, setClientModalVisible] = useState(false);
  const [clientImportModalVisible, setClientImportModalVisible] =
    useState(false);

  const [checkedItems, setCheckedItems] = useState([]);

  const [clientRemoveModalVisible, setClientRemoveModalVisible] =
    useState(false);

  /**
   * Subscribes to client documents
   */
  useEffect(() => {
    const q = query(collection(db, largeCustomerCollectionPath));
    const clientUnsub = onSnapshot(q, docSnapshot => {
      const clientDocs = [];
      docSnapshot.forEach(doc => {
        const _data = doc.data();
        // clients are never really deleted, but removed is set to true
        if (!_data.removed) clientDocs.push({ key: doc.id, ...doc.data() });
      });
      dispatch(setClients(clientDocs));
    });

    return () => {
      //console.log(TAG, "Unsubscribing from clients");
      clientUnsub();
    };
  }, [dispatch]);

  // returns an object which has open and resolved target counts
  // mapped to a client id, example: {"someClientKey": {open: 11, closed: 0}, ...}
  const { countMap } = useClientTargetCount(clients);

  /**
   * Pushes open and closed count values to client documents
   */
  useEffect(() => {
    // exit early if countMap is empty
    if (_.isEmpty(countMap)) return;
    let isCanceled = false;
    const editedClients = [];
    for (const client of clients) {
      const counts = countMap[client.key] ?? null;
      if (counts) {
        editedClients.push({
          ...client,
          openCount: counts.open,
          closedCount: counts.closed,
        });
      } else {
        editedClients.push({ ...client, openCount: null, closedCount: null });
      }
    }
    if (!_.isEqual(clients, editedClients) && !isCanceled)
      dispatch(setClients(editedClients));
    return () => {
      isCanceled = true;
    };
  }, [countMap, clients, dispatch]);

  const removeCheckedClients = useCallback(() => {
    (async () => {
      /**
       * example of clientItem
       * {
       * address: "Some address",
       * client: {// actual client doc},
       * key: "2kldsksKXKDjmfmgfl",
       * closedCount: 0,
       * openCount: 0
       * name: ""
       * }
       */
      for await (const clientItem of checkedItems) {
        const docRef = doc(db, largeCustomerCollectionPath, clientItem.key);

        // delete targets
        await deleteClientTargets(clientItem.key);
        // delete transactions
        await deleteClientTransactions(clientItem.key);
        // delete reports
        await deleteClientReports(clientItem.key);

        // delete client contacts
        await deleteClientContacts(clientItem.client?.contacts ?? []);

        // delete client itself
        await deleteDoc(docRef);
      }
      setCheckedItems([]);
      setClientRemoveModalVisible(false);
    })();
  }, [checkedItems, setCheckedItems, setClientRemoveModalVisible]);

  useEffect(() => {
    let _filteredClients = clients;
    if (clientNameFilter) {
      _filteredClients = _filteredClients.filter(client => {
        return client.name
          .toLowerCase()
          .includes(clientNameFilter.toLowerCase());
      });
    }
    if (clientCityFilter) {
      _filteredClients = _filteredClients.filter(client => {
        return client.postalDistrict
          .toLowerCase()
          .includes(clientCityFilter.toLowerCase());
      });
    }
    if (clientContactFilter) {
      _filteredClients = _filteredClients.filter(client => {
        return client.contacts.includes(clientContactFilter);
      });
    }
    dispatch(setFilteredClients(_filteredClients));
  }, [
    dispatch,
    clientNameFilter,
    clientCityFilter,
    clientContactFilter,
    clients,
  ]);

  return (
    <>
      <ClientRemoveConfirmationModal
        visible={clientRemoveModalVisible}
        onConfirm={() => removeCheckedClients()}
        onCancel={() => setClientRemoveModalVisible(false)}
        checkedCount={checkedItems.length}
      />
      <ClientImportModal
        visible={clientImportModalVisible}
        onHide={() => {
          setClientImportModalVisible(false);
        }}
      />
      <ClientCreateModal
        visible={clientModalVisible}
        onHide={() => {
          dispatch(setClient(null));
          setClientModalVisible(false);
        }}
      />
      <Box m={5} />
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-primary"
          onClick={() => {
            console.log(TAG, "Create client");
            const newClient = {
              address: "",
              billing: {
                billingAddress: "",
                eBilling: "",
                eBillingProvider: "",
              },
              contacts: [],
              name: "",
              postalCode: "",
              postalDistrict: "",
              y_id: "",
            };
            dispatch(setClient(newClient));
            setClientModalVisible(true);
          }}>
          LUO ASIAKAS
        </button>
        <Box m={2} />
        <button
          className="btn btn-transparent"
          onClick={() => {
            console.log(TAG, "Import clients");
            setClientImportModalVisible(true);
          }}>
          TUO ASIAKKAITA
        </button>
        {checkedItems.length > 0 && (
          <>
            <Box m={2} />
            <button
              className="btn btn-transparent"
              onClick={() => setClientRemoveModalVisible(true)}>
              POISTA VALITUT
            </button>
          </>
        )}
      </div>
      <Box m={3} />
      <div className="d-flex flex-row">
        <TextField
          value={clientNameFilter}
          onChange={e => {
            setClientNameFilter(e.target.value);
          }}
          placeholder="Hae asiakkaan nimellä"
        />
        <Box m={1} />
        <ContactAutocomplete
          onSelectContact={contact => {
            console.log("selected contact", contact);
            setClientContactFilter(contact);
          }}
        />
        <Box m={1} />
        <TextField
          value={clientCityFilter}
          onChange={e => {
            setClientCityFilter(e.target.value);
          }}
          placeholder="Hae paikkakunnalla"
        />
      </div>
      <Box m={3} />
      <ClientTable
        clients={filteredClients}
        targets={targets}
        countsFetched={!_.isEmpty(countMap)}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
    </>
  );
};

export default Clients;
