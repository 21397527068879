import { Box } from "@material-ui/core";
import React from "react";

const TotalStatBox = ({ suffix = "", title, number }) => {
  return (
    <div className="d-flex flex-column stats-container">
      <span style={{ color: "#7E84A3", fontSize: 16 }}>{title}</span>
      <Box m={1} />
      <span style={{ color: "#131523", fontSize: 28, fontWeight: "bold" }}>
        {number} {suffix}
      </span>
    </div>
  );
};

export default TotalStatBox;
