import { FormControlLabel, Switch, TextField } from "@material-ui/core";
import React from "react";
import RemoveIcon from "../RemoveIcon";

/**
 * Phone number input wrapper
 * @param {*} numberObject Phone number object {number: "+35812132", switchState: false, label: "Työ"}
 * @param {*} onRemove onRemove handler
 * @param {*} setNumberObject numberObject update handler
 * @param {*} error Flag to indicate if there's an error in validation
 * @returns
 */
const NumberInput = ({ numberObject, onRemove, setNumberObject, error }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <FormControlLabel
          label={numberObject.label}
          control={
            <Switch
              checked={numberObject.switchState}
              onChange={e => {
                const newNumber = { ...numberObject };
                newNumber.switchState = !newNumber.switchState;
                newNumber.label =
                  newNumber.switchState === true ? "Oma" : "Työ";
                setNumberObject(newNumber);
              }}
            />
          }
        />
        <RemoveIcon size={30} onClick={onRemove} />
      </div>
      <TextField
        label="Puhelinnumero"
        placeholder="+358123123"
        error={error}
        helperText={error ? "Puhelinnumero tulee olla muodossa +358" : ""}
        onChange={e =>
          setNumberObject({ ...numberObject, number: e.target.value })
        }
      />
    </div>
  );
};

export default NumberInput;
