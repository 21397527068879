import { useEffect, useState } from "react";
import NavBar from "./components/nav/NavBar";
import { Route } from "react-router-dom";
import Clients from "./route/admin/Clients";
import Targets from "./route/admin/Targets";
import ClientDetails from "./components/client/ClientDetails";
import Frontpage from "./route/admin/Frontpage";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db, firebaseApp, userCollectionPath } from "./firebase";
import Login from "./auth/Login";
import Loading from "./components/Loading";
import TargetDetails from "./components/target/TargetDetails";
import TransactionDetails from "./components/transaction/TransactionDetails";
import Contacts from "./route/admin/Contacts";
import Users from "./route/admin/Users";
import ContactDetails from "./components/contact/ContactDetails";
import UserDetails from "./components/user/UserDetails";
import { doc, getDoc, onSnapshot } from "@firebase/firestore";
import { ADMIN, OFFICE_WORKER } from "./components/user/userRoles";
import { setUser } from "./components/user/userSlice";
import { useDispatch } from "react-redux";
import CreatedStatusTransactions from "./route/admin/CreatedStatusTransactions";

function App() {
  // flag to direct user to the correct view
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [firebaseUser, setFirebaseUser] = useState(null);

  const auth = getAuth(firebaseApp);
  const dispatch = useDispatch();

  useEffect(() => {
    let userUnSub;

    if (firebaseUser?.uid) {
      userUnSub = onSnapshot(
        doc(db, userCollectionPath, firebaseUser.uid),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          console.log("user changed, dispatching");
          dispatch(setUser({ key: key, ...data }));
        },
        error => {
          console.warn("Error while subscribing to user doc", error);
        },
      );
    }

    return () => {
      if (userUnSub) {
        userUnSub();
      }
    };
  }, [firebaseUser?.uid, dispatch]);

  /**
   * Listen for auth state changes, if user is
   * set isAuthenticated as false.
   * If not, set as true.
   */
  onAuthStateChanged(auth, user => {
    if (user === null) {
      setIsAuthenticated(false);
    } else {
      setFirebaseUser(user);
      // fetch user doc and check roles
      checkUserRoles(user.uid).then(rolesOk => {
        if (rolesOk) {
          setLoginError("");
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          setLoginError("Ei käyttöoikeutta");
        }
      });
    }
  });

  const checkUserRoles = async userId => {
    const docRef = doc(db, userCollectionPath, userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      dispatch(setUser({ key: docSnap.id, ...data }));
      return [ADMIN, OFFICE_WORKER].some(role => data.roles.includes(role));
    }
    return false;
  };

  if (isAuthenticated === null) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  if (isAuthenticated === false) {
    return (
      <Route path="/">
        <Login loginError={loginError} />
      </Route>
    );
  }

  return (
    <>
      <NavBar />
      <div className="container-md">
        <Route exact path="/">
          <Frontpage />
        </Route>
        <Route path="/asiakkaat">
          <Clients />
        </Route>
        <Route
          path="/asiakas"
          render={props => <ClientDetails {...props} />}></Route>
        <Route path="/kohteet">
          <Targets />
        </Route>
        <Route
          path="/kohde"
          render={props => <TargetDetails {...props} />}></Route>
        <Route
          path="/tilaus"
          render={props => <TransactionDetails {...props} />}></Route>
        <Route path="/yhteyshenkilot">
          <Contacts />
        </Route>
        <Route
          path="/yhteystieto"
          render={props => <ContactDetails {...props} />}></Route>
        <Route path="/asfalttipartio">
          <Users />
        </Route>
        <Route
          path="/tarkastettavat"
          render={props => <CreatedStatusTransactions {...props} />}></Route>
        <Route
          path="/kayttaja"
          render={props => <UserDetails {...props} />}></Route>
        {/* this one is removed from the designs, I'll leave it here for now */}
        <Route path="/asetukset">
          <></>
        </Route>
      </div>
    </>
  );
}

export default App;
