import { Box } from "@material-ui/core";
import { Modal } from "react-bootstrap";

const TransactionDeleteModal = ({ onCancel, onDelete, visible }) => {
  return (
    <Modal show={visible} onHide={onCancel} centered>
      <Modal.Body>
        <div className="text-center">
          <h4>Kartoituksen poisto</h4>
        </div>
        <Box m={2} />
        <p className="text-center">Haluatko varmasti poistaa kartoituksen?</p>
        <Box m={2} />
        <div className="d-flex flex-row justify-content-center gap-2">
          <button className="btn btn-danger" onClick={onDelete}>
            Kyllä, poista kartoitus
          </button>
          <button className="btn btn-secondary" onClick={onCancel}>
            Peruuta
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionDeleteModal;
