import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Box, TextField } from "@material-ui/core";
import NewExistingContact from "../client/NewExistingContact";
import _ from "lodash";
import RemoveIcon from "../RemoveIcon";
import Loading from "../Loading";

/**
 * Renders a modal which can be used to updated/create new Target documents, to
 * a predefined client.
 * @param {*} isVisible modal visibility flag
 * @param {*} onHide modal hide handler
 * @param {*} target target document being rendered
 * @param {*} setTarget target document update handler
 * @param {*} onSubmit Submit button handler
 * @param {*} client Targets clients data
 * @param {*} submitting Flag to indicate if the form is submitting
 * @param {*} targetSubmitError Error text that is set if an error occurs while submitting
 * @returns
 */
const TargetUpdateModal = ({
  isVisible = false,
  onHide,
  target,
  setTarget,
  onSubmit,
  client,
  submitting,
  targetSubmitError,
  contacts,
  title = "Uusi kohde",
}) => {
  // flag to indicate if the user is adding a new contact
  const [addingNewContact, setAddingNewContact] = useState(false);
  // internal state for updated contact list
  const [updatedContacts, setUpdatedContacts] = useState(contacts);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (!contacts) return;
    setUpdatedContacts(contacts);
  }, [contacts]);

  /**
   * Validates submit
   * @param {*} targetDoc
   * @returns
   */
  const isValid = targetDoc => {
    if (_.isEmpty(targetDoc.contact) && targetDoc.contacts?.length === 0) {
      setErrorText("Valitse yhteyshenkilö!");
      return false;
    } else if (_.isEmpty(targetDoc.name)) {
      setErrorText("Syötä nimi!");
    } else if (_.isEmpty(targetDoc.postalCode)) {
      setErrorText("Syötä postinumero!");
    } else if (_.isEmpty(targetDoc.postalDistrict)) {
      setErrorText("Syötä postitoimipaikka!");
    }
    return true;
  };

  /**
   * Calls onSubmit if valid form
   * @param {*} newTarget
   */
  const submit = newTarget => {
    setErrorText("");
    // create a target document that would be submitted to
    // firebase if validated successfully

    const contactIds = updatedContacts.map(c => c.uid);
    const targetDoc = { ...newTarget, contacts: contactIds };
    if (isValid(targetDoc)) {
      console.log("Target valid", newTarget);
      onSubmit(targetDoc);
    }
  };

  if (!target || !client) return null;
  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Body>
        <div className="text-center">
          <h4>{title}</h4>
        </div>
        <Box m={2} />
        {/* organization section, it should always be defined and cannot be changed */}
        <TextField
          fullWidth={true}
          disabled={true}
          value={client.name}
          label="Organisaatio"
        />
        <Box m={2} />
        {/* name section */}
        <TextField
          fullWidth={true}
          value={target.name}
          label="Nimi"
          placeholder="Kohteen nimi"
          onChange={e => setTarget({ ...target, name: e.target.value })}
          required={true}
        />
        <Box m={2} />
        {/* address section */}
        <TextField
          fullWidth={true}
          value={target.address}
          label="Osoite"
          placeholder="Osoite"
          onChange={e => setTarget({ ...target, address: e.target.value })}
          required={true}
        />
        <Box m={2} />
        {/* postal code section */}
        <TextField
          fullWidth={true}
          value={target.postalCode}
          label="Postinumero"
          placeholder="00000"
          onChange={e => setTarget({ ...target, postalCode: e.target.value })}
          required={true}
        />
        <Box m={2} />
        {/* postal district section */}
        <TextField
          fullWidth={true}
          value={target.postalDistrict}
          label="Postitoimipaikka"
          placeholder="Oulu"
          onChange={e =>
            setTarget({ ...target, postalDistrict: e.target.value })
          }
          required={true}
        />
        <Box m={2} />
        {/* additional info */}
        <TextField
          fullWidth={true}
          value={target.additionalInfo}
          label="Lisätiedot"
          multiline
          rows={3}
          onChange={e =>
            setTarget({ ...target, additionalInfo: e.target.value })
          }
        />
        <Box m={2} />
        {/* contact section */}
        <p className="input-title">Yhteyshenkilöt *</p>
        {updatedContacts &&
          updatedContacts.map((contact, index) => {
            return (
              <div
                key={"contact" + index}
                className="d-flex flex-row contact-row align-items-center justify-content-between">
                <p className="mb-0">{contact.name}</p>
                <div>
                  <RemoveIcon
                    size={30}
                    onClick={() => {
                      setUpdatedContacts(
                        updatedContacts.filter(c => c.uid !== contact.uid),
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}

        <NewExistingContact
          visible={addingNewContact}
          onSelectContact={contact => {
            const existingContacts = updatedContacts ?? [];
            setUpdatedContacts([...existingContacts, contact]);
            setAddingNewContact(false);
          }}
        />
        {!addingNewContact && (
          <button
            className="btn btn-primary"
            onClick={() => setAddingNewContact(true)}>
            Lisää uusi
          </button>
        )}
        <Box m={2} />
        {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
        {targetSubmitError && targetSubmitError.length > 0 && (
          <p className="text-danger">{targetSubmitError}</p>
        )}
        {!submitting && (
          <button className="btn btn-primary" onClick={() => submit(target)}>
            Tallenna
          </button>
        )}
        {submitting && <Loading />}
      </Modal.Body>
    </Modal>
  );
};

export default TargetUpdateModal;
