import React, { useState, useEffect, useCallback } from "react";
import { TextField, Select, MenuItem, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, userCollectionPath } from "../../firebase";

const ContactAutocomplete = ({ onSelectContact }) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Constructs a firebase query based on is the search string empty or not.
   * Empty string will returns up to 5 contacts from the current client, sorted by name.
   * A defined string will act like an autocomplete feature, returning names that are greater
   * than "capitalized" string but less than "limitString", which is lexicographically greater
   * than the "capitalized" string. See {@link constructLimitString} for details on that.
   *
   * @param {*} capitalized
   * @param {*} limitString
   * @returns
   */
  const constructQuery = useCallback((capitalized, limitString) => {
    if (capitalized.length === 0) {
      return query(
        collection(db, userCollectionPath),
        where("roles", "array-contains", "client"),
        orderBy("name"),
        limit(5),
      );
    }
    return query(
      collection(db, userCollectionPath),
      where("name", ">=", capitalized),
      where("name", "<", limitString),
      where("roles", "array-contains", "client"),
      orderBy("name"),
      limit(5),
    );
  }, []);

  /**
   * Queries firebase user collection with keyword.
   * Tries to match keyword to the name field.
   */
  useEffect(() => {
    let isCanceled = false;
    const capitalized = _.startCase(_.toLower(keyword));
    const limitString = constructLimitString(capitalized);
    const q = constructQuery(capitalized, limitString);
    if (!isCanceled) {
      (async () => {
        const contacts = await getDocs(q);
        const contactDocs = [];
        contacts.forEach(doc => {
          contactDocs.push(doc.data());
        });
        if (!isCanceled) setResults(contactDocs);
      })();
    }
    return () => {
      isCanceled = true;
    };
  }, [keyword, constructLimitString, constructQuery]);

  return (
    <>
      <Autocomplete
        options={results}
        getOptionLabel={option => option.name}
        onChange={(e, contact) => {
          if (contact) {
            onSelectContact(contact.uid);
          } else {
            onSelectContact("");
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            style={{ width: "200px" }}
            placeholder="Hae yhteyshenkilöä"
            onChange={e => {
              console.log("ac change", e.target.value);
              setKeyword(e.target.value);
            }}></TextField>
        )}
      />
    </>
  );
};

export default ContactAutocomplete;
