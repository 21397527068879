import React, { useState, useEffect, useMemo } from "react";
import UserInfo from "./UserInfo";
import qs from "qs";
import _ from "lodash";
import { doc, onSnapshot } from "@firebase/firestore";
import { db, userCollectionPath } from "../../firebase";
import Loading from "../Loading";
import UserUpdateModal from "./UserUpdateModal";
import { updateDoc } from "firebase/firestore";

/**
 * Renders a component that displays detailed information about a user
 * @param {*} location location params
 * @returns
 */
const UserDetails = ({ location }) => {
  const [userDocument, setUserDocument] = useState();

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [userSubmitting, setUserSubmitting] = useState(false);

  /**
   * Gets the current users ID from either locations user field
   * or parses it from query parameters.
   */
  const userId = useMemo(() => {
    const userDoc = location?.user;
    if (userDoc) {
      return userDoc.uid;
    } else {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const id = queryParams.id;
      if (!id) {
        console.warn("User ID was not defined!");
        return null;
      }
      return id;
    }
  }, [location]);

  /**
   * Subscribes to the user document
   */
  useEffect(() => {
    if (_.isNil(userId)) return;
    const unSub = onSnapshot(
      doc(db, userCollectionPath, userId),
      docSnapshot => {
        setUserDocument(docSnapshot.data());
      },
      error => {
        console.warn("Error while subscribing to user document", error);
      },
    );

    return () => unSub();
  }, [userId, setUserDocument]);

  /**
   * Update user handler
   * @param {*} userData Updated user data
   * @returns
   */
  const onUpdatedUser = userData => {
    if (userSubmitting) return;
    setUserSubmitting(true);
    const userRef = doc(db, userCollectionPath, userData.uid);
    updateDoc(userRef, userData)
      .then(() => {
        setUserSubmitting(false);
      })
      .catch(error => {
        console.log("Error while updating contact", error);
        setUserSubmitting(false);
      });
    // trying out optimistic updating here, hide the modal straight away
    setUpdateModalVisible(false);
  };

  if (_.isNil(userDocument)) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <UserUpdateModal
        visible={updateModalVisible}
        user={userDocument}
        setUser={setUserDocument}
        onHide={() => setUpdateModalVisible(false)}
        onComplete={updatedUser => {
          onUpdatedUser(updatedUser);
        }}
      />
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <UserInfo
            userDocument={userDocument}
            onEditUser={() => {
              console.log("edit user call");
              setUpdateModalVisible(true);
            }}
          />
        </div>
        {/* perhaps target table here or something, if we decide to go that way */}
      </div>
    </>
  );
};

export default UserDetails;
