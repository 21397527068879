import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { tabLabels } from "./imageClassifications";
import _ from "lodash";

/**
 * Gets download URLs for image references in classified image objects.
 * Uses tabLabels as keys for classifications, to more easily
 * render the images later in ClassifiedImages component.
 * @param {*} imageRefs
 * @returns
 */
const getImageURLs = imageRefs => {
  return new Promise(async resolve => {
    const storage = getStorage();
    const classifiedUrls = {};

    for await (const image of imageRefs) {
      const label = tabLabels[image.classification];
      const urls = classifiedUrls[label] ?? [];
      const url = await getDownloadURL(ref(storage, image.imageUri));
      urls.push({ downloadURL: url, ...image });
      classifiedUrls[label] = urls;
    }

    resolve(classifiedUrls);
  });
};

/**
 * Returns the next available index for an imageUri.
 *
 * We can't actually just use the length of the image array, because
 * let's say there's 3 images: indexes 0 1 2 and if the user removes
 * index number 0, that leaves us 2 images... 1 and 2.
 * Now if were to get the length of the array (which is 2)
 * and use that as the image index, it'd collide with the existing
 * image that has an index of 2. So instead this function will loop through those
 * images, and look for the highest number in the imageUris, and increment that by 1.
 *
 * ImageUris will always follow this format: transactionId/transactionId_index.jpg
 * i.e. JVXhQvWbeVMyGAc5u5kH/JVXhQvWbeVMyGAc5u5kH_0.jpg
 *
 * Returns 0 if used on an empty array
 * @param {*} imageUris
 */
const nextImageIndex = imageUris => {
  if (imageUris.length === 0) return 0;
  const regex = /_(\d)./;
  // use lodash to find the largest number in the array that reduce
  // from imageUris.
  const max = _.max(
    imageUris.reduce((numberArr, uri) => {
      const indexString = uri.match(regex);
      // parse if exists
      if (indexString) {
        const index = parseInt(indexString[1]);
        numberArr.push(index);
      }
      return numberArr;
    }, []),
  );
  return max + 1;
};

export { getImageURLs, nextImageIndex };
