import React from "react";
import { NavLink } from "react-router-dom";
import LogoutButton from "../../components/LogoutButton";

/**
 * Navigation element
 * @returns
 */
const NavBar = () => {
  return (
    <nav>
      <div className="nav-link-container">
        <NavLink exact to="/" activeClassName="selected">
          Etusivu
        </NavLink>
        <NavLink
          to="/asiakkaat"
          activeClassName="selected"
          isActive={(match, location) => {
            if (match && match.isExact) {
              return true;
            }
            const matches = ["/asiakas"];
            if (matches.includes(location.pathname)) {
              return true;
            }
          }}>
          Asiakkaat
        </NavLink>
        <NavLink
          to="/kohteet"
          activeClassName="selected"
          isActive={(match, location) => {
            if (match && match.isExact) {
              return true;
            }
            const matches = ["/kohde"];
            if (matches.includes(location.pathname)) {
              return true;
            }
          }}>
          Kohteet
        </NavLink>
        <NavLink exact to="/yhteyshenkilot" activeClassName="selected">
          Yhteyshenkilöt
        </NavLink>
        <NavLink exact to="/asfalttipartio" activeClassName="selected">
          Asfalttipartio
        </NavLink>
        <NavLink exact to="/tarkastettavat" activeClassName="selected">
          Tarkastettavat
        </NavLink>
      </div>
      <LogoutButton />
    </nav>
  );
};

export default NavBar;
