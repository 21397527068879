import { useEffect, useState } from "react";
import _ from "lodash";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";

/**
 * Returns an object which maps organization IDs to their firebase documents.
 * Example: {ZQLKL7rwC0mu6xKwOaz9: {name: "Testi organisaatio", y_id: "321321", ...}, ...}
 * @param {*} contacts
 * @returns
 */
const useOrganizationNameResolver = contacts => {
  const [organizationIds, setOrganizationIds] = useState();
  const [organizationDocuments, setOrganizationDocuments] = useState(null);

  /**
   * Sets a list of unqiue organization IDs into the organizationIds state
   */
  useEffect(() => {
    if (!contacts) return;
    const orgIds = contacts.reduce((idArr, contact) => {
      if (!_.includes(idArr, contact.organization)) {
        if (typeof contact.organization === "string")
          idArr.push(contact.organization);
      }
      return idArr;
    }, []);
    if (!_.isEqual(orgIds, organizationIds)) {
      setOrganizationIds(orgIds);
    }
  }, [contacts, setOrganizationIds, organizationIds]);

  useEffect(() => {
    if (!organizationIds) return;
    let isCanceled = false;
    (async () => {
      const orgDocs = {};
      const organizationIdBatches = _.chunk(organizationIds, 10);
      for (const idBatch of organizationIdBatches) {
        const orgRef = collection(db, largeCustomerCollectionPath);
        const q = query(orgRef, where(documentId(), "in", idBatch));
        const docs = await getDocs(q);
        docs.forEach(qSnap => {
          orgDocs[qSnap.id] = qSnap.data();
        });
      }
      if (!isCanceled) {
        setOrganizationDocuments(orgDocs);
      }
    })();

    return () => {
      isCanceled = true;
    };
  }, [organizationIds, setOrganizationDocuments]);

  return organizationDocuments;
};

export default useOrganizationNameResolver;
