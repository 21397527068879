import { Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import useCsvImport from "../hooks/useCsvImport";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Papa from "papaparse";
import XLSX from "xlsx";
import { addDoc, collection } from "firebase/firestore";
import { db, largeCustomerTransactionPath } from "../../firebase";
import Loading from "../Loading";
import _ from "lodash";
import moment from "moment";
import { COMPLETED } from "../transaction/TransactionStates";

/**
 * Imports transactions from a CSV or excel file
 * expects no header row
 * @returns
 */
const TransactionImportModal = ({
  visible = false,
  onHide,
  client,
  target,
}) => {
  const [file, setFile] = useState(null);
  const [parsedResult, setParsedResult] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles[0]) {
        setParsedResult(null);
        setFile(acceptedFiles[0]);
      }
    },
    [setFile, setParsedResult],
  );

  const { getRootProps, getInputProps, isDragActive } = useCsvImport(onDrop);

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, { type: "array" });

      wb.SheetNames.forEach(name => {
        const sheet = wb.Sheets[name];
        const csv = XLSX.utils.sheet_to_csv(sheet, { header: 0 });
        const parsed = Papa.parse(csv);

        setParsedResult(
          parsed.data
            .filter(row => {
              if (row.length > 1) {
                return true;
              } else if (row.length === 1 && !_.isEmpty(row[0])) {
                return true;
              }
              return false;
            })
            // remove header row
            .slice(1),
        );
      });
    };

    reader.readAsArrayBuffer(file);
  }, [file]);

  const parseTransaction = useCallback(
    csvRow => {
      const date = moment(csvRow[0], "DD.MM.YYYY").format("YYYY-MM-DD");
      const price = Number(csvRow[1] ?? 0);
      const parsedTransaction = {
        organization: client.key,
        target: target.key,
        offer: {
          createdAt: date,
          endDate: null,
          startDate: null,
          price: price,
          repairedAt: null,
        },
        pictures: {
          classified: [],
          classifiedNotUploaded: [],
          unclassified: [],
        },
        state: COMPLETED,
        updatedAt: moment(date).unix(),
      };
      return parsedTransaction;
    },
    [target, client],
  );

  const createTransactions = csvArray => {
    if (submitting) return;
    setSubmitting(true);
    const transactions = csvArray.reduce((txArr, row) => {
      const tx = parseTransaction(row);
      txArr.push(tx);
      return txArr;
    }, []);
    const txRef = collection(db, largeCustomerTransactionPath);
    try {
      for (const tx of transactions) {
        addDoc(txRef, tx);
      }
    } catch (error) {
      console.warn("Error while creating transactions");
    }
    setSubmitting(false);
    setFile(null);
    setParsedResult(null);
    onHide();
  };

  return (
    <Modal show={visible} onHide={onHide} dialogClassName="modal-90w">
      <Modal.Body>
        <div className="text-center">
          <h4>Tuo kartoituksia kohteelle {target.name}</h4>
        </div>
        <Box m={2} />
        <div
          className={
            isDragActive
              ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
              : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
          }
          {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="input-text">
            Vedä tiedostot tähän tai{" "}
            <span className="link">valitse ne tietokoneestasi</span>
          </p>
        </div>
        <div>
          {parsedResult && (
            <>
              <div className="text-center my-3">
                {!submitting && (
                  <button
                    onClick={() => createTransactions(parsedResult)}
                    className="btn btn-primary">
                    Luo kartoitukset
                  </button>
                )}
                {submitting && <Loading />}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Päivämäärä</TableCell>
                    <TableCell>Hinta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedResult &&
                    parsedResult.map((csvRow, index) => {
                      const tx = parseTransaction(csvRow);
                      return (
                        <TableRow key={"importedTx" + index}>
                          <TableCell>
                            {moment(tx.offer.createdAt).format("DD.MM.YYYY")}
                          </TableCell>
                          <TableCell>{tx.offer.price}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionImportModal;
