import _ from "lodash";

export const latestTransactionDocsByTargetOffer = transactionDocs => {
  const sortedTransactions = _.orderBy(
    transactionDocs,
    item => item.offer.createdAt,
    ["desc"],
  );
  const uniq = _.uniqBy(sortedTransactions, item => item.target);

  return uniq;
};
