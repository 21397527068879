import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import OrganizationSearch from "../OrganizationSearch";
import { Box, TextField } from "@material-ui/core";
import RemoveIcon from "../RemoveIcon";
import Loading from "../Loading";
import NewTargetContact from "./NewTargetContact";

/**
 * Renders a modal which can be used to create a new Target document, without
 * a predefined client.
 * @param {*} isVisible Modal visibility flag
 * @param {*} onHide onHide modal handler
 * @param {*} onSubmit onSubmit new target handler
 * @param {*} submitting Submitting state flag
 * @param {*} submitError Error string which can happen during submit
 * @param {*} target Target data
 * @param {*} setTarget Target data update handler
 * @returns
 */
const NewTargetModal = ({
  isVisible,
  onHide,
  onSubmit,
  submitting,
  submitError,
  target,
  setTarget,
}) => {
  const [errorText, setErrorText] = useState("");

  /**
   * Validates targetData
   * @param {*} targetData
   * @returns
   */
  const isValid = targetData => {
    if (_.isNil(targetData.organization)) {
      setErrorText("Valitse organisaatio!");
      return false;
    } else if (_.isEmpty(targetData.name)) {
      setErrorText("Nimi ei voi olla tyhjä!");
      return false;
    } else if (_.isEmpty(targetData.address)) {
      setErrorText("Osoite ei voi olla tyhjä!");
      return false;
    } else if (_.isNil(target.contact)) {
      setErrorText("Valitse yhteyshenkilö!");
      return false;
    }
    return true;
  };

  const submit = targetData => {
    setErrorText("");
    // construct a target document
    const targetDoc = {
      ...targetData,
      organization: targetData.organization.key,
      contact: targetData.contact.uid,
    };
    // validate
    if (!isValid(targetDoc)) return;
    // submit
    onSubmit(targetDoc);
  };

  return (
    <>
      <Modal onHide={onHide} show={isVisible}>
        <Modal.Body>
          <div className="text-center">
            <h4>Uusi kohde</h4>
          </div>
          {/* organization section, show selector if org is null/undefined */}
          {_.isNil(target.organization) && (
            <OrganizationSearch
              onOrganization={chosenOrg => {
                //console.log("Org chosen", chosenOrg);
                setTarget({ ...target, organization: chosenOrg });
              }}
            />
          )}
          {!_.isNil(target.organization) && (
            <div className="d-flex flex-row align-items-center">
              <TextField
                disabled
                fullWidth={true}
                label="Organisaatio"
                value={target.organization.name}
              />
              <RemoveIcon
                size={30}
                onClick={() =>
                  // also set contact as null, since the user might select a
                  // different organization next time
                  setTarget({ ...target, organization: null, contact: null })
                }
              />
            </div>
          )}
          <Box m={2} />
          {/* Basic info section */}
          <p className="input-title">Yleiset tiedot</p>
          <Box m={1} />
          <TextField
            fullWidth={true}
            label="Nimi"
            placeholder="Nimi"
            onChange={e => setTarget({ ...target, name: e.target.value })}
          />
          <TextField
            fullWidth={true}
            label="Osoite"
            placeholder="Louhimontie 10"
            onChange={e => setTarget({ ...target, address: e.target.value })}
          />
          <TextField
            fullWidth={true}
            label="Postinumero"
            placeholder="90620"
            onChange={e => setTarget({ ...target, postalCode: e.target.value })}
          />
          <TextField
            fullWidth={true}
            label="Postitoimipaikka"
            placeholder="Oulu"
            onChange={e =>
              setTarget({ ...target, postalDistrict: e.target.value })
            }
          />
          <TextField
            fullWidth={true}
            label="Lisätiedot"
            multiline={true}
            rows={3}
            onChange={e =>
              setTarget({ ...target, additionalInfo: e.target.value })
            }
          />
          <Box m={2} />
          {/* contact section */}
          <Box m={2} />
          <p className="input-title">Yhteyshenkilö</p>
          {!_.isNil(target.organization) && _.isNil(target.contact) && (
            <NewTargetContact
              clientKey={target.organization.key}
              onContact={contact => setTarget({ ...target, contact: contact })}
            />
          )}
          {!_.isNil(target.contact) && (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <p className="mb-0">{target.contact.name}</p>
              <RemoveIcon
                size={30}
                onClick={() => setTarget({ ...target, contact: null })}
              />
            </div>
          )}
          <Box m={1} />
          {/* buttons */}
          <Box m={2} />
          <div className="text-center">
            {submitError.length > 0 && (
              <p className="text-danger">{submitError}</p>
            )}
            {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
            {submitting && <Loading />}
            {!submitting && (
              <button
                className="btn btn-primary"
                onClick={() => submit(target)}>
                Tallenna
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewTargetModal;
