import React, { useCallback } from "react";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import ThumbnailRemoveIcon from "../ThumbnailRemoveIcon";

const ReportExtraWork = ({
  extraWork,
  setExtraWork,
  extraWorkFiles,
  setExtraWorkFiles,
  extraWorkImageUrls,
  setExtraWorkImageUrls,
}) => {
  const onDrop = useCallback(
    acceptedFiles => {
      const oldFiles = [...extraWorkFiles];
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setExtraWorkFiles(_.unionBy([...oldFiles, ...newFiles], "name"));
    },
    [extraWorkFiles, setExtraWorkFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const thumbnails = extraWorkFiles.map((file, index) => {
    return (
      <React.Fragment key={file.name}>
        <div className="thumbnail" style={{ marginRight: "5%" }}>
          <div className="thumbnail-inner">
            <img src={file.preview} alt="" />
          </div>
          <ThumbnailRemoveIcon
            onClick={() => {
              const _files = [...extraWorkFiles];
              const thisFile = _files[index];
              if (thisFile.preview) URL.revokeObjectURL(thisFile.preview);
              _files.splice(index, 1);
              setExtraWorkFiles(_files);
            }}
          />
        </div>
      </React.Fragment>
    );
  });

  const uploadedThumbnails = extraWorkImageUrls.map((url, index) => {
    return (
      <React.Fragment key={url.key}>
        <div className="thumbnail" style={{ marginRight: "5%" }}>
          <div className="thumbnail-inner">
            <img src={url.url} alt="" />
          </div>
          <ThumbnailRemoveIcon
            onClick={() => {
              const imgs = [...extraWorkImageUrls];

              const extraPictures = [...extraWork.pictures];
              const foundIndex = extraPictures.findIndex(
                imgKey => imgKey === url.key,
              );
              if (foundIndex >= 0) {
                extraPictures.splice(foundIndex, 1);
                imgs.splice(index, 1);
                setExtraWorkImageUrls(imgs);
                setExtraWork({ ...extraWork, pictures: extraPictures });
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  });

  return (
    <>
      <div className="text-center">
        <h4>Lisätyöt</h4>
      </div>
      <Box m={2} />
      <TextField
        fullWidth={true}
        label="Lisätöiden hinta"
        value={extraWork.price}
        onChange={e => {
          console.log(
            "extra work price change",
            e.target.value,
            typeof e.target.value,
          );
          setExtraWork({ ...extraWork, price: e.target.value });
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextField
        multiline={true}
        label="Lisätöiden tiedot"
        placeholder=""
        rows={3}
        value={extraWork.shortReport}
        fullWidth={true}
        onChange={e => {
          const ew = { ...extraWork };
          ew.shortReport = e.target.value;
          setExtraWork(ew);
        }}
      />
      <Box m={2} />
      <p className="input-title">Kuvat</p>
      <div
        className={
          isDragActive
            ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
            : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
        }
        {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="input-text">
          Vedä tiedostot tähän tai{" "}
          <span className="link">valitse ne tietokoneestasi</span>
        </p>
      </div>
      <Box m={2} />
      {/* new images */}
      {extraWorkFiles.length > 0 && (
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-center">
          {thumbnails}
        </div>
      )}
      {/* already uploaded images */}
      {extraWork.pictures.length > 0 && (
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-center">
          {uploadedThumbnails}
        </div>
      )}
    </>
  );
};

export default ReportExtraWork;
