import { useAppSelector } from "../../store/storeHooks";
import { ADMIN, OFFICE_WORKER, UserRole, WORKER } from "./userRoles";

/**
 * Custom hook which returns booleans based on user roles
 * for "role based access control"
 * @returns
 */
const useUserInfo = () => {
  const { user: userDocument } = useAppSelector(state => state.user);

  const isAdmin = () => {
    return hasRoleAdmin();
  };

  const hasRoleAdmin = () => {
    if (userDocument && userDocument.roles) {
      return userDocument.roles.includes(ADMIN);
    }
    return false;
  };

  const hasRoleWorker = () => {
    if (userDocument && userDocument.roles) {
      return userDocument.roles.includes(WORKER);
    }
    return false;
  };

  const hasRoleOfficeWorker = () => {
    if (userDocument && userDocument.roles) {
      return userDocument.roles.includes(OFFICE_WORKER);
    }
    return false;
  };

  /**
   * Returns true or false depending on if the user
   * should see the asfalttipartio side of the app or not.
   * @returns
   */
  const isAsfalttipartio = () => {
    if (userDocument && userDocument.roles) {
      return [ADMIN, OFFICE_WORKER, WORKER].some(role =>
        userDocument.roles.includes(role as UserRole),
      );
    }
    return false;
  };

  /**
   * Returns if the user is 'worker', which used
   * to be just 'isWorker' on the user document.
   * Returns true if user has the role 'worker' but doesn't have the role(s)
   * 'admin' or 'office-worker'
   * @returns
   */
  const isWorker = () => {
    return hasRoleWorker() && !hasRoleOfficeWorker() && !hasRoleAdmin();
  };

  return { isAdmin, isAsfalttipartio, isWorker };
};

export default useUserInfo;
