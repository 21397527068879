import _ from "lodash";

/**
 * Returns a list of target docs with the highest updatedAt field
 * for each unique target.
 * @param {*} targetDocs
 * @returns
 */
const latestTargetDocs = targetDocs => {
  const sortedTargets = _.orderBy(targetDocs, ["target"], ["asc"]);
  const targetMap = {};
  for (const target of sortedTargets) {
    const addedTarget = targetMap[target.target] ?? {
      target: target,
      updatedAt: target.updatedAt,
    };
    if (target.updatedAt > addedTarget.updatedAt) {
      addedTarget.target = target;
      addedTarget.updatedAt = target.updatedAt;
    }
    targetMap[target.target] = addedTarget;
  }
  return Object.keys(targetMap).reduce((targetArr, targetKey) => {
    targetArr.push(targetMap[targetKey].target);
    return targetArr;
  }, []);
};

export { latestTargetDocs };
