import React from "react";

/**
 * Renders a red X using svg, that doesn't intercept clicks, positioned absolutely
 * and 100% fills the closest relative element.
 * @returns
 */
const RejectedIcon = () => {
  return (
    <svg
      style={{ position: "absolute", color: "red", pointerEvents: "none" }}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="currentColor"
      className="bi bi-x-lg"
      viewBox="0 0 16 16">
      <line
        x1="-1"
        x2="17"
        y1="-1"
        y2="17"
        style={{ stroke: "red", strokeWidth: 0.5 }}
      />
      <line
        x1="17"
        x2="-1"
        y1="-1"
        y2="17"
        style={{ stroke: "red", strokeWidth: 0.5 }}
      />
    </svg>
  );
};

export default RejectedIcon;
