import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from "@material-ui/core";

/**
 * We could use sortConfig to render an indicator indicating the current sort direction
 * @param {*} sortBy Callback to sort the table
 * @param {*} sortConfig Current sort config, indicating which column is sorted and how
 * @param {*} columns Columns of the table, array of Objects.
 * Expects to find at least "label" property in each object
 * @returns
 */
const SortableHeader = ({
  sortBy,
  sortConfig,
  columns,
  checkAll,
  allChecked,
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          if (index === 0) {
            return (
              <TableCell
                padding="checkbox"
                key={index}
                onClick={() => {
                  if (checkAll) {
                    checkAll();
                  }
                }}
                style={{ alignContent: "flex-start" }}>
                <Checkbox checked={allChecked} />
              </TableCell>
            );
          }
          if ("property" in column) {
            return (
              <TableCell key={index} onClick={() => sortBy(column.property)}>
                <Typography>{column.label}</Typography>
              </TableCell>
            );
          } else {
            return (
              <TableCell key={index}>
                <Typography>{column.label}</Typography>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default SortableHeader;
