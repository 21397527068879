import firebaseApp from "./firebaseApp";
import { getFirestore } from "@firebase/firestore";
import {
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
  largeCustomerReportPath,
  userCollectionPath,
} from "./CollectionPaths";

const db = getFirestore(firebaseApp);

export {
  firebaseApp,
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
  largeCustomerReportPath,
  userCollectionPath,
};
