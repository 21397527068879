import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  db,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import { COMPLETED } from "../transaction/TransactionStates";
import _ from "lodash";

/**
 * Returns an object containing open and closed target count mapped
 * to each client
 * @param {*} clients
 * @returns
 */
const useClientTargetCount = clients => {
  const [countMap, setCountMap] = useState({});
  const [clientIds, setClientIds] = useState([]);
  // kind of lazy way to do a refetch on interval,
  // see useEffect hook with setInterval.
  const [refetch, setRefetch] = useState(false);

  /**
   * Extract unique client IDs from all the client documents
   */
  useEffect(() => {
    // exit early if clients are not defined
    if (!clients) return;
    const ids = [];
    clients.forEach(client => ids.push(client.key));
    const idArr = Array.from(new Set(ids));
    if (!_.isEqual(idArr, clientIds)) {
      setClientIds(idArr);
    }
  }, [clients, clientIds]);

  /**
   * This is part of the lazy way to do a refetch every 30 seconds,
   * we just need a state variable that changes, which will be in the dependencies
   * of the useEffect hook that actually does the fetching.
   */
  useEffect(() => {
    const intr = setInterval(() => {
      //console.log("Refetching");
      setRefetch(!refetch);
    }, 30000);
    return () => clearInterval(intr);
  }, [setRefetch, refetch]);

  /**
   * Resolve open/closed target count for each client
   */
  useEffect(() => {
    if (clientIds.length === 0) return;

    let isNotCanceled = true;

    const targetRef = collection(db, largeCustomerTargetPath);

    const idBatches = _.chunk(clientIds, 10);

    let batches = [];
    for (const idBatch of idBatches) {
      const q = query(targetRef, where("organization", "in", idBatch));
      batches.push(
        new Promise(response => {
          getDocs(q).then(results =>
            response(
              results.docs.map(doc => {
                return { key: doc.id, ...doc.data() };
              }),
            ),
          );
        }),
      );
    }

    Promise.all(batches).then(content => {
      const fetchedDocs = content.flat();
      // extract just IDs from target documents
      const targetIds = fetchedDocs.map(targetDoc => {
        return targetDoc.key;
      });

      // batch target IDs
      const tIdBatches = _.chunk(targetIds, 10);

      const txRef = collection(db, largeCustomerTransactionPath);

      // for every organization we want an object
      // where every target is a key, which points to an array
      // where we'll store that targets transactions later.
      // example:
      // {"org1Key": {"org1Target1Key": [], "org1Target2Key": [], ...}, ...}
      const orgTargetMap = {};
      for (const fetchedTarget of fetchedDocs) {
        const orgMap = orgTargetMap[fetchedTarget.organization] ?? {};
        orgMap[fetchedTarget.key] = [];
        orgTargetMap[fetchedTarget.organization] = orgMap;
      }

      let batches = [];
      // create batched promises
      for (const tIdBatch of tIdBatches) {
        const q = query(txRef, where("target", "in", tIdBatch));
        batches.push(
          new Promise(response => {
            getDocs(q).then(results =>
              response(
                results.docs.map(doc => {
                  return { key: doc.id, ...doc.data() };
                }),
              ),
            );
          }),
        );
      }
      Promise.all(batches).then(content => {
        const fetchedTxs = content.flat();
        const chunksByClientAndTarget = fetchedTxs.reduce((chunks, txObj) => {
          let orgObj = chunks[txObj.organization] ?? {};
          let arr = orgObj[txObj.target] ?? [];
          arr.push(txObj);
          arr = _.orderBy(arr, "target", "asc");
          orgObj[txObj.target] = arr;
          chunks[txObj.organization] = orgObj;
          return chunks;
        }, orgTargetMap);

        const defaultCounts = {};
        for (const clientId of clientIds) {
          defaultCounts[clientId] = { open: 0, closed: 0 };
        }

        const clientTargetCounts = Object.keys(chunksByClientAndTarget).reduce(
          (counts, clientId) => {
            // obj of target keys
            const clientObj = chunksByClientAndTarget[clientId];
            for (const targetId of Object.keys(clientObj)) {
              const countObj = counts[clientId] ?? { open: 0, closed: 0 };
              const txArr = clientObj[targetId];
              let hasOpenTx = false;
              // treat 0 TXs as open
              if (txArr.length === 0) {
                countObj.open = countObj.open + 1;
                continue;
              }
              for (const tx of txArr) {
                if (tx.state < COMPLETED) {
                  hasOpenTx = true;
                  break;
                }
              }
              if (hasOpenTx) {
                countObj.open = countObj.open + 1;
              } else {
                countObj.closed = countObj.closed + 1;
              }
              counts[clientId] = countObj;
            }

            return counts;
          },
          defaultCounts,
        );
        if (isNotCanceled) {
          setCountMap(clientTargetCounts);
        }
      });
    });

    return () => {
      isNotCanceled = false;
    };
  }, [clientIds, refetch]);

  return { countMap };
};

export default useClientTargetCount;
