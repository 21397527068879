import { Box } from "@material-ui/core";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClientSalesTotal from "../../components/frontpage/ClientSalesTotal";
import SalesPerMonth from "../../components/frontpage/SalesPerMonth";
import TotalStats from "../../components/frontpage/TotalStats";
import Loading from "../../components/Loading";
import useUserInfo from "../../components/user/useUserInfo";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import {
  setClients,
  markClientsFetched,
  setTargets,
  markTargetsFetched,
  setTransactions,
  markTransactionsFetched,
} from "./frontpageSlice";
import { latestTransactionDocsByTargetOffer } from "../../components/util/transactionFunctions";

const Frontpage = () => {
  const dispatch = useDispatch();
  const {
    transactionsFetched,
    targetsFetched,
    clientsFetched,
    clients,
    targets,
    transactions,
  } = useSelector(state => state.frontpage);

  const { isAdmin } = useUserInfo();

  /**
   * Fetches all transactions for the current year
   */
  useEffect(() => {
    // exit early if transactions are fetched
    if (transactionsFetched === true) return;

    if (!isAdmin()) return;
    const startOfYear = moment().startOf("year");

    const txRef = collection(db, largeCustomerTransactionPath);
    const q = query(
      txRef,
      where("offer.createdAt", ">=", startOfYear.format("YYYY-MM-DD")),
    );
    getDocs(q)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const txDocs = [];
          querySnapshot.docs.forEach(doc => {
            txDocs.push({ key: doc.id, ...doc.data() });
          });
          const latestDocs = latestTransactionDocsByTargetOffer(txDocs);
          dispatch(setTransactions(latestDocs));
          dispatch(markTransactionsFetched(true));
        } else {
          dispatch(setTransactions([]));
          dispatch(markTransactionsFetched(true));
        }
      })
      .catch(err => {
        console.log("Error while fetching all transactions", err);
      });
  }, [dispatch, transactionsFetched, isAdmin]);

  /**
   * Fetches all targets from firebase
   */
  useEffect(() => {
    if (targetsFetched === true) return;
    if (!isAdmin()) return;

    const targetRef = collection(db, largeCustomerTargetPath);
    getDocs(targetRef)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const targetDocs = [];
          querySnapshot.docs.forEach(doc => {
            targetDocs.push({ key: doc.id, ...doc.data() });
          });
          dispatch(setTargets(targetDocs));
          dispatch(markTargetsFetched(true));
        }
      })
      .catch(err => {
        console.log("Error while fetching all targets", err);
      });
  }, [dispatch, targetsFetched, isAdmin]);

  /**
   * Fetches all clients from firebase
   */
  useEffect(() => {
    if (clientsFetched === true) return;
    if (!isAdmin()) return;
    const clientRef = collection(db, largeCustomerCollectionPath);
    getDocs(clientRef).then(querySnapshot => {
      if (!querySnapshot.empty) {
        const clientDocs = [];
        querySnapshot.docs.forEach(doc => {
          clientDocs.push({ key: doc.id, ...doc.data() });
        });
        dispatch(setClients(clientDocs));
        dispatch(markClientsFetched(true));
      }
    });
  }, [dispatch, clientsFetched, isAdmin]);
  // return error if user is not an admin
  if (!isAdmin()) {
    return (
      <>
        <Box m={5} />
        <p>Käyttöoikeudet eivät riitä etusivu datan näkemiseen</p>
      </>
    );
  }

  // display loading indicator while fetching necessary data
  if (!clientsFetched || !targetsFetched || !transactionsFetched) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Box m={5} />
      <div className="row frontpage">
        <div className="col-md-12 col-lg-8">
          <SalesPerMonth transactions={transactions} />
          <Box m={2} />
          <ClientSalesTotal
            transactions={transactions}
            targets={targets}
            clients={clients}
          />
          <Box m={2} />
        </div>

        <div className="col-md-12 col-lg-4">
          <TotalStats targets={targets} transactions={transactions} />
        </div>
      </div>
      <Box m={5} />
    </>
  );
};

export default Frontpage;
