import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
} from "@material-ui/core";
import Loading from "../Loading";
import SortableHeader from "./SortableHeader";
import { useHistory } from "react-router-dom";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";

const columns = [
  { label: "" },
  { label: "Asiakas", property: "name" },
  { label: "Osoite", property: "address" },
  { label: "Avoimia kohteita", property: "openCount" },
  { label: "Valmiita kohteita", property: "closedCount" },
  { label: "" },
];

/**
 * Renders a Table component of sortable LargeCustomers
 * @param {} clients array of LargeCustomers
 * @param {} targets array of Target documents
 * @returns
 */
const ClientTable = ({
  clients,
  targets,
  countsFetched,
  checkedItems,
  setCheckedItems,
}) => {
  const [tableItems, setTableItems] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  /**
   * Creates custom client items for the table, combines address into a single line
   */
  useEffect(() => {
    const items = [];
    for (const client of clients) {
      items.push({
        name: client.name,
        address:
          client.address +
          ", " +
          client.postalCode +
          " " +
          client.postalDistrict,
        key: client.key,
        // include original data as well
        client: client,
        openCount: client.openCount,
        closedCount: client.closedCount,
      });
    }
    setTableItems(items);
  }, [clients, targets]);

  const onRowClick = (item, index) => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.key === item.key,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  const history = useHistory();

  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Ascending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        // flip sorting type
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, tableItems);

  const renderOpenCount = client => {
    if (!countsFetched) {
      return <Loading />;
    }
    const openCount = client.openCount;
    if (openCount !== null) {
      return openCount;
    }
    return "-";
  };

  const renderClosedCount = client => {
    if (!countsFetched) {
      return <Loading />;
    }
    const closedCount = client.closedCount;
    if (closedCount !== null) {
      return closedCount;
    }
    return "-";
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  if (!tableItems) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }
  // this could cause "problems", what if the data set
  // is actually empty?
  else if (sortedRows.length === 0) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );

              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {
              return (
                <TableRow
                  key={index}
                  hover
                  onClick={event => {
                    // if the user is clicking on the "Open" button, don't trigger this onClick
                    if (event.nativeEvent.target.id === "clientOpenButton") {
                      return;
                    }
                    onRowClick(item, index);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(
                          cItem => cItem.key === item.key,
                        ) > -1
                      }
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.address}</TableCell>
                  {/* open count */}
                  <TableCell>{renderOpenCount(item)}</TableCell>
                  {/* closed count */}
                  <TableCell>{renderClosedCount(item)}</TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: "/asiakas",
                        search: "?id=" + item.key,
                        client: item.client,
                      });
                    }}>
                    <button id="clientOpenButton" className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default ClientTable;
