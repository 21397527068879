import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  db,
  largeCustomerReportPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
  userCollectionPath,
} from "../../../firebase";

export const deleteClientTargets = async (clientKey, withContacts = true) => {
  const q = query(
    collection(db, largeCustomerTargetPath),
    where("organization", "==", clientKey),
  );

  const docs = await getDocs(q);
  const docKeys = [];
  const targetContactKeys = [];
  docs.forEach(doc => {
    const data = doc.data();
    if (data.contact) targetContactKeys.push(data.contact);
    docKeys.push(doc.id);
  });
  for await (const key of docKeys) {
    const docRef = doc(db, largeCustomerTargetPath, key);
    await deleteDoc(docRef);
  }
  if (withContacts) {
    for await (const key of docKeys) {
      const contactRef = doc(db, userCollectionPath, key);
      await deleteDoc(contactRef);
    }
  }
};

export const deleteClientTransactions = async clientKey => {
  const q = query(
    collection(db, largeCustomerTransactionPath),
    where("organization", "==", clientKey),
  );
  const docs = await getDocs(q);
  const docKeys = [];
  docs.forEach(doc => {
    docKeys.push(doc.id);
  });
  for await (const key of docKeys) {
    const docRef = doc(db, largeCustomerTransactionPath, key);
    await deleteDoc(docRef);
  }
};

export const deleteClientReports = async clientKey => {
  const q = query(
    collection(db, largeCustomerReportPath),
    where("organization", "==", clientKey),
  );
  const docs = await getDocs(q);
  const docKeys = [];
  docs.forEach(doc => {
    docKeys.push(doc.id);
  });
  for await (const key of docKeys) {
    const docRef = doc(db, largeCustomerReportPath, key);
    await deleteDoc(docRef);
  }
};

export const deleteClientContacts = async contactKeys => {
  for await (const key of contactKeys) {
    const docRef = doc(db, userCollectionPath, key);
    await deleteDoc(docRef);
  }
};
