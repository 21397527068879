import { TextField, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EmailInput from "./EmailInput";
import _ from "lodash";
import NumberInput from "./NumberInput";
import AddButton from "../AddButton";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Renders a form for a new contact, without the ability to select an organization.
 * This will be used when creating a new organization.
 * @param {*} onNewContact submit handler
 * @param {*} onCancel cancel handler
 * @param {*} contact lifted contact state
 * @param {*} setContact lifted contact state
 * @returns
 */
const NewContact = ({ onNewContact, onCancel, contact, setContact }) => {
  const [errorText, setErrorText] = useState("");
  const [emailErrorIndices, setEmailErrorIndices] = useState([]);
  const [numberErrorIndices, setNumberErrorIndices] = useState([]);

  /**
   * Clear error indices when unmounting
   */
  useEffect(() => {
    return () => {
      setEmailErrorIndices([]);
      setNumberErrorIndices([]);
    };
  }, [setEmailErrorIndices, setNumberErrorIndices]);

  const onSubmit = contactData => {
    setErrorText("");
    if (isValid(contactData)) {
      onNewContact(contactData);
    }
  };

  const isValid = contactData => {
    if (_.isEmpty(contactData.name)) {
      setErrorText("Nimi ei voi olla tyhjä!");
      return false;
    }
    // this case shouldn't happen, but just in case
    else if (_.isEmpty(contact.emails)) {
      setErrorText("Yksi sähköposti on pakollinen");
      return false;
    }
    // validate emails
    const emails = [...contactData.emails];
    for (const emailObject of emails) {
      if (_.isEmpty(emailObject.email)) {
        setErrorText("Sähköposti ei voi olla tyhjä");
        return false;
      } else if (!emailRegex.test(emailObject.email)) {
        setErrorText("Tarkista sähköpostit");
        return false;
      }
    }
    // validate phone numebrs
    const numbers = [...contactData.numbers];
    for (const numberObject of numbers) {
      if (!_.startsWith(numberObject.number, "+")) {
        setErrorText("Puhelinnumerot tulee olla muodossa +358123123");
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <div className="text-center">
        <h4>Uusi yhteyshenkilö</h4>
      </div>
      <Box m={2} />
      <div className="d-flex flex-column">
        {/* name section */}
        <TextField
          fullWidth={true}
          label="Nimi"
          placeholder="Matti Meikäläinen"
          value={contact.name}
          onChange={e => setContact({ ...contact, name: e.target.value })}
        />
        {/* email section */}
        <Box m={2} />
        <p className="input-title">Sähköpostit</p>

        {contact.emails.map((emailObject, index) => {
          return (
            <EmailInput
              error={_.includes(emailErrorIndices, index)}
              emailObject={emailObject}
              index={index}
              key={"email" + index}
              onRemove={() => {
                const emails = [...contact.emails];
                emails.splice(index, 1);
                setContact({ ...contact, emails });
              }}
              setEmailObject={newEmailObject => {
                const emails = [...contact.emails];
                emails[index] = newEmailObject;
                // validate email with regex
                if (!_.isEmpty(newEmailObject.email)) {
                  const valid = emailRegex.test(newEmailObject.email);
                  if (valid) {
                    setEmailErrorIndices(_.filter(emailErrorIndices, index));
                  } else {
                    if (!_.includes(emailErrorIndices, index)) {
                      setEmailErrorIndices([...emailErrorIndices, index]);
                    }
                  }
                }
                setContact({ ...contact, emails });
              }}
            />
          );
        })}
        <Box m={1} />
        <div className="d-flex">
          <AddButton
            onClick={() => {
              const emails = [...contact.emails];
              emails.push({ email: "", switchState: false, label: "Työ" });
              setContact({ ...contact, emails });
            }}>
            Lisää sähköposti
          </AddButton>
        </div>
        {/* phone section */}
        <Box m={1} />
        {contact.numbers.map((numberObject, index) => {
          return (
            <NumberInput
              numberObject={numberObject}
              key={"number" + index}
              onRemove={() => {
                const numbers = [...contact.numbers];
                numbers.splice(index, 1);
                setContact({ ...contact, numbers });
              }}
              error={_.includes(numberErrorIndices, index)}
              setNumberObject={newNumberObject => {
                const numbers = [...contact.numbers];
                numbers[index] = newNumberObject;
                const valid = _.startsWith(newNumberObject.number, "+");
                if (valid) {
                  setNumberErrorIndices(_.filter(numberErrorIndices, index));
                } else {
                  if (!_.includes(numberErrorIndices, index)) {
                    setNumberErrorIndices([...numberErrorIndices, index]);
                  }
                }
                setContact({ ...contact, numbers });
              }}
            />
          );
        })}
        <Box m={1} />
        <div className="d-flex">
          <AddButton
            onClick={() => {
              const numbers = [...contact.numbers];
              numbers.push({
                number: "",
                switchState: false,
                label: "Työ",
              });
              setContact({ ...contact, numbers });
            }}>
            Lisää puhelinnumero
          </AddButton>
        </div>
        {/* save section */}
        <Box m={2} />
        {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
        <div className="d-flex flex-row justify-content-center">
          <button onClick={() => onSubmit(contact)} className="btn btn-primary">
            Tallenna
          </button>
          <Box m={2} />
          <button
            onClick={() => {
              setContact({
                name: "",
                emails: [{ email: "", switchState: false, label: "Työ" }],
                numbers: [],
              });
              onCancel();
            }}
            className="btn btn-transparent">
            Peruuta
          </button>
        </div>
      </div>
    </>
  );
};

export default NewContact;
