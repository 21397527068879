export const txCollectionPath = "transactions";
export const offerCollectionPath = "offers";
export const workReportCollectionPath = "workreports";
export const reviewCollectionPath = "reviews";
export const userCollectionPath = "users";
export const notificationCollectionPath = "notifications";
// append -test to the collection paths below
// to access test environment
const useTestPaths = false;
export const largeCustomerCollectionPath = useTestPaths
  ? "largeCustomers-test"
  : "largeCustomers";
export const largeCustomerTargetPath = useTestPaths
  ? "largeCustomerTargets-test"
  : "largeCustomerTargets";
export const largeCustomerTransactionPath = useTestPaths
  ? "largeCustomerTransactions-test"
  : "largeCustomerTransactions";
export const largeCustomerReportPath = useTestPaths
  ? "largeCustomerReports-test"
  : "largeCustomerReports";
