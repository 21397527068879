import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Box, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { doc, setDoc } from "@firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";
import { setClient } from "../../route/admin/largeCustomerSlice";
import RemoveIcon from "../RemoveIcon";
import NewExistingContact from "./NewExistingContact";
import AddButton from "../AddButton";

/**
 * Renders a modal where the user can update clients (largeCustomers) information.
 * Editing contacts information happens with another modal.
 * @param {*} param0
 * @returns
 */
const ClientUpdateModal = ({ visible = false, onHide, contacts }) => {
  const { client } = useSelector(state => state.largeCustomer);
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // flag to indicate if the user is adding a new contact
  const [addingNewContact, setAddingNewContact] = useState(false);

  // internally keep track of contacts, which will be used
  // to set the new contacts array when onSave is called
  const [updatedContacts, setUpdatedContacts] = useState([]);

  /**
   * Sets contacts to the internal state
   */
  useEffect(() => {
    setUpdatedContacts(contacts);
  }, [contacts, setUpdatedContacts]);

  /**
   * Clears error text when modal is hidden,
   * and sets addingNewContact state to false
   */
  useEffect(() => {
    if (visible === false) {
      setErrorText("");
      setAddingNewContact(false);
    }
  }, [visible]);

  /**
   * onSave handler, updates this clients document in firebase
   * @returns
   */
  const onSave = () => {
    setErrorText("");
    if (submitting) return;
    setSubmitting(true);
    const fields = [
      "address",
      "billing",
      "contacts",
      "key",
      "name",
      "postalCode",
      "postalDistrict",
      "y_id",
      "log",
    ];
    // if there's any extra fields created for sorting in the table
    // we will filter them out here
    const filteredClient = _.pick(client, fields);

    const newContacts = updatedContacts.reduce((contactArr, contact) => {
      if (contact.uid) contactArr.push(contact.uid);
      return contactArr;
    }, []);
    filteredClient.contacts = newContacts;

    const clientRef = doc(db, largeCustomerCollectionPath, filteredClient.key);
    setDoc(clientRef, filteredClient)
      .then(() => {
        setSubmitting(false);
        onHide();
      })
      .catch(err => {
        setSubmitting(false);
        setErrorText("Virhe päivittäessä asiakasta");
        console.log("Error while updating client", err);
      });
  };
  const updateField = (value, field) => {
    const newClient = { ...client };
    newClient[field] = value;
    dispatch(setClient(newClient));
  };

  const updateBillingField = (value, field) => {
    const newClient = { ...client };
    const billing = { ...newClient.billing };
    billing[field] = value;
    newClient.billing = billing;
    dispatch(setClient(newClient));
  };

  if (!client) return null;

  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <div className="client-update-modal">
          <div className="text-center">
            <h4>Muokkaa asiakkaan tietoja</h4>
          </div>
          <Box m={2} />
          <p className="input-title">Yleiset tiedot</p>
          <Box m={1} />
          <Box paddingLeft={1}>
            <TextField
              fullWidth={true}
              label="Nimi"
              value={client.name}
              onChange={e => {
                updateField(e.target.value, "name");
              }}
            />
            <TextField
              fullWidth={true}
              label="Osoite"
              value={client.address}
              onChange={e => {
                updateField(e.target.value, "address");
              }}
            />
            <TextField
              fullWidth={true}
              label="Postinumero"
              value={client.postalCode}
              onChange={e => {
                updateField(e.target.value, "postalCode");
              }}
            />
            <TextField
              fullWidth={true}
              label="Postitoimipaikka"
              value={client.postalDistrict}
              onChange={e => {
                updateField(e.target.value, "postalDistrict");
              }}
            />
            <TextField
              fullWidth={true}
              label="Y-tunnus"
              value={client.y_id}
              onChange={e => updateField(e.target.value, "y_id")}
            />
          </Box>
          <Box m={2} />
          <p className="input-title">Laskutustiedot</p>
          <Box paddingLeft={1}>
            {/* billing information can be undefined */}
            <TextField
              fullWidth={true}
              label="Laskutusosoite"
              value={client.billing?.billingAddress ?? ""}
              onChange={e => {
                updateBillingField(e.target.value, "billingAddress");
              }}
            />
            <TextField
              fullWidth={true}
              label="E-lasku osoite"
              value={client.billing?.eBilling ?? ""}
              onChange={e => {
                updateBillingField(e.target.value, "eBilling");
              }}
            />
            <TextField
              fullWidth={true}
              label="E-lasku tarjoaja"
              value={client.billing?.eBillingProvider ?? ""}
              onChange={e => {
                updateBillingField(e.target.value, "eBillingProvider");
              }}
            />
          </Box>
          <Box m={2} />
          <p className="input-title">Yhteyshenkilöt</p>
          <Box paddingLeft={1}>
            {updatedContacts &&
              updatedContacts.map((contact, index) => {
                return (
                  <div
                    className="d-flex flex-row contact-row align-items-center justify-content-between"
                    key={"contact" + index}>
                    <p className="mb-0">
                      {contact?.name ?? "Poistettu yhteyshenkilö"}
                    </p>
                    <div>
                      <RemoveIcon
                        size={30}
                        onClick={() => {
                          if (updatedContacts.length > 1) {
                            const newContacts = [...updatedContacts];
                            newContacts.splice(index, 1);
                            setUpdatedContacts(newContacts);
                          } else {
                            setErrorText(
                              "Kaikkia yhteyshenkilöitä ei voi poistaa!",
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            <NewExistingContact
              visible={addingNewContact}
              onSelectContact={contact => {
                const newContacts = [...updatedContacts];
                newContacts.push(contact);
                setUpdatedContacts(newContacts);
                setAddingNewContact(false);
              }}
            />
            {!addingNewContact && (
              <AddButton onClick={() => setAddingNewContact(true)}>
                Lisää uusi
              </AddButton>
            )}
          </Box>
          <Box m={2} />
          {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
          <div className="text-center">
            <button className="btn btn-primary" onClick={onSave}>
              TALLENNA
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClientUpdateModal;
