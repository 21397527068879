import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LargeCustomerTransaction,
  TransactionOffer,
} from "../../firebase/document-types";

interface TransactionState {
  transactions: LargeCustomerTransaction[];
  transaction: LargeCustomerTransaction | null;
}

const initialState: TransactionState = {
  transactions: [],
  transaction: null,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactions: (
      state,
      action: PayloadAction<LargeCustomerTransaction[]>,
    ) => {
      state.transactions = action.payload;
    },
    setTransaction: (
      state,
      action: PayloadAction<LargeCustomerTransaction>,
    ) => {
      state.transaction = action.payload;
    },
    updateOffer: (state, action: PayloadAction<TransactionOffer>) => {
      if (state.transaction) {
        state.transaction.offer = action.payload;
      } else {
        console.error(
          "Tried to update offer, but transaction wasn't set in state!",
        );
      }
    },
    removeTransaction: state => {
      state.transaction = null;
    },
    removeTransactions: state => {
      state.transactions = [];
    },
  },
});

export const {
  setTransaction,
  setTransactions,
  removeTransaction,
  removeTransactions,
  updateOffer,
} = transactionSlice.actions;

export default transactionSlice.reducer;
