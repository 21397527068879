import { useDropzone } from "react-dropzone";

const useCsvImport = onDrop => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return { getRootProps, getInputProps, isDragActive };
};

export default useCsvImport;
