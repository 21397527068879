import React, { useCallback, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
} from "@material-ui/core";
import SortableHeader from "../largeCustomers/SortableHeader";
import { useHistory } from "react-router-dom";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import { stateTranslations } from "../transaction/TransactionStates";
import Loading from "../Loading";
import { useDispatch } from "react-redux";
import { setTarget } from "../../route/admin/targetSlice";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";

const columns = [
  { label: "" },
  { label: "Kohde", property: "name" },
  { label: "Osoite", property: "wholeAddress" },
  { label: "Viimeisin muokkaaja", property: "logUser" },
  { label: "Status", property: "latestState" },
  { label: "Muokattu", property: "logTime" },
];

/**
 * Renders a Table component of sortable Target documents
 * @param {*} targets array of Target documents
 * @returns
 */
const TargetTable = ({
  targets,
  statesFetched,
  checkedItems,
  setCheckedItems,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Ascending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, targets);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const onRowClick = (item, index) => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.key === item.key,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  const renderState = target => {
    if (!statesFetched) {
      return <Loading />;
    }
    if (target.latestState) {
      const translation = stateTranslations[target.latestState];
      if (translation) {
        return translation;
      }
    }
    return "-";
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  if (!targets) {
    return null;
  } else if (targets.length === 0) {
    return null;
  }

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );
              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((target, index) => {
              return (
                <TableRow
                  key={"target" + index}
                  hover
                  onClick={event => {
                    // if the user is clicking on the "Open" button, don't trigger this onClick
                    if (event.nativeEvent.target.id === "targetOpenButton") {
                      return;
                    }

                    onRowClick(target, index);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(
                          item => item.key === target.key,
                        ) > -1
                      }
                    />
                  </TableCell>
                  <TableCell>{target.name}</TableCell>
                  <TableCell>{target.wholeAddress}</TableCell>
                  {/* last editor */}
                  <TableCell>{target.logUser}</TableCell>
                  {/* state/status */}
                  <TableCell>{renderState(target)}</TableCell>
                  {/* last edited at */}
                  <TableCell>{target.logTime}</TableCell>
                  <TableCell
                    onClick={() => {
                      dispatch(setTarget(target));
                      history.push({
                        pathname: "/kohde",
                        search: "?id=" + target.key,
                        target: target,
                      });
                    }}>
                    <button id="targetOpenButton" className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default TargetTable;
