import { Table, TableRow, TableCell, TableBody, Box } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";

import { COMPLETED } from "../transaction/TransactionStates";
import _ from "lodash";
import useSortedRows from "../sort/useSortedRows";
import { SortingType } from "../sort/SortingTypes";
import FrontpageSortableHeader from "../largeCustomers/FrontpageSortableHeader";

const columns = [
  { label: "NIMI", property: "name" },
  { label: "KOHTEITA", property: "targetCount" },
  { label: "TARJOUKSET KPL", property: "offerCount" },
  { label: "TARJOUKSET €", property: "offerSum" },
  { label: "MYYNTI €", property: "soldSum" },
];

const ClientSalesTotal = ({ clients, targets, transactions }) => {
  const [tableRows, setTableRows] = useState([]);

  const findTargets = useCallback(
    clientKey => {
      return targets.reduce((targetArr, target) => {
        if (target.organization === clientKey) {
          targetArr.push(target);
        }
        return targetArr;
      }, []);
    },
    [targets],
  );

  const findTransactions = useCallback(
    clientKey => {
      return transactions.reduce((txArr, tx) => {
        if (tx.organization === clientKey) {
          txArr.push(tx);
        }
        return txArr;
      }, []);
    },
    [transactions],
  );

  useEffect(() => {
    const rows = [];
    for (const client of clients) {
      const _targets = findTargets(client.key);
      const _transactions = findTransactions(client.key);
      const { count, soldSum, offerSum } = _transactions.reduce(
        (obj, tx) => {
          if (_.isNil(tx.offer)) return obj;
          if (tx.state >= COMPLETED) {
            obj.soldSum += tx.offer.price;
          } else {
            obj.offerSum += tx.offer.price;
          }
          obj.count += 1;
          return obj;
        },
        { count: 0, soldSum: 0, offerSum: 0 },
      );

      const tableRow = {
        name: client.name,
        targetCount: _targets.length,
        offerCount: count,
        offerSum: offerSum,
        soldSum: soldSum,
      };
      rows.push(tableRow);
    }
    setTableRows(rows);
  }, [clients, targets, transactions, findTargets, findTransactions]);

  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Descending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        // flip sorting type
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, tableRows);

  return (
    <>
      <div className="stats-container">
        <h4>Asiakkaat</h4>
        <Box m={2} />

        <Table>
          <FrontpageSortableHeader
            columns={columns}
            sortBy={sortBy}
            sortConfig={sortConfig}
          />
          <TableBody>
            {sortedRows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.targetCount}</TableCell>
                  <TableCell>{row.offerCount}</TableCell>
                  <TableCell>{row.offerSum}</TableCell>
                  <TableCell>{row.soldSum}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ClientSalesTotal;
