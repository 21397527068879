import { useEffect, useState } from "react";
import _ from "lodash";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  db,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import { COMPLETED } from "../transaction/TransactionStates";

/**
 * Returns an object containing open and closed target count
 * mapped to an contacts ID.
 * @param {*} contacts
 * @returns
 */
const useContactTargetCount = contacts => {
  const [contactIds, setContactIds] = useState();
  const [contactCountMap, setContactCountMap] = useState();

  /**
   * Extract unique contact IDs from contacts array
   */
  useEffect(() => {
    if (!contacts) return;
    const uniqueContactIds = [];
    for (const contact of contacts) {
      if (!_.includes(uniqueContactIds, contact.uid)) {
        uniqueContactIds.push(contact.uid);
      }
    }
    if (!_.isEqual(contactIds, uniqueContactIds)) {
      setContactIds(uniqueContactIds);
    }
  }, [contacts, contactIds]);

  /**
   * Resolves open and closed count for each contact
   */
  useEffect(() => {
    if (!contactIds) return;
    if (contactIds.length === 0) return;
    let isNotCanceled = true;

    const targetRef = collection(db, largeCustomerTargetPath);

    const idBatches = _.chunk(contactIds, 10);

    let batches = [];
    for (const idBatch of idBatches) {
      const q = query(targetRef, where("contact", "in", idBatch));
      batches.push(
        new Promise(response => {
          getDocs(q).then(results =>
            response(
              results.docs.map(doc => {
                return { key: doc.id, ...doc.data() };
              }),
            ),
          );
        }),
      );
    }

    Promise.all(batches).then(content => {
      const fetchedDocs = content.flat();

      // extract just IDs from target documents
      const targetIds = fetchedDocs.map(targetDoc => {
        return targetDoc.key;
      });

      const targetContactMap = fetchedDocs.reduce((map, targetDoc) => {
        map[targetDoc.key] = targetDoc.contact;
        return map;
      }, {});

      // batch target IDs
      const tIdBatches = _.chunk(targetIds, 10);

      const txRef = collection(db, largeCustomerTransactionPath);

      let batches = [];
      // create batched promises
      for (const tIdBatch of tIdBatches) {
        const q = query(txRef, where("target", "in", tIdBatch));
        batches.push(
          new Promise(response => {
            getDocs(q).then(results =>
              response(
                results.docs.map(doc => {
                  return { key: doc.id, ...doc.data() };
                }),
              ),
            );
          }),
        );
      }

      Promise.all(batches).then(content => {
        const fetchedTxs = content.flat();

        const chunksByContactAndTarget = fetchedTxs.reduce((chunks, txObj) => {
          const contact = targetContactMap[txObj.target];
          let contactObj = chunks[contact] ?? {};
          let arr = contactObj[txObj.target] ?? [];
          arr.push(txObj);
          arr = _.orderBy(arr, "target", "asc");
          contactObj[txObj.target] = arr;
          chunks[contact] = contactObj;
          return chunks;
        }, {});

        // some contact IDs might not have any targets available,
        // in which case they'd be left out of the counts object,
        // and the table would interpert it as still loading the data.
        const defaultCounts = {};
        for (const contactId of contactIds) {
          defaultCounts[contactId] = { open: 0, closed: 0 };
        }

        const contactTargetCounts = Object.keys(
          chunksByContactAndTarget,
        ).reduce((counts, contactId) => {
          const contactObj = chunksByContactAndTarget[contactId];
          const countObj = counts[contactId] ?? { open: 0, closed: 0 };
          for (const targetId of Object.keys(contactObj)) {
            const txArr = contactObj[targetId];
            let hasOpenTx = false;
            for (const tx of txArr) {
              if (tx.state < COMPLETED) {
                hasOpenTx = true;
                break;
              }
            }
            if (hasOpenTx) {
              countObj.open = countObj.open + 1;
            } else {
              countObj.closed = countObj.closed + 1;
            }
            counts[contactId] = countObj;
          }
          return counts;
        }, defaultCounts);

        if (isNotCanceled) {
          setContactCountMap(contactTargetCounts);
        }
      });
    });

    return () => {
      isNotCanceled = false;
    };
  }, [contactIds]);

  return contactCountMap;
};

export default useContactTargetCount;
