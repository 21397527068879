import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TargetInfo from "../target/TargetInfo";
import Loading from "../Loading";
import {
  doc,
  onSnapshot,
  collection,
  query,
  getDocs,
  limit,
  where,
  updateDoc,
} from "@firebase/firestore";
import { getStorage, ref, uploadBytes } from "@firebase/storage";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerReportPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
} from "../../firebase";
import { setClient } from "../../route/admin/largeCustomerSlice";
import { setTarget } from "../../route/admin/targetSlice";
import { setTransaction } from "../../route/admin/transactionSlice";
import qs from "qs";
import TransactionInfo from "./TransactionInfo";
import { Box } from "@material-ui/core";
import { nextImageIndex } from "../image/imgUtil";
import { useHistory } from "react-router-dom";
import {
  ACCEPTED,
  COMPLETED,
  REPORTED,
  REPORT_CREATED,
  WAITING,
} from "./TransactionStates";
import _ from "lodash";
import {
  calculateAcceptedPictureTotalSize,
  calculateAutoprice,
  createReportPdf,
  sendPdfEmail,
  sendReportPdfEmail,
} from "./transactionUtil";
import { setDoc, deleteDoc } from "firebase/firestore";
import { createPdf } from "./transactionUtil";
import { pdf } from "@react-pdf/renderer";
import ReportCreateModal from "../report/ReportCreateModal";
import ReportModal from "../report/ReportModal";
import { tabLabels } from "../image/imageClassifications";
import {
  appendLog,
  currentUserLogInfo,
  logEvents,
  updateTargetLogByTransaction,
} from "../logging/logUtils";
import TransactionDeleteModal from "./TransactionDeleteModal";

const TAG = "[TransactionDetails]";

const TransactionDetails = ({ location }) => {
  const { client } = useSelector(state => state.largeCustomer);
  const { target } = useSelector(state => state.target);
  const { transaction } = useSelector(state => state.transaction);
  const dispatch = useDispatch();
  // lifted states from TransactionInfo
  const [classifiedNotUploaded, setClassifiedNotUploaded] = useState([]);
  const [contacts, setContacts] = useState([]);

  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [submittingPdf, setSubmittingPdf] = useState(false);
  const [pdfError, setPdfError] = useState("");
  const [submittingReportPdf, setSubmittingReportPdf] = useState(false);
  const [reportPdfError, setReportPdfError] = useState("");

  const [reportCreateShown, setReportCreateShown] = useState(false);
  const [reportViewShown, setReportViewShown] = useState(false);

  const [reportDoc, setReportDoc] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /**
   * Sets transaction to the state, uses either the tx found in
   * router location parameters, or loads it from firebase
   * by parsing the ID from URL query parameters
   */
  useEffect(() => {
    let txUnSub;
    const transaction = location?.transaction;
    if (transaction) {
      dispatch(setTransaction(transaction));
    } else {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const id = queryParams.id;
      if (!id) {
        console.warn("Transaction ID was not defined!");
        return;
      }
      txUnSub = onSnapshot(
        doc(db, largeCustomerTransactionPath, id),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          dispatch(setTransaction({ key: key, ...data }));
        },
        error => {
          console.warn(TAG, "Error while fetching transaction data", error);
        },
      );
    }
    return () => {
      if (txUnSub) {
        txUnSub();
      }
      dispatch(setTransaction(null));
    };
  }, [dispatch, location]);

  useEffect(() => {
    (async () => {
      //console.log("fetching report doc");
      const repDoc = await getReportDoc();
      if (!_.isEqual(repDoc, reportDoc)) {
        setReportDoc(await getReportDoc());
      }
    })();
  }, [transaction]);

  /**
   * Fetches this transactions client if not defined
   */
  useEffect(() => {
    // exit early if client is already defined
    if (client) return;
    // exit early if transaction is not defined
    if (!transaction) return;
    const fetchClient = clientId => {
      return onSnapshot(
        doc(db, largeCustomerCollectionPath, clientId),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          dispatch(setClient({ key: key, ...data }));
        },
        error => {
          console.warn(TAG, "Error while fetching client data", error);
        },
      );
    };
    //console.log("fetching client");
    const clientId = transaction.organization;
    let clientUnSub;
    (async () => {
      clientUnSub = fetchClient(clientId);
    })();
    return () => {
      if (clientUnSub) {
        clientUnSub();
      }
    };
  }, [client, transaction, dispatch]);

  /**
   * Fetches this transaction target if not defined
   */
  useEffect(() => {
    // exit early if target is already defined
    if (target) return;
    // exit early if transaction is not defined
    if (!transaction) return;
    const fetchTarget = targetId => {
      return onSnapshot(
        doc(db, largeCustomerTargetPath, targetId),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          dispatch(setTarget({ key: key, ...data }));
        },
        error => {
          console.warn(TAG, "Error while fetching target data", error);
        },
      );
    };
    console.log("fetching target");
    const targetId = transaction.target;
    let targetUnSub;
    (async () => {
      fetchTarget(targetId);
    })();
    return () => {
      if (targetUnSub) {
        targetUnSub();
      }
    };
  }, [target, transaction, dispatch]);

  const onSubmit = async transactionState => {
    // exit early if already submitting
    if (submitting) return;
    //console.log(TAG, "onSubmit");
    // set submitting as true
    setSubmitting(true);

    //console.log("Submitting...");
    //console.log("Images to upload:", classifiedNotUploaded);
    // begin classifiedNotUploaded image indexing from this
    let firstIndex = nextImageIndex(
      transaction.pictures.classified.reduce((imageUris, classifiedImage) => {
        imageUris.push(classifiedImage.imageUri);
        return imageUris;
      }, []),
    );
    const storage = getStorage();
    const newTx = { ...transaction };

    // choose correct log event, based on are we creating or updating a transaction
    const LOG_EVENT =
      newTx.key === "NEW" ? logEvents.LOG_CREATE : logEvents.LOG_UPDATE;
    // check if we need to pre-create an ID
    if (newTx.key === "NEW") {
      const newTxRef = doc(collection(db, largeCustomerTransactionPath));
      console.log("Pre creating new ID", newTxRef.id);
      newTx.key = newTxRef.id;
    }

    const newPictures = { ...newTx.pictures };
    const newClassified = [...newPictures.classified];
    // upload images that need to be uploaded.
    // there can be images in there that are edited existing classified images,
    // in those cases they already have a "imageUri" field, which we'll use to overwrite
    // the existing image in storage.
    for await (const notUploadedImage of classifiedNotUploaded) {
      // name already exists, hasOwnProperty also ignores properties passed
      // down by prototype chain (it's safer than => "imageUri" in notUploadedImage)
      if (notUploadedImage.hasOwnProperty("imageUri")) {
        const storageRef = ref(storage, notUploadedImage.imageUri);
        try {
          await uploadBytes(storageRef, notUploadedImage.file);
          // only keep a selected number of fields
          const newImg = {
            classification: notUploadedImage.classification,
            size: notUploadedImage.size,
            comment: notUploadedImage.comment,
            imageUri: notUploadedImage.imageUri,
          };
          // keep rejected data if it's defined
          if (notUploadedImage.hasOwnProperty("rejected")) {
            newImg.rejected = notUploadedImage.rejected;
          }
          newClassified.push(newImg);
        } catch (error) {
          /*
          console.log(
            "Error while uploading image with existing key",
            notUploadedImage.imageUri,
            error,
          );
          */
          console.log("Error while uploading with existing key");
        }
      } else {
        // new name, use firstIndex and increment it
        const key = newTx.key + "/" + newTx.key + "_" + firstIndex + ".jpg";
        firstIndex++;
        const storageRef = ref(storage, key);
        try {
          await uploadBytes(storageRef, notUploadedImage.file);
          newClassified.push({
            classification: notUploadedImage.classification,
            size: notUploadedImage.size,
            comment: notUploadedImage.comment,
            imageUri: key,
          });
        } catch (error) {
          //console.log("Error while uploading image with new key", key, error);
          console.log("Error while uploadting with new key");
        }
      }
    }
    // set pictures
    newPictures.classified = newClassified;
    newTx.pictures = newPictures;

    // set transaction state if it was passed as an argument,
    // otherwise it'll stay the same
    if (transactionState) {
      newTx.state = transactionState;
    }

    // offer price === null or NaN, calculate auto price here
    if (newTx.offer.price === null || _.isNaN(newTx.offer.price)) {
      const classifiedSum = calculateAcceptedPictureTotalSize(
        newTx.pictures.classified,
      );
      const notUploadedSum = classifiedNotUploaded.reduce((sum, picture) => {
        const size = parseInt(picture.size);
        if (!isNaN(size)) {
          sum += size;
        }
        return sum;
      }, 0);
      const totalSum = classifiedSum + notUploadedSum;
      if (totalSum <= 10) {
        const offer = { ...newTx.offer };
        offer.price = calculateAutoprice(newTx.pictures.classified);
        newTx.offer = offer;
      } else {
        console.log("Not calculating auto price", newTx.offer);
      }
    } else {
      // offer price can be a string if the user makes a mistake
      // here we'll cast it to a number
      if (newTx.offer) {
        const offer = { ...newTx.offer };
        if (offer.price) {
          offer.price = Number(offer.price);
          newTx.offer = offer;
        }
      }
    }

    // filter out some fields we have added to the transaction object
    // for table sorting/other purposes, we can still keep those in the redux
    // state, but we don't really want those in Firestore.
    const fields = [
      "additionalInfo",
      "key",
      "offer",
      "pictures",
      "state",
      "target",
      "organization",
      "updatedAt",
      "log",
    ];
    const filteredTx = _.pick(newTx, fields);

    // append log
    const loggedTx = appendLog(
      LOG_EVENT,
      null,
      null,
      filteredTx,
      await currentUserLogInfo(),
    );

    const txRef = doc(db, largeCustomerTransactionPath, filteredTx.key);
    await setDoc(txRef, loggedTx);
    // clear submitting
    setSubmitting(false);
    // clear not uploaded
    setClassifiedNotUploaded([]);
    // update redux state
    dispatch(setTransaction(newTx));
    // update target doc log
    await updateTargetLogByTransaction(loggedTx.target);
    // if query string is empty (in the URL) we can assume
    // we're creating a new Transaction, in which case
    // after the creation we want to redirect to the transactions
    // page with the key (or document ID) as the id parameter.
    if (
      _.isEmpty(
        qs.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
      )
    ) {
      history.replace({
        pathname: "/tilaus",
        search: "?id=" + filteredTx.key,
        transaction: newTx,
      });
    }
  };

  const getReportDoc = async () => {
    if (!transaction) return null;
    const q = query(
      collection(db, largeCustomerReportPath),
      where("transaction", "==", transaction.key),
      limit(1),
    );
    const docResult = await getDocs(q);

    let doc = null;
    if (docResult.size > 0 && docResult.docs[0]) {
      const firstDoc = docResult.docs[0];
      doc = { key: firstDoc.id, ...firstDoc.data() };
    }
    return doc;
  };

  /**
   * Accepts this transaction on behalf of the client
   */
  const acceptForClient = async () => {
    const txRef = doc(db, largeCustomerTransactionPath, transaction.key);
    updateDoc(txRef, {
      state: ACCEPTED,
    });
  };

  // return loading indicator if target or client is undefined
  // if one of them is undefined, transaction should also be undefined.
  if (!target || !client) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {/* modal section */}
      {transaction &&
        (transaction.state === ACCEPTED ||
          transaction.state === COMPLETED ||
          transaction.state === REPORT_CREATED) && (
          <ReportCreateModal
            visible={reportCreateShown}
            onHide={() => {
              setReportCreateShown(false);
            }}
            transactionDocument={transaction}
            onComplete={state => {
              setReportCreateShown(false);
              dispatch(setTransaction({ ...transaction, state: state }));
            }}
            reportDoc={reportDoc}
          />
        )}

      {transaction && transaction.state >= REPORTED && (
        <ReportModal
          transactionDocument={transaction}
          onHide={() => {
            setReportViewShown(false);
          }}
          visible={reportViewShown}
        />
      )}
      <TransactionDeleteModal
        visible={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onDelete={async () => {
          setSubmitting(true);
          const txRef = doc(db, largeCustomerTransactionPath, transaction.key);
          await deleteDoc(txRef);
          setSubmitting(false);
          history.goBack();
        }}
      />
      {/* button section */}
      <Box m={5} />
      <div className="row mb-4">
        <div className="d-flex flex-row-reverse align-items-center">
          <Box pr={4} />
          {submitting && (
            <>
              <Loading />
              <Box ml={2} />
            </>
          )}
          <button
            onClick={() => {
              console.log(TAG, "Save press");
              onSubmit();
            }}
            className="btn btn-primary">
            TALLENNA
          </button>
          <button
            onClick={() => {
              console.log(TAG, "Cancel press");
              history.goBack();
            }}
            className=" btn btn-transparent me-5">
            PERUUTA
          </button>
          {transaction?.state === WAITING && (
            <button
              onClick={() => {
                acceptForClient();
              }}
              className=" btn btn-transparent me-5">
              HYVÄKSY ASIAKKAAN PUOLESTA
            </button>
          )}
          {/* allow user to create a report if the state is ACCEPTED or COMPLETED
            completed comes right after ACCEPTED, if a user presses "Merkkaa korjatuksi" 
            from the mobile app, I decided to "merge" that behavior into a single create report here */}
          {(transaction?.state === ACCEPTED ||
            transaction?.state === COMPLETED) && (
            <button
              className="btn btn-primary me-5"
              onClick={() => {
                console.log(TAG, "Create report");
                setReportCreateShown(true);
              }}>
              LUO RAPORTTI
            </button>
          )}
          {transaction?.state >= REPORTED && (
            <button
              className="btn btn-primary me-5"
              onClick={() => {
                console.log(TAG, "View report");
                setReportViewShown(true);
              }}>
              KATSO RAPORTTI
            </button>
          )}
          {transaction?.state == REPORT_CREATED && (
            <button
              className="btn btn-primary me-5"
              onClick={() => {
                console.log(TAG, "View draft report");
                setReportCreateShown(true);
              }}>
              KATSO RAPORTTI
            </button>
          )}
        </div>
      </div>
      {/* target info & transaction info section */}
      <div className="row justify-content-between">
        {/* target info */}
        <div className="col-xs-12 col-lg-4 col-xl-4">
          <TargetInfo
            target={target}
            classes="info-container-mt-0"
            contacts={contacts}
            setContacts={setContacts}
          />
        </div>
        {/* transaction info */}
        <div className="col-xs-12 col-lg-7 col-xl-6">
          <TransactionInfo
            contacts={contacts}
            transactionDocument={transaction}
            submitting={submitting}
            submittingPdf={submittingPdf}
            submittingReportPdf={submittingReportPdf}
            pdfError={pdfError}
            reportPdfError={reportPdfError}
            classifiedNotUploaded={classifiedNotUploaded}
            setClassifiedNotUploaded={setClassifiedNotUploaded}
            onSubmit={() => {
              onSubmit(WAITING);
            }}
            onCancel={() => {
              history.goBack();
            }}
            onSave={() => {
              onSubmit();
            }}
            downloadPDF={async () => {
              // creates a PDF document from UPLOADED images, converts
              // it into a blob, and creates a data URL for it, which can be used
              // to preview/print/download it.
              for await (const imgClassification of Object.keys(tabLabels)) {
                const createdPdf = await createPdf(
                  transaction,
                  target,
                  imgClassification,
                );
                const blob = await pdf(createdPdf).toBlob();
                const url = URL.createObjectURL(blob);

                window.open(url, "_blank", "noopener,noreferrer");
              }
            }}
            downloadReportPDF={async () => {
              const report = await getReportDoc();
              // creates a PDF document from UPLOADED images, converts
              // it into a blob, and creates a data URL for it, which can be used
              // to preview/print/download it.
              if (report) {
                const createdPdf = await createReportPdf(report, target);
                const blob = await pdf(createdPdf).toBlob();
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank", "noopener,noreferrer");
              }
            }}
            sendPDF={async recipient => {
              if (submittingPdf) return;
              setSubmittingPdf(true);
              const pdfs = [];
              for await (const imgClassification of Object.keys(tabLabels)) {
                // see if there are any images in this classification category
                const images = transaction.pictures.classified.filter(
                  img => img.classification == imgClassification,
                );
                // and skip creating a pdf if there's 0 images.
                if (images.length === 0) {
                  continue;
                }
                const createdPdf = await createPdf(
                  transaction,
                  target,
                  imgClassification,
                );
                let fileName =
                  "yhteenveto_" +
                  imgClassification +
                  "_" +
                  target.address +
                  " " +
                  target.postalCode +
                  " " +
                  target.postalDistrict +
                  ".pdf";
                fileName = fileName.split(" ").join("_");
                pdfs.push({ pdf: createdPdf, fileName });
              }
              try {
                await sendPdfEmail(recipient, pdfs, target.name, transaction);
                setSubmittingPdf(false);
              } catch (error) {
                setSubmittingPdf(false);
                setPdfError("Virhe lähettäessä PDF " + error.message);
              }
            }}
            sendReportPDF={async recipient => {
              // validate that we have a TX object and the state
              // is at least "REPORTED"
              if (!transaction) return;
              if (transaction.state < REPORTED) return;

              if (submittingReportPdf) return;
              setSubmittingReportPdf(true);

              const report = await getReportDoc();
              if (!report) {
                console.warn("No report");
                setSubmittingReportPdf(false);
              }
              const createdPdf = await createReportPdf(report, target);
              let fileName =
                "raportti_" +
                target.address +
                " " +
                target.postalCode +
                " " +
                target.postalDistrict +
                ".pdf";
              fileName = fileName.split(" ").join("_");
              try {
                await sendReportPdfEmail(
                  recipient,
                  [{ pdf: createdPdf, fileName: fileName }],

                  target.name,
                );
                setSubmittingReportPdf(false);
              } catch (error) {
                setSubmittingReportPdf(false);
                setReportPdfError("Virhe lähettäessä PDF " + error.message);
              }
            }}
            onDelete={() => {
              setShowDeleteModal(true);
            }}
          />
        </div>
        {/* /target info & transaction info row */}
      </div>
      <Box m={5} />
    </>
  );
};

export default TransactionDetails;
