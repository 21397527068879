import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  pdf,
} from "@react-pdf/renderer";
import logo from "../../assets/logo/logo.png";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "@firebase/storage";
import _ from "lodash";
import { httpsCallable } from "firebase/functions";
import { functions, pdfStorage } from "../../firebase/firebaseApp";
//import watermark from "../../assets/watermark_color.png";
import watermark from "../../assets/watermark_black.png";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { tabLabels } from "../image/imageClassifications";
import axios from "axios";

/**
 * Calculates autoprice from pictures, assumes pictureArray
 * is an array of objects, with at least "size" property.
 *
 * autoprice is calculated in the following way:
 * 1-5 m2 = 240€/m2
 * > 5 = 190€/m2
 * @param {*} pictureArray
 */
const calculateAutoprice = pictureArray => {
  const sizeSum = pictureArray.reduce((sum, picture) => {
    const size = parseInt(picture.size);
    if (!isNaN(size)) {
      sum += size;
    }
    return sum;
  }, 0);
  if (sizeSum <= 5) {
    return sizeSum * 240;
  }
  return sizeSum * 190;
};

const calculateAcceptedPictureTotalSize = pictureArray => {
  return pictureArray.reduce((sum, picture) => {
    if (!picture.rejected) {
      const size = parseInt(picture.size);
      if (!isNaN(size)) {
        sum += size;
      }
    }
    return sum;
  }, 0);
};

const styles = StyleSheet.create({
  roboto: {
    fontFamily: "Roboto",
    fontSize: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  borderedSection: {
    borderWidth: 1,
    borderColor: "black",
    padding: 20,
  },
  row: {
    flexDirection: "row",
  },
});

const createReportPdf = async (reportDocument, targetDocument) => {
  // register Roboto fonts
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
        fontWeight: 700,
      },
    ],
  });
  const title = "Raportti " + targetDocument.name;
  const subtitle =
    targetDocument.address +
    ", " +
    targetDocument.postalCode +
    " " +
    targetDocument.postalDistrict;
  // resolve imageUris
  const imgArray = await resolveReportImageUrls(reportDocument.pictures);

  // create <Image /> elements from image URLs
  const imageElements = pdfImages(imgArray);

  // generate pages, two images per page
  const imagePairArray = _.chunk(imageElements, 2);
  let i = 0;
  const imagePairs = imagePairArray.map((pair, index) => {
    return (
      <View
        key={"imagePair" + index}
        style={[styles.row, { padding: 20 }]}
        wrap={false}>
        {pair.map((imgElement, ind) => {
          i++;
          return (
            <View
              style={{
                marginHorizontal: 10,
                flex: 1,
              }}
              key={"imagePairView" + i}>
              {imgElement}
              <Text
                style={[styles.roboto, styles.bold, { textAlign: "center" }]}>
                Kuva {i}
              </Text>
            </View>
          );
        })}
      </View>
    );
  });

  // render an "empty" pdf when there's no images
  if (imagePairs.length === 0) {
    return (
      <Document>
        <Page size="A4" orientation="landscape" wrap={false}>
          {pdfHeader(1, 1, title, subtitle, "")}
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <Text>Ei kuvia</Text>
          </View>
          {pdfFooter()}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      {imagePairs.map((imgPair, index) => {
        return (
          <Page size="A4" orientation="landscape" wrap={false}>
            <View wrap={false}>
              {pdfHeader(index + 1, imagePairs.length, title, subtitle, "")}
              {imgPair}
              {pdfFooter()}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

/**
 * Creates an offer PDF
 * @param {*} transactionDocument
 * @param {*} targetDocument
 * @returns
 */
const createPdf = async (
  transactionDocument,
  targetDocument,
  imageClassification,
) => {
  // register Roboto fonts
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
        fontWeight: 700,
      },
    ],
  });

  // create pdfHeader strings
  const title = "Raportti - " + targetDocument.name;
  const subtitle =
    targetDocument.address +
    ", " +
    targetDocument.postalCode +
    " " +
    targetDocument.postalDistrict;
  const dateString = "Kartoituspäivä " + transactionDocument.offer.createdAt;
  // resolve imageUris
  const imgArray = await resolveImageUrls(
    transactionDocument.pictures.classified.filter(
      img => img.classification == imageClassification,
    ),
  );

  const compressedImages = await compressPdfImages(imgArray);

  // create <Image /> elements from image URLs
  const imageElements = pdfImages(compressedImages);

  // generate pages, two images per page
  const imagePairArray = _.chunk(imageElements, 2);
  let i = 0;
  const imagePairs = imagePairArray.map((pair, index) => {
    return (
      <View
        key={"imagePair" + index}
        style={[styles.row, { padding: 20 }]}
        wrap={false}>
        {pair.map((imgElement, ind) => {
          i++;
          return (
            <View
              style={{
                height: "100%",
                flex: 1,
                marginHorizontal: 10,
              }}>
              {imgElement}
              <Text
                style={[styles.roboto, styles.bold, { textAlign: "center" }]}>
                Kuva {i}
              </Text>
            </View>
          );
        })}
      </View>
    );
  });

  // render an "empty" pdf when there's no images
  if (imagePairs.length === 0) {
    return (
      <Document>
        <Page size="A4" orientation="landscape" wrap={false}>
          {pdfHeader(1, 1, title, subtitle, dateString, imageClassification)}
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <Text>Ei kuvia</Text>
          </View>
          {pdfFooter()}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      {imagePairs.map((imgPair, index) => {
        return (
          <Page size="A4" orientation="landscape" wrap={false}>
            <View wrap={false}>
              {pdfHeader(
                index + 1,
                imagePairs.length,
                title,
                subtitle,
                dateString,
                imageClassification,
              )}
              {imgPair}
              {pdfFooter()}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

/**
 * Generates a header element for PDF page
 * @param {*} pageNumber
 * @param {*} totalPages
 * @param {*} title
 * @param {*} subtitle
 * @param {*} dateString
 * @returns
 */
const pdfHeader = (
  pageNumber,
  totalPages,
  title,
  subtitle,
  dateString,
  classification,
) => {
  return (
    <View
      style={[
        styles.row,
        styles.borderedSection,
        { justifyContent: "space-between", alignItems: "top" },
      ]}>
      <View style={{ width: 90 }}>
        <Image style={{ width: 80 }} src={logo} />
      </View>
      <View style={{ alignItems: "center" }}>
        <Text
          style={[
            styles.roboto,
            styles.bold,
            { fontSize: 18, paddingBottom: 4 },
          ]}>
          {title}
        </Text>
        <Text style={[styles.roboto, styles.bold, { paddingBottom: 4 }]}>
          {subtitle}
        </Text>
        <Text style={[styles.roboto, styles.bold]}>{dateString}</Text>
        {classification && (
          <Text
            style={[
              styles.roboto,
              styles.bold,
              { fontSize: 14, color: "#ff6319" },
            ]}>
            {classification}
          </Text>
        )}
      </View>
      <View style={{ width: 90, alignItems: "flex-end" }}>
        <Text style={[styles.roboto, styles.bold]}>
          Sivu {pageNumber}/{totalPages}
        </Text>
      </View>
    </View>
  );
};

/**
 * Generates a footer element for PDF page
 * @returns
 */
const pdfFooter = () => {
  return (
    <View
      style={[
        styles.borderedSection,
        { padding: 10, alignItems: "center", justifyContent: "space-between" },
      ]}>
      <Text style={[styles.roboto, styles.bold, { paddingBottom: 4 }]}>
        Asfalttipartio
      </Text>
      <Text style={[styles.roboto, { paddingBottom: 2 }]}>
        Louhimontie 10, 90630 Oulu
      </Text>
      <Text style={[styles.roboto]}>Jere Vuojus, +358 40 187 0430</Text>
    </View>
  );
};

/**
 * Generates <Image /> elements from an array of image URLs
 * @param {*} imageArray
 * @returns
 */
const pdfImages = imageArray => {
  const pdfImageArray = [];
  let i = 0;
  for (const url of imageArray) {
    pdfImageArray.push(
      <View
        key={"pdfImageWrapper" + i}
        style={{
          maxHeight: "100%",
          position: "relative",
        }}>
        <Image
          key={"pdfImage" + i}
          style={{ maxHeight: "100%", objectFit: "contain" }}
          src={url}
          wrap={false}
        />
        <Image
          key={"pdfImageWatermark" + i}
          style={{
            objectFit: "contain",
            position: "absolute",
            alignSelf: "center",
            top: "40%",
            width: "100px",
            height: "100px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
          src={watermark}
          wrap={false}
        />
      </View>,
    );
    i++;
  }
  return pdfImageArray;
};

/**
 * Resolves classified imageUris into downloadable image URLs
 * @param {*} classifiedPictures
 * @returns
 */
const resolveImageUrls = async classifiedPictures => {
  const urls = [];
  const storage = getStorage();
  for await (const picture of classifiedPictures) {
    const url = await getDownloadURL(ref(storage, picture.imageUri));
    urls.push(url);
  }
  return urls;
};

const resolveReportImageUrls = async reportImages => {
  const urls = [];
  const storage = getStorage();
  for await (const reportUri of reportImages) {
    const url = await getDownloadURL(ref(storage, reportUri));
    urls.push(url);
  }
  return urls;
};

const sendPdfEmail = async (
  recipient,
  pdfDocuments,
  targetName,
  transactionDocument,
) => {
  console.log("PDF email transaction", transactionDocument);
  const offer = transactionDocument.offer;
  let data = {
    email: recipient,
    pdfs: [],
    targetName: targetName,
  };
  if (offer) {
    const start = moment(offer.startDate, "YYYY-MM-DD");
    const end = moment(offer.endDate, "YYYY-MM-DD");
    Object.assign(data, {
      start: start.isValid() ? start.format("DD.MM.YYYY") : "",
      end: end.isValid() ? end.format("DD.MM.YYYY") : "",
      price: typeof offer.price === "number" ? offer.price : "Ei asetettu",
    });
  }

  const storageForPdf = pdfStorage();

  const pdfDownloadUrls = [];
  // pdfDocuments are objects with the created PDF and fileName
  // {pdf: {...}, fileName: "someFileName"}
  for (const pdfDocument of pdfDocuments) {
    const blob = await pdf(pdfDocument.pdf).toBlob();

    const storageRef = ref(storageForPdf, pdfDocument.fileName);

    const pdfResult = await uploadBytes(storageRef, blob);
    const pdfDownloadURL = await getDownloadURL(pdfResult.ref);

    const shortUrl = await shortenUrl(pdfDownloadURL);
    if (!shortUrl.link) {
      throw new Error("shortUrl didn't have a link!", shortUrl);
    }

    pdfDownloadUrls.push({
      url: shortUrl.link,
      fileName: pdfDocument.fileName,
    });
  }

  Object.assign(data, { downloadablePdfs: pdfDownloadUrls });

  const sendPdfEmailCallable = httpsCallable(functions, "sendPdfEmail");
  try {
    await sendPdfEmailCallable(data);
  } catch (err) {
    console.error("Error while sending pdf", err);
    throw err;
  }
};

/**
 * Returns bitly response data, example object:
 * {
 *  created_at: "2022-06-03T14:53:43+0000",
 *  id: "bit.ly/3901lEM",
 *  link: "https://bit.ly/3901lEM",
 *  long_url: "<the original url here>",
 *  ... some additional fields we don't need as of writing this.
 * }
 * @param {*} url
 * @returns
 */
const shortenUrl = async url => {
  try {
    const response = await axios.post(
      "https://api-ssl.bitly.com/v4/shorten",
      { domain: "bit.ly", long_url: url },
      {
        headers: {
          // since the whole app is pretty much for private use,
          // it's kind of ok to leave the api key here
          Authorization: "Bearer 94b0ebff3a4a6dd2220f39dd348813debd9b88aa",
        },
      },
    );
    const data = response?.data;
    if (data) {
      console.log("shortened url", data);
      return data;
    }
  } catch (err) {
    console.warn("Error while shortening url", err);
    throw err;
  }
};

const sendReportPdfEmail = async (recipient, pdfDocuments, targetName) => {
  const data = {
    email: recipient,
    pdfs: [],
    targetName: targetName,
  };

  const storageForPdf = pdfStorage();

  const pdfDownloadUrls = [];
  // pdfDocuments are objects with the created PDF and fileName
  // {pdf: {...}, fileName: "someFileName"}
  for (const pdfDocument of pdfDocuments) {
    const blob = await pdf(pdfDocument.pdf).toBlob();

    const storageRef = ref(storageForPdf, pdfDocument.fileName);

    const pdfResult = await uploadBytes(storageRef, blob);
    const pdfDownloadURL = await getDownloadURL(pdfResult.ref);

    const shortUrl = await shortenUrl(pdfDownloadURL);
    if (!shortUrl.link) {
      throw new Error("shortUrl didn't have a link!", shortUrl);
    }

    pdfDownloadUrls.push({
      url: shortUrl.link,
      fileName: pdfDocument.fileName,
    });
  }

  Object.assign(data, { downloadablePdfs: pdfDownloadUrls });

  const sendPdfEmailCallable = httpsCallable(functions, "sendReportPdfEmail");

  return new Promise((resolve, reject) => {
    // response would be available in the then function
    // but we dont need it.
    sendPdfEmailCallable(data)
      .then(() => {
        resolve();
      })
      .catch(err => {
        console.log("Error while sending pdf", err);
        reject();
      });
  });
};

const readAsDataUrl = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const compressPdfImages = async imageUrlArray => {
  const options = {
    maxSizeMB: 0.15,
    maxWidthOrHeight: 1480,
  };
  const compressedUrls = [];
  for await (const imageUrl of imageUrlArray) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], imageUrl, { type: blob.type });
    try {
      const compressedFile = await imageCompression(file, options);
      //console.log("comp file", compressedFile);
      const newUrl = URL.createObjectURL(compressedFile);
      compressedUrls.push(newUrl);
    } catch (err) {
      console.log("Error while compressing", err);
    }
  }
  return compressedUrls;
};

const sendTransactionsPdfEmail = async (
  targetDoc,
  transactionDoc,
  recipientEmail,
) => {
  const pdfs = [];
  for await (const imgClassification of Object.keys(tabLabels)) {
    // see if there are any images in this classification category
    const images = transactionDoc.pictures.classified.filter(
      img => img.classification == imgClassification,
    );
    // and skip creating a pdf if there's 0 images.
    if (images.length === 0) {
      continue;
    }

    const createdPdf = await createPdf(
      transactionDoc,
      targetDoc,
      imgClassification,
    );
    let fileName =
      "yhteenveto_" +
      imgClassification +
      "_" +
      targetDoc.address +
      " " +
      targetDoc.postalCode +
      " " +
      targetDoc.postalDistrict +
      ".pdf";
    fileName = fileName.split(" ").join("_");
    pdfs.push({ pdf: createdPdf, fileName });
  }

  try {
    await sendPdfEmail(recipientEmail, pdfs, targetDoc.name, transactionDoc);
    return { success: true };
  } catch (error) {
    console.warn("[sendTransactionsPdfEmail]", "Failed to send PDF email");
    return { success: false, message: error.message };
  }
};

export {
  calculateAutoprice,
  createPdf,
  sendPdfEmail,
  createReportPdf,
  calculateAcceptedPictureTotalSize,
  sendReportPdfEmail,
  sendTransactionsPdfEmail,
};
