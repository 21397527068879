import { Box } from "@material-ui/core";
import React from "react";
import EditIcon from "../EditIcon";

/**
 * Renders contacts name, phone numebers and emails
 * @param {*} contact contact firebase document
 * @param {*} onEditContact handler to show update modal
 * @returns
 */
const ContactInfo = ({ contact, onEditContact }) => {
  /**
   * Renders contacts phone numbers
   * @param {*} contact
   */
  const renderPhoneNumbers = contact => {
    return (
      <>
        {contact.numbers.map((numberObject, index) => {
          return (
            <React.Fragment key={"number" + index}>
              <div className="text-center">
                <label>{numberObject.label}</label>
                <p>{numberObject.number}</p>
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  /**
   * Renders contacts emails
   * @param {*} contact
   * @returns
   */
  const renderEmails = contact => {
    return (
      <>
        {contact.emails.map((emailObject, index) => {
          return (
            <React.Fragment key={"email" + index}>
              <div className="text-center">
                <label>{emailObject.label}</label>
                <p>{emailObject.email}</p>
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="col info-container text-center">
        <div className="icon-title d-flex flex-row flex-wrap justify-content-evenly align-items-center">
          <Box m={1} />
          <h3 className="d-flex flex-shrink">{contact.name}</h3>
          <EditIcon
            size={24}
            onClick={() => {
              console.log("edit contact");
              onEditContact();
            }}
          />
        </div>
        <Box m={2} />
        <h3>Sähköpostit</h3>
        <Box paddingLeft={0}>
          <Box m={1} />
          {renderEmails(contact)}
        </Box>
        <Box m={2} />
        <h3>Puhelinnumerot</h3>
        <Box paddingLeft={0}>
          <Box m={1} />
          {renderPhoneNumbers(contact)}
        </Box>
      </div>
    </>
  );
};

export default ContactInfo;
