const UNSENT = 50;
const CREATED = 100;
const ORDERED = 200; // transactions with state >= 200 can be seen by clients in the mobile app
const WAITING = 300;
const WAITING_REVISION = 301;
const ACCEPTED = 310;
const REJECTED = 320;
const COMPLETED = 400;
const REPORT_CREATED = 405;
const REPORTED = 410;
const GIVEN_FEEDBACK = 420;

// TransactionOffer translations
const stateTranslations = {
  50: "Lähettämättä",
  100: "Tarkastettava",
  200: "Tilattu",
  300: "Odottaa",
  301: "Odottaa uudelleenhinnoittelua",
  310: "Hyväksytty",
  320: "Hylätty",
  400: "Valmis",
  405: "Raportti luotu",
  410: "Raportoitu",
  420: "Raportoitu",
};

// TransactionItem translations
const itemTranslations = {
  50: "Lähettämättä",
  100: "Tarkastettava",
  200: "Tilattu",
  300: "Tarjous hyväksyttävänä",
  301: "Odottaa uudelleenhinnoittelua",
  310: "Tarjous hyväksytty",
  320: "Tarjous hylätty",
  400: "Tilaus valmis",
  405: "Raportti luotu",
  410: "Raportoitu",
  420: "Raportoitu",
};

// clients version of TransactionItem translations
const clientItemTranslations = {
  50: "Lähettämättä",
  100: "Tarkastettava",
  200: "Tilattu",
  300: "Hyväksymättä",
  301: "Uudelleen hyväksyttävänä",
  310: "Tarjous hyväksytty",
  320: "Tarjous hylätty",
  400: "Tilaus valmis",
  // a client shouldn't see report is in draft
  405: "Tilaus valmis",
  410: "Raportoitu",
  420: "Raportoitu",
};

// color codings for transaction states
const stateColor = {
  [UNSENT]: "white",
  [CREATED]: "white",
  [ORDERED]: "white",
  [WAITING]: "gray",
  [WAITING_REVISION]: "blue",
  [ACCEPTED]: "green",
  [REJECTED]: "red",
  [COMPLETED]: "#ff6200",
  [REPORT_CREATED]: "#ff6200",
  [REPORTED]: "#ff6200",
  [GIVEN_FEEDBACK]: "#ff6200",
};

export {
  UNSENT,
  CREATED,
  ORDERED,
  WAITING,
  WAITING_REVISION,
  ACCEPTED,
  REJECTED,
  COMPLETED,
  REPORT_CREATED,
  REPORTED,
  stateTranslations,
  itemTranslations,
  clientItemTranslations,
  GIVEN_FEEDBACK,
  stateColor,
};
