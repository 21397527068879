import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination/TablePagination";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Table from "@material-ui/core/Table/Table";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Loading from "../Loading";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import SortableHeader from "../largeCustomers/SortableHeader";
import { useDispatch } from "react-redux";
import { setTarget } from "../../route/admin/targetSlice";
import {
  stateColor,
  stateTranslations,
} from "../transaction/TransactionStates";
import moment from "moment";
import {
  defaultRowsPerPageExtraLong,
  rowsPerPageOptionsExtraLong,
} from "../util/tableUtil";
import { useAppSelector } from "../../store/storeHooks";
import {
  LargeCustomerTarget,
  TableExtendedTarget,
} from "../../firebase/document-types";

const columns = [
  { label: "" },
  { label: "Asiakas", property: "clientName" },
  { label: "Kohteen nimi", property: "name" },
  { label: "Osoite", property: "wholeAddress" },
  { label: "Toimitusaika-arvio", property: "latestOfferStartDate" },
  { label: "Tarjous", property: "latestOfferPrice" },
  { label: "Status", property: "latestState" },
  { label: "Viimeisin muokkaaja", property: "logUser" },
  { label: "Viimeisin muokkaus", property: "logTime" },
  { label: "" },
];

interface AllTargetsTableProps {
  targets: LargeCustomerTarget[];
  statesFetched: boolean;
  checkedItems: TableExtendedTarget[];
  setCheckedItems: (checkedItems: TableExtendedTarget[]) => void;
}

const AllTargetsTable: React.FunctionComponent<AllTargetsTableProps> = ({
  targets,
  statesFetched,
  checkedItems,
  setCheckedItems,
}) => {
  const { clients } = useAppSelector(state => state.largeCustomer);
  const dispatch = useDispatch();

  const history = useHistory();
  const [sortConfig, setSortConfig] = useState({
    propertyName: "clientName",
    sortType: SortingType.Ascending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const [targetItems, setTargetItems] = useState<TableExtendedTarget[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPageExtraLong);
  const [page, setPage] = useState(0);

  /**
   * Creates custom objects from targets to be shown in the table
   */
  useEffect(() => {
    const findClient = (clientId: string) => {
      return clients.find(client => client.key === clientId);
    };

    const tableItems: TableExtendedTarget[] = [];
    targets.forEach(target => {
      const wholeAddress =
        target.address + ", " + target.postalCode + " " + target.postalDistrict;
      const client = findClient(target.organization);
      if (client) {
        const item = {
          ...target,
          wholeAddress: wholeAddress,
          clientName: client.name,
        };
        tableItems.push(item);
      }
    });
    setTargetItems(tableItems);
  }, [targets, clients]);

  const sortedRows = useSortedRows(sortConfig, targetItems);

  const onRowClick = (item: TableExtendedTarget) => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.key === item.key,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  /**
   * Renders an extra field called latestState
   * @param {*} target
   * @returns
   */
  const renderState = (target: TableExtendedTarget) => {
    if (!statesFetched) {
      return <Loading />;
    }
    if (target.latestState) {
      const translation = stateTranslations[target.latestState];
      const color = stateColor[target.latestState];
      if (translation) {
        return (
          <div className="d-flex flex-row align-items-center">
            <div
              className="transaction-state"
              style={{ backgroundColor: color, marginRight: "0.25rem" }}></div>
            {translation}
          </div>
        );
      }
    }
    return "-";
  };

  /**
   * Renders price field in an extra field called latestOfferPrice
   * @param {*} target
   */
  const renderOfferPrice = (target: TableExtendedTarget) => {
    if (!statesFetched) {
      return <Loading />;
    }
    let returnStr = "";
    if (target.latestOfferPrice) {
      returnStr += target.latestOfferPrice + "\u00A0€";
    }
    if (target.latestOfferm2) {
      returnStr += "\u00A0/ " + target.latestOfferm2 + "\u00A0m2";
    }
    if (returnStr) {
      return returnStr;
    }
    return "-";
  };

  /**
   * Renders startDate and endDate if an extra field called latestOffer
   * @param {*} target
   */
  const renderOfferDates = (target: TableExtendedTarget) => {
    if (!statesFetched) {
      return <Loading />;
    }
    if (target.latestOfferStartDate && target.latestOfferEndDate) {
      return (
        moment(target.latestOfferStartDate).format("DD.MM.YYYY") +
        " - " +
        moment(target.latestOfferEndDate).format("DD.MM.YYYY")
      );
    }
    return "-";
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  if (!targets) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }
  // this could cause "problems", what if the data set
  // is actually empty?
  else if (sortedRows.length === 0) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );

              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((target, index) => {
              return (
                <TableRow
                  key={"target" + index}
                  hover
                  onClick={(event: any) => {
                    // if the user is clicking on the "Open" button, don't trigger this onClick
                    if (
                      // @ts-ignore
                      event.nativeEvent.target?.id === "allTargetOpenButton"
                    ) {
                      return;
                    }
                    onRowClick(target);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(
                          item => item.key === target.key,
                        ) > -1
                      }
                    />
                  </TableCell>
                  <TableCell>{target.clientName}</TableCell>
                  <TableCell>{target.name}</TableCell>
                  <TableCell>{target.wholeAddress}</TableCell>
                  <TableCell>{renderOfferDates(target)}</TableCell>
                  <TableCell>{renderOfferPrice(target)}</TableCell>
                  <TableCell>{renderState(target)}</TableCell>
                  <TableCell>{target.logUser}</TableCell>
                  <TableCell>{target.logTime}</TableCell>
                  <TableCell
                    onClick={() => {
                      dispatch(setTarget(target));
                      history.push({
                        pathname: "/kohde",
                        search: "?id=" + target.key,
                        // @ts-ignore
                        target: target,
                      });
                    }}>
                    <button id="allTargetOpenButton" className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptionsExtraLong}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e: any, newPage: any) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={(e: any) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default AllTargetsTable;
