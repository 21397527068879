export const ADMIN = "admin";
export const OFFICE_WORKER = "office-worker";
export const WORKER = "worker";
export const CLIENT = "client";

export type UserRole =
  | typeof ADMIN
  | typeof OFFICE_WORKER
  | typeof WORKER
  | typeof CLIENT;
