import { Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import useCsvImport from "../hooks/useCsvImport";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Papa from "papaparse";
import XLSX from "xlsx";
import { addDoc, collection } from "firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";
import Loading from "../Loading";

const ClientImportModal = ({ visible = false, onHide }) => {
  const [file, setFile] = useState(null);
  const [parsedResult, setParsedResult] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles[0]) {
        setParsedResult(null);
        setFile(acceptedFiles[0]);
      }
    },
    [setFile, setParsedResult],
  );

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, { type: "array" });

      wb.SheetNames.forEach(name => {
        const sheet = wb.Sheets[name];
        const csv = XLSX.utils.sheet_to_csv(sheet, {});
        const parsed = Papa.parse(csv);
        // filter invalid rows & slice first row out (header row)
        setParsedResult(parsed.data.filter(row => row.length > 3).slice(1));
      });
    };

    reader.readAsArrayBuffer(file);
  }, [file]);

  const { getRootProps, getInputProps, isDragActive } = useCsvImport(onDrop);

  /**
   * Parses a clients data out of a csv row
   */
  const parseClient = useCallback(csvRow => {
    const parsedClient = {
      name: csvRow[0] ?? "",
      address: csvRow[1] ?? "",
      postalCode: csvRow[2] ?? "",
      postalDistrict: csvRow[3] ?? "",
      y_id: csvRow[4] ?? "",
      billing: {
        billingAddress: "",
        eBilling: "",
        eBillingProvider: "",
      },
      contacts: [],
      log: [],
    };
    return parsedClient;
  }, []);

  const createClients = csvArray => {
    if (submitting) return;
    setSubmitting(true);
    const clients = csvArray.reduce((clientArr, csvRow) => {
      clientArr.push(parseClient(csvRow));
      return clientArr;
    }, []);
    console.log("Creating clients", clients);
    const clientRef = collection(db, largeCustomerCollectionPath);
    try {
      for (const client of clients) {
        addDoc(clientRef, client);
      }
    } catch (error) {
      console.warn("Error while creating clients", error);
    }
    setFile(null);
    setParsedResult(null);
    setSubmitting(false);
    onHide();
  };

  return (
    <Modal show={visible} onHide={onHide} dialogClassName="modal-90w">
      <Modal.Body>
        <div className="text-center">
          <h4>Tuo asiakkaita</h4>
        </div>
        <Box m={2} />
        <div
          className={
            isDragActive
              ? "d-flex justify-content-center align-items-center white-bg input-box drag-box drag-box-active"
              : "d-flex justify-content-center align-items-center white-bg input-box drag-box"
          }
          {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="input-text">
            Vedä tiedostot tähän tai{" "}
            <span className="link">valitse ne tietokoneestasi</span>
          </p>
        </div>
        <div>
          {parsedResult && (
            <>
              <div className="text-center my-3">
                {!submitting && (
                  <button
                    onClick={() => createClients(parsedResult)}
                    className="btn btn-primary">
                    Luo asiakkaat
                  </button>
                )}
                {submitting && <Loading />}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nimi</TableCell>
                    <TableCell>Osoite</TableCell>
                    <TableCell>Postinumero</TableCell>
                    <TableCell>Postitoimipaikka</TableCell>
                    <TableCell>Y-tunnus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedResult &&
                    parsedResult.map((csvRow, index) => {
                      const client = parseClient(csvRow);
                      return (
                        <TableRow key={"importedClient" + index}>
                          <TableCell>{client.name}</TableCell>
                          <TableCell>{client.address}</TableCell>
                          <TableCell>{client.postalCode}</TableCell>
                          <TableCell>{client.postalDistrict}</TableCell>
                          <TableCell>{client.y_id}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClientImportModal;
