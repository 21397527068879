import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTransactionPath,
  userCollectionPath,
} from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import _ from "lodash";
import { latestTargetDocs } from "../util/TargetUtils";
import { createPdf } from "../transaction/transactionUtil";
import { ASPHALT_DAMAGE, tabLabels } from "../image/imageClassifications";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { workOrderStorrage } from "../../firebase/firebaseApp";
import { latestTransactionDocsByTargetOffer } from "../util/transactionFunctions";
var JSZip = require("jszip");

/**
 * Even though the function is named download, it actually
 * does upload all of the stuff to the cloud (as well).
 * Should a user want to download all of the files as well,
 * you can enable the saveAs line at the end of the function.
 * @param {*} targetDocuments
 * @returns
 */
export const downloadLatestOffers = async targetDocuments => {
  console.log("download latest offers", targetDocuments);

  const pdfZipResults = {};

  if (targetDocuments.length === 0) return {};
  // get just the keys from targets
  const targetIds = _.map(targetDocuments, "key");
  console.log("ids", targetIds);
  // get latest transaction for each target
  const targetRef = collection(db, largeCustomerTransactionPath);

  // chunk IDs into batches of 10
  const idBatches = _.chunk(targetIds, 10);

  // create promises for each batch
  let batches = [];
  for (const idBatch of idBatches) {
    const q = query(targetRef, where("target", "in", idBatch));
    batches.push(
      new Promise(response => {
        getDocs(q).then(result =>
          response(
            result.docs.map(doc => {
              return { key: doc.id, ...doc.data() };
            }),
          ),
        );
      }),
    );
  }

  // resolve all promises
  await Promise.all(batches).then(async content => {
    const fetchedDocs = content.flat();

    // get the latest transaction for each target
    const sortedDocs = _.orderBy(fetchedDocs, ["target", "updatedAt"], "asc");
    const latestDocsForTargets = latestTargetDocs(sortedDocs);

    const workOrderSotrage = workOrderStorrage();
    const createdPdfsForTarget = {};
    // create PDF for each target/transaction
    for await (const latestDoc of latestDocsForTargets) {
      const transactionsTarget = targetDocuments.find(
        targetDoc => targetDoc.key === latestDoc.target,
      );
      if (!transactionsTarget) {
        console.warn("Did not find target for transaction", latestDoc);
        continue;
      }
      const pdfArray = createdPdfsForTarget[transactionsTarget.key] ?? [];
      for await (const imgClassification of Object.keys(tabLabels)) {
        const createdPdf = await createPdf(
          latestDoc,
          transactionsTarget,
          imgClassification,
        );
        pdfArray.push({
          pdf: createdPdf,
          name: imgClassification,
        });
      }
      createdPdfsForTarget[transactionsTarget.name] = pdfArray;
    }

    for await (const [targetName, pdfArray] of Object.entries(
      createdPdfsForTarget,
    )) {
      const zip = new JSZip();
      for await (const pdfObject of pdfArray) {
        const blob = await pdf(pdfObject.pdf).toBlob();
        zip.file(pdfObject.name + ".pdf", blob, { binary: true });
      }
      const content = await zip.generateAsync({ type: "blob" });
      // url encode name
      const targetNameEncoded = encodeURIComponent(targetName + ".zip");
      const storageRef = ref(workOrderSotrage, targetNameEncoded);
      const zipUploadResult = await uploadBytes(storageRef, content);
      const zipUrl = await getDownloadURL(zipUploadResult.ref);
      //saveAs(content, targetName + ".zip");
      pdfZipResults[targetName] = zipUrl;
    }
  });
  console.log("finished", pdfZipResults);
  return pdfZipResults;
};

/**
 * Fetches organization data for an array of target documents
 * Returns an object where organizations ID points to the
 * organizations document.
 * @param {*} targetDocuments
 * @returns
 */
const fetchOrganizationDataForTargets = async targetDocuments => {
  const organizationMap = {};

  // get the IDs of the customers, also known as 'key' in the document
  const customerIds = _.uniq(_.map(targetDocuments, "organization"));

  // batch into chunks of 10
  const idBatches = _.chunk(customerIds, 10);

  // create reference to customer collection
  const customerRef = collection(db, largeCustomerCollectionPath);

  // create promises for each batch
  let batches = [];
  for (const idBatch of idBatches) {
    const q = query(customerRef, where(documentId(), "in", idBatch));
    batches.push(
      new Promise(resolve => {
        getDocs(q).then(result =>
          resolve(
            result.docs.map(doc => {
              return { key: doc.id, ...doc.data() };
            }),
          ),
        );
      }),
    );
  }

  // resolve all promises, flatten the result array
  const customerDocuments = (await Promise.all(batches)).flat();

  // add every document to the result map
  for (const customerDocument of customerDocuments) {
    organizationMap[customerDocument.key] = customerDocument;
  }

  return organizationMap;
};

/**
 * Fetches contact documents for each target document.
 * Returns an object where the target ID points to an array
 * of contact documents.
 * @param {Promise<{[key:string]: any[]}} targetDocuments
 */
const fetchTargetContacts = async targetDocuments => {
  const contactMap = {};

  if (targetDocuments.length === 0) return contactMap;
  // get all contact IDs
  let contactIds = [];
  for (const targetDocument of targetDocuments) {
    if (targetDocument.contacts) {
      contactIds.push(...targetDocument.contacts);
    }
    // "legacy" contacts are just a single ID in the target doc
    else if (targetDocument.contact) {
      contactIds.push(targetDocument.contact);
    }
  }
  // only keep unqiue values
  contactIds = _.uniq(contactIds);

  // batch into chunks of 10
  const idBatches = _.chunk(contactIds, 10);

  // create reference to user collection
  const contactRef = collection(db, userCollectionPath);

  // create promises for each batch
  let batches = [];
  for (const idBatch of idBatches) {
    const q = query(contactRef, where(documentId(), "in", idBatch));
    batches.push(
      new Promise(resolve => {
        getDocs(q).then(result =>
          resolve(
            result.docs.map(doc => {
              return { key: doc.id, ...doc.data() };
            }),
          ),
        );
      }),
    );
  }

  // resolve all promises, flatten the result array
  const contactDocuments = (await Promise.all(batches)).flat();

  for (const targetDocument of targetDocuments) {
    const contacttIds = [];
    if (targetDocument.contacts) {
      contacttIds.push(...targetDocument.contacts);
    } else if (targetDocument.contact) {
      contacttIds.push(targetDocument.contact);
    }

    const foundContacts = [];
    for (const contactId of contactIds) {
      // find contactDocument from contactDocuments
      const contactDocument = contactDocuments.find(
        contactDocument => contactDocument.key === contactId,
      );
      if (contactDocument) {
        foundContacts.push(contactDocument);
      }
    }

    contactMap[targetDocument.key] = foundContacts;
  }

  return contactMap;
};

const fetchTargetTransactions = async targetDocuments => {
  if (targetDocuments.length === 0) return [];

  // get all target IDs
  const targetIds = _.map(targetDocuments, "key");

  // batch into chunks of 10
  const idBatches = _.chunk(targetIds, 10);

  // create a reference to transactions
  const txRef = collection(db, largeCustomerTransactionPath);

  // create promises for each batch
  let batches = [];
  for (const idBatch of idBatches) {
    const q = query(txRef, where("target", "in", idBatch));
    batches.push(
      new Promise(resolve => {
        getDocs(q).then(result =>
          resolve(
            result.docs.map(doc => {
              return { key: doc.id, ...doc.data() };
            }),
          ),
        );
      }),
    );
  }

  const txs = (await Promise.all(batches)).flat();
  return txs;
};

export const targetsToExcelData = async targetDocuments => {
  if (targetDocuments.length === 0) return [];

  // get customer data for each target, we need the name
  // of the customer
  const organizationMap = await fetchOrganizationDataForTargets(
    targetDocuments,
  );

  // get contact data for each target, we need the names
  // of the contacts
  const contactMap = await fetchTargetContacts(targetDocuments);

  // get latest transaction data for each target, we need
  // date, m2 and price
  const latestDocsForTargets = latestTransactionDocsByTargetOffer(
    await fetchTargetTransactions(targetDocuments),
  );

  // map latest transaction data by target documents
  const latestTransactionMap = {};
  for (const latestDoc of latestDocsForTargets) {
    latestTransactionMap[latestDoc.target] = latestDoc;
  }

  // now we can construct the excel data
  const results = [];

  for (const targetDocument of targetDocuments) {
    // get all related data for this target
    const org = organizationMap[targetDocument.organization] ?? null;
    const contacts = contactMap[targetDocument.key] ?? null;
    const latestTransaction = latestTransactionMap[targetDocument.key] ?? null;

    // validate that we have all we need
    // if not, warn to log and skip
    if (org === null) {
      console.warn(
        "Unexpected: no organization found for target",
        targetDocument.key,
      );
      continue;
    }
    if (contacts === null) {
      console.warn(
        "Unexpected: no contacts found for target",
        targetDocument.key,
      );
      continue;
    }
    if (latestTransaction === null) {
      console.warn(
        "Unexpected: no latest transaction found for target",
        targetDocument.key,
      );
      continue;
    }

    // construct the excel row
    const address =
      targetDocument.address +
      ", " +
      targetDocument.postalCode +
      " " +
      targetDocument.postalDistrict;

    const repairm2Sum = latestTransaction.pictures.classified.reduce(
      (sum, picture) => {
        if (!picture.rejected && picture.classification === ASPHALT_DAMAGE) {
          const size = parseInt(picture.size);
          if (!isNaN(size)) {
            sum += size;
          }
        }
        return sum;
      },
      0,
    );

    const row = {
      "Asiakkaan nimi": org.name,
      Yhteyshenkilöt: contacts
        .map(contact => contact.name ?? contact.email)
        .join(", "),
      "Kohteen nimi": targetDocument.name,
      "Kohteen osoite": address,
      Kartoituspäivämäärä:
        latestTransaction.offer?.createdAt ?? "Ei kartoituspäivämäärää",
      "Korjauksen neliöt": repairm2Sum,
      Hinta: latestTransaction.offer?.price ?? "Ei hintaa",
    };
    results.push(row);
  }

  return results;
};
