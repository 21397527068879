import { TextField } from "@material-ui/core";
import {
  collection,
  limit,
  query,
  orderBy,
  where,
  getDocs,
} from "firebase/firestore";
import React, { useState, useEffect, useCallback } from "react";
import { db, largeCustomerCollectionPath } from "../firebase";
import _ from "lodash";

/**
 * A component that can be used to search for an organization autocomplete style.
 * @param {*} onOrganization called when user chooses an organization from results
 * @returns
 */
const OrganizationSearch = ({ onOrganization }) => {
  const [keyword, setKeyword] = useState("");
  const [orgResults, setOrgResults] = useState([]);

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    if (str.length <= 0) return "";
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Queries organizations by name from firebase
   */
  useEffect(() => {
    let isNotCanceled = true;
    const capitalized = _.startCase(_.toLower(keyword));
    const limitString = constructLimitString(capitalized);
    // construct query based on capitalized length
    let q;
    if (capitalized.length === 0) {
      q = query(
        collection(db, largeCustomerCollectionPath),
        orderBy("name"),
        limit(5),
      );
    } else {
      q = query(
        collection(db, largeCustomerCollectionPath),
        where("name", ">=", capitalized),
        where("name", "<", limitString),
        orderBy("name"),
        limit(5),
      );
    }
    if (isNotCanceled) {
      (async () => {
        const orgs = await getDocs(q);
        const orgDocs = [];
        orgs.forEach(doc => {
          orgDocs.push({ key: doc.id, ...doc.data() });
        });
        if (isNotCanceled) setOrgResults(orgDocs);
      })();
    }
    return () => {
      isNotCanceled = false;
    };
  }, [keyword, constructLimitString, setOrgResults]);

  return (
    <>
      <TextField
        fullWidth={true}
        value={keyword}
        label="Hae organisaatiota"
        placeholder="Hakusana"
        onChange={e => setKeyword(e.target.value)}
      />
      {orgResults.map((org, index) => {
        return (
          <React.Fragment key={org.key}>
            <div className="shadow-sm p-1 d-flex flex-row align-items-center justify-content-between">
              <p className="mb-0">{org.name}</p>
              <button
                className="btn btn-primary"
                onClick={() => onOrganization(org)}>
                Valitse
              </button>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default OrganizationSearch;
