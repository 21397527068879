import React, { useState, useCallback } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
} from "@material-ui/core";
import SortableHeader from "../largeCustomers/SortableHeader";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { itemTranslations } from "./TransactionStates";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";

const columns = [
  { label: "" },
  { label: "Päivämäärä", property: "updatedAt" },
  { label: "Status", property: "state" },
  { label: "Viimeisin muokkaaja", property: "logUser" },
  { label: "Toimitusaika-arvio", property: "offerStartDate" },
  { label: "Hinta", property: "offerPrice" },
  { label: "" },
];

const TransactionTable = ({ transactions }) => {
  const history = useHistory();

  const [sortConfig, setSortConfig] = useState({
    propertyName: "updatedAt",
    sortType: SortingType.Descending,
  });

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        // flip sorting type
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, transactions);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const [checkedItems, setCheckedItems] = useState([]);
  const onRowClick = (item, index) => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.key === item.key,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  if (!transactions) {
    return null;
  } else if (_.isEmpty(transactions)) {
    return null;
  }

  const renderOfferCells = transaction => {
    if (transaction.offer && !_.isEmpty(transaction.offer)) {
      const _offer = transaction.offer;
      const startDate = moment(_offer.startDate);
      const endDate = moment(_offer.endDate);
      return (
        <>
          <TableCell>
            {startDate.isValid() ? startDate.format("DD.MM.YYYY") : ""}
            {endDate.isValid() ? " - " + endDate.format("DD.MM.YYYY") : ""}
          </TableCell>
          <TableCell>{_offer.price} €</TableCell>
        </>
      );
    }
    return (
      <>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </>
    );
  };

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );

              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((transaction, index) => {
              return (
                <TableRow
                  key={"tx" + index}
                  hover
                  onClick={event => {
                    if (
                      event.nativeEvent.target.id === "transactionOpenButton"
                    ) {
                      return;
                    }
                    onRowClick(transaction);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(
                          item => item.key === transaction.key,
                        ) > -1
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {moment.unix(transaction.updatedAt).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>{itemTranslations[transaction.state]}</TableCell>
                  {/* last editor cell */}
                  <TableCell>
                    {transaction.logUser} {transaction.logTime}
                  </TableCell>
                  {renderOfferCells(transaction)}
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: "/tilaus",
                        search: "?id=" + transaction.key,
                        transaction: transaction,
                      });
                    }}>
                    <button
                      id="transactionOpenButton"
                      className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default TransactionTable;
