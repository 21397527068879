import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getStorage } from "@firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBU0kCmF9esLOXPum7kIXTYjsTxgpHGKKw",
  authDomain: "asfaltti-beta.firebaseapp.com",
  databaseURL: "https://asfaltti-beta.firebaseio.com",
  projectId: "asfaltti-beta",
  storageBucket: "asfaltti-beta.appspot.com",
  messagingSenderId: "207019949244",
  appId: "1:207019949244:web:2515c2c761bd27d6c8e4b9",
  measurementId: "G-0DD1BGM1JG",
};

/**
 * Initialized firebase app
 */
const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp, "europe-west1");

const pdfStorage = () => getStorage(firebaseApp, "gs://asfaltti-beta-pdfs");
const workOrderStorrage = () => getStorage(firebaseApp, "gs://asfaltti-tt");

export default firebaseApp;
export { functions, pdfStorage, workOrderStorrage };
