import React from "react";
import { HashRouter as Router, Switch } from "react-router-dom";
import App from "./App";
// initialize firebase app
import "./firebase/firebaseApp";
import store from "./store/store";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fi";
import moment from "moment";

moment.locale("fi");

const RouterApp = () => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={"fi"}>
        <Router>
          <Switch>
            <App />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default RouterApp;
