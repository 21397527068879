import React, { useState, useEffect } from "react";
import { COMPLETED } from "../transaction/TransactionStates";
import TotalStatBox from "./TotalStatBox";
import _ from "lodash";
import { Box } from "@material-ui/core";

const TotalStats = ({ targets, transactions }) => {
  const [stats, setStats] = useState({
    offersTotal: 0,
    soldTotal: 0,
    offerMean: 0,
  });
  const [targetsTotal] = useState(targets.length);

  useEffect(() => {
    const { offTotal, sTotal, offerCount } = transactions.reduce(
      (obj, tx) => {
        if (_.isNil(tx.offer)) return obj;
        if (tx.state > COMPLETED) {
          obj.sTotal += tx.offer.price;
          obj.offTotal += tx.offer.price;
        } else {
          obj.offTotal += tx.offer.price;
        }
        obj.offerCount += 1;
        return obj;
      },
      {
        offTotal: 0,
        sTotal: 0,
        offerCount: 0,
      },
    );
    let mean = 0;
    if (offTotal > 0 && offerCount > 0) {
      mean = Math.floor(offTotal / offerCount);
    }

    setStats({
      offersTotal: offTotal,
      soldTotal: sTotal,
      offerMean: mean,
    });
  }, [transactions, setStats]);

  return (
    <>
      <TotalStatBox
        title="Tarjousten arvo yhteensä"
        number={stats.offersTotal}
        suffix="€"
      />
      <Box m={2} />
      <TotalStatBox
        title="Myynti yhteensä"
        number={stats.soldTotal}
        suffix="€"
      />
      <Box m={2} />
      <TotalStatBox
        title="Tarjouksen keskihinta"
        number={stats.offerMean}
        suffix="€"
      />
      <Box m={2} />
      <TotalStatBox title="Kohteita yhteensä" number={targetsTotal} />
    </>
  );
};

export default TotalStats;
