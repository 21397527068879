import _ from "lodash";
import moment from "moment";
import { getAuth } from "@firebase/auth";
import {
  db,
  firebaseApp,
  userCollectionPath,
  largeCustomerTargetPath,
} from "../../firebase";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";

const logEvents = {
  LOG_CREATE: "CreateEvent",
  LOG_UPDATE: "UpdateEvent",
  LOG_REMOVE: "RemoveEvent",
  LOG_TX_UPDATE: "UpdateChildTransaction",
};

/**
 * Appens a log entry to a firebaseDocument.
 * Computes differences of oldValues and newValues into a new object
 * called changedValues to the log entry, if both are defined.
 * @param {*} logEvent Type of log entry, see logEvents above
 * @param {*} oldValues values before an update (or null if create)
 * @param {*} newValues values after update
 * @param {*} firebaseDocument any firebase document, actually just an object.
 * @param {*} userInfo details about the user making a change, object containing userId and name or email if no name
 */
const appendLog = (
  logEvent,
  oldValues,
  newValues,
  firebaseDocument,
  userInfo,
) => {
  // validate arguments
  if (_.isNil(logEvent)) {
    throw new Error("Define log event.");
  } else if (_.isNil(firebaseDocument)) {
    throw new Error("Define firebase document for logging.");
  } else if (_.isNil(userInfo) || _.isEmpty(userInfo)) {
    throw new Error(
      "Define user data for logging, at least uid and name fields are expected.",
    );
  } else if (_.isNil(userInfo.uid) || _.isNil(userInfo.name)) {
    throw new Error("Define uid and name for userInfo for logging.");
  }

  // get changed values if necessary
  let changedValues;
  if (_.isNil(oldValues) || _.isNil(newValues)) {
    changedValues = null;
  } else {
    changedValues = diff(oldValues, newValues);
  }
  const logEntry = {
    event: logEvent,
    changedValues: changedValues,
    user: userInfo,
    time: moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  const logs = _.isNil(firebaseDocument.log) ? [] : [...firebaseDocument.log];
  logs.push(logEntry);
  return { ...firebaseDocument, log: logs };
};

/**
 * Append a targets log entry when updating a transaction
 */
const updateTargetLogByTransaction = async targetKey => {
  const targetRef = doc(collection(db, largeCustomerTargetPath), targetKey);
  const targetDoc = await getDoc(targetRef);
  const targetData = targetDoc.data();
  const loggedTarget = appendLog(
    logEvents.LOG_TX_UPDATE,
    null,
    null,
    targetData,
    await currentUserLogInfo(),
  );
  await updateDoc(targetRef, loggedTarget);
};

/**
 * Returns an object containing differences between two objects, example:
 * oldValues = {x: 1},
 * newValues = {x: 2},
 * changedValues = {x: {old: 1, new: 2}}
 * @param {*} oldValues
 * @param {*} newValues
 * @returns
 */
const diff = (oldValues, newValues) => {
  return _.transform(newValues, (result, value, key) => {
    if (!_.isEqual(value, oldValues[key])) {
      result[key] = { old: oldValues[key], new: value };
    }
  });
};

/**
 * Attempts to get the current users information
 * for logging purposes, returns an object containing uid and name fields.
 * Sometimes a user does not have a name defined, in these cases we'll use
 * the users email instead.
 * @returns
 */
const currentUserLogInfo = async () => {
  const auth = getAuth(firebaseApp);
  const userDoc = await getDoc(
    doc(collection(db, userCollectionPath), auth.currentUser.uid),
  );
  const userData = userDoc.data();
  return {
    uid: userData.uid,
    // use the name if it's defined, other wise use email if that is defined
    // and lastly just use the UID as a backup
    name: userData.name
      ? userData.name
      : userData.email
      ? userData.email
      : userData.uid,
  };
};

/**
 * Returns the latest log entry in a firebase document, or null if not defined
 * @param {*} firebaseDocument
 * @returns
 */
const lastLogEntry = firebaseDocument => {
  if (!_.isNil(firebaseDocument) && !_.isNil(firebaseDocument.log)) {
    const lastLog = _.last(firebaseDocument.log);
    return lastLog;
  }
  return null;
};

export {
  logEvents,
  appendLog,
  currentUserLogInfo,
  lastLogEntry,
  updateTargetLogByTransaction,
};
