import { getAuth, signOut } from "@firebase/auth";

const LogoutButton = () => {
  return (
    <a
      href="#"
      style={{ alignSelf: "flex-end", float: "right" }}
      onClick={() => {
        const auth = getAuth();
        signOut(auth);
      }}>
      Kirjaudu ulos
    </a>
  );
};

export default LogoutButton;
