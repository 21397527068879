/**
 * Simple loading indicator component
 * @returns
 */
const Loading = () => {
  return (
    <div className="spinner-grow text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Loading;
