import { Box } from "@material-ui/core";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { db, userCollectionPath } from "../../firebase";
import EditIcon from "../EditIcon";
import { contactEmail, contactPhoneNumber } from "../../functions";
import _ from "lodash";

/**
 * Renders information about a Target document
 * @returns
 */
const TargetInfo = ({
  target,
  classes = "",
  showUpdateModal,
  contacts,
  setContacts,
}) => {
  const { client } = useSelector(state => state.largeCustomer);

  useEffect(() => {
    if (!target) return;
    (async () => {
      const contactIds = target.contacts ? target.contacts : [target.contact];
      const contactDocs = [];
      for await (const contactId of contactIds) {
        const docRef = doc(db, userCollectionPath, contactId);
        const docSnap = await getDoc(docRef);
        contactDocs.push({ key: docSnap.id, ...docSnap.data() });
      }
      setContacts(contactDocs);
    })();
  }, [target, setContacts]);

  const renderContact = contact => {
    return (
      <div className="contact" key={contact.uid}>
        <Box m={1} />
        <p>{contact.name}</p>
        <Box m={1} />
        <p>{contactPhoneNumber(contact)}</p>
        <Box m={1} />
        <p>{contactEmail(contact)}</p>
      </div>
    );
  };

  return (
    <div className={"col target-info info-container text-center " + classes}>
      <div className="d-flex flex-row flex-wrap justify-content-evenly align-items-center">
        <Box m={1} />
        <h3>{target.name}</h3>
        {_.isNil(showUpdateModal) ? (
          <Box m={1} />
        ) : (
          <EditIcon
            onClick={() => {
              //console.log("Target edit icon press");
              showUpdateModal();
            }}
          />
        )}
      </div>
      <p>{target.address}</p>
      <p>
        {target.postalCode} {target.postalDistrict}
      </p>
      <Box m={0.5} />
      <p>Y-tunnus:</p>
      <p>{client.y_id}</p>
      <Box m={0.5} />
      <p>Suurasiakas</p>
      <Box m={2} />
      <div className=" d-flex flex-row flex-wrap justify-content-evenly align-items-center">
        <Box m={1} />
        <h3>Yhteyshenkilöt</h3>
        <Box m={1} />
      </div>
      {contacts &&
        contacts.map((contact, index) => {
          return renderContact(contact, index);
        })}
    </div>
  );
};

export default TargetInfo;
