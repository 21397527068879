import {
  locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_crop_locale_en_gb,
  markup_editor_locale_en_gb,
} from "pintura";

const locale = {
  // spread all defaults
  ...locale_en_gb,
  ...plugin_crop_locale_en_gb,
  ...plugin_annotate_locale_en_gb,
  ...markup_editor_locale_en_gb,
  // begin overrides
  // tools
  shapeLabelToolSharpie: "Kynä",
  shapeLabelToolEraser: "Kumi",
  shapeLabelToolRectangle: "Neliö",
  shapeLabelToolEllipse: "Ympyrä",
  shapeLabelToolArrow: "Nuoli",
  shapeLabelToolLine: "Viiva",
  shapeLabelToolText: "Teksti",
  shapeLabelToolPreset: "Tarra",
  // side menu
  annotateLabel: "Piirrä",
  cropLabel: "Rajaa",

  // sizes
  labelSizeExtraSmall: "Pienin",
  labelSizeSmall: "Pieni",
  labelSizeMediumSmall: "Pienempi",
  labelSizeMedium: "Keskikokoinen",
  labelSizeMediumLarge: "Suurempi",
  labelSizeLarge: "Suuri",
  labelSizeExtraLarge: "Suurin",

  labelButtonUndo: "Kumoa",
  labelButtonRedo: "Tee uudelleen",
  labelButtonExport: "Valmis",
  statusLabelButtonClose: "Sulje",
};

export default locale;
