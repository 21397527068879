import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "@firebase/firestore";
import { useEffect, useState } from "react";
import { db, largeCustomerTransactionPath } from "../../firebase";
import _ from "lodash";
import { latestTargetDocs } from "../util/TargetUtils";

const useTargetStatus = targets => {
  const [targetStateMap, setTargetStateMap] = useState({});
  const [targetIds, setTargetIds] = useState([]);
  // kind of lazy way to do a refetch on interval,
  // see useEffect hook with setInterval.
  const [refetch, setRefetch] = useState(false);

  /**
   * Extract target Ids from targets
   */
  useEffect(() => {
    if (!targets) return;
    const ids = [];
    targets.forEach(target => ids.push(target.key));
    if (!_.isEqual(ids, targetIds)) {
      setTargetIds(ids);
    }
  }, [targets, targetIds]);

  /**
   * This is part of the lazy way to do a refetch every 30 seconds,
   * we just need a state variable that changes, which will be in the dependencies
   * of the useEffect hook that actually does the fetching.
   */
  useEffect(() => {
    const intr = setInterval(() => {
      //console.log("Refetching statuses");
      setRefetch(!refetch);
    }, 30000);
    return () => clearInterval(intr);
  }, [setRefetch, refetch]);

  /**
   * Fetch the latest transaction for each target, and get its state
   */
  useEffect(() => {
    let isNotCanceled = true;

    // at least 1 id is required for an "in" query for firebase
    // besides, there's nothing to fetch if we have 0 IDs...
    if (targetIds.length === 0) {
      setTargetStateMap({});
      return;
    }

    const txRef = collection(db, largeCustomerTransactionPath);

    // "in" queries are limited to 10 per query, so we need to batch the IDs
    const idBatches = _.chunk(targetIds, 10);

    let batches = [];
    // for every batch of 10 or less IDs, create a promise
    // which fetches the documents
    for (const idBatch of idBatches) {
      const q = query(
        txRef,
        where("target", "in", idBatch),
        orderBy("updatedAt", "desc"),
      );
      batches.push(
        new Promise(response => {
          getDocs(q).then(results =>
            response(
              results.docs.map(doc => {
                return { key: doc.id, ...doc.data() };
              }),
            ),
          );
        }),
      );
    }

    // resolve all batches
    Promise.all(batches).then(content => {
      // flatten array of arrays into a single array
      const fetchedDocs = content.flat();

      // sort docs so that unique targets are right after each other, and the latest
      // updated tx is the last one. doing this we can easily find the latest doc in
      // the next step
      const sortedDocs = _.orderBy(
        fetchedDocs,
        ["target", "updatedAt"],
        ["asc", "desc"],
      );

      const latestDocsForTargets = latestTargetDocs(sortedDocs);

      // map target ids to a state value
      const states = {};
      for (const latestDoc of latestDocsForTargets) {
        states[latestDoc.target] = latestDoc.state;
      }

      if (isNotCanceled) {
        setTargetStateMap(states);
      }
    });

    return () => {
      isNotCanceled = false;
    };
  }, [targetIds, setTargetStateMap, refetch]);

  return targetStateMap;
};

export default useTargetStatus;
