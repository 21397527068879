const CORNER_ROCK_DAMAGE = "Reunakivivauriot";
const MANHOLE_DAMAGE = "Kansistovauriot";
const ROAD_SIGN_DAMAGE = "Liikennemerkkivauriot";
const ASPHALT_DAMAGE = "Asfalttivauriot";
const PAINT_DAMAGE = "Maalausvauriot";

const tabLabels = {
  [CORNER_ROCK_DAMAGE]: "Reunakivet",
  [MANHOLE_DAMAGE]: "Kansistot",
  [ROAD_SIGN_DAMAGE]: "Liikennemerkit",
  [ASPHALT_DAMAGE]: "Asfalttivauriot",
  [PAINT_DAMAGE]: "Maalausvauriot",
};

const classifications = [
  CORNER_ROCK_DAMAGE,
  MANHOLE_DAMAGE,
  ROAD_SIGN_DAMAGE,
  ASPHALT_DAMAGE,
  PAINT_DAMAGE,
];

export {
  classifications,
  CORNER_ROCK_DAMAGE,
  MANHOLE_DAMAGE,
  ROAD_SIGN_DAMAGE,
  ASPHALT_DAMAGE,
  PAINT_DAMAGE,
  tabLabels,
};
