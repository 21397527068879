import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Box } from "@material-ui/core";
import Loading from "../Loading";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db, largeCustomerReportPath } from "../../firebase";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import _ from "lodash";

const ReportModal = ({ transactionDocument, visible = false, onHide }) => {
  const [report, setReport] = useState(null);
  const [reportImageURLs, setReportImageURLs] = useState(null);
  const [extraWorkImageURLs, setExtraWorkImageURLs] = useState(null);

  /**
   * Fetches the report document
   */
  useEffect(() => {
    //const ref = doc(db, largeCustomerReportPath, transactionDocument.key);
    const q = query(
      collection(db, largeCustomerReportPath),
      where("transaction", "==", transactionDocument.key),
      limit(1),
    );
    getDocs(q)
      .then(docs => {
        // query is limited for 1 document
        docs.forEach(doc => {
          console.log("Report key", doc.id);
          setReport({ key: doc.id, ...doc.data() });
        });
      })
      .catch(err => {
        console.log("Error while fetching report document", err);
      });
  }, [transactionDocument, setReport]);

  /**
   * Resolves report images
   */
  useEffect(() => {
    if (report === null) return;
    // fetch report images
    (async () => {
      const urls = [];
      const storage = getStorage();
      for await (const imageKey of report.pictures) {
        const url = await getDownloadURL(ref(storage, imageKey));
        urls.push(url);
      }
      setReportImageURLs(urls);
    })();
  }, [report, setReportImageURLs]);

  /**
   * Resolves extra work images
   */
  useEffect(() => {
    if (report === null) return;
    if (!report.extraWork) return;
    // fetch report images
    (async () => {
      const urls = [];
      const storage = getStorage();
      for await (const imageKey of report.extraWork.pictures) {
        const url = await getDownloadURL(ref(storage, imageKey));
        urls.push(url);
      }
      setExtraWorkImageURLs(urls);
    })();
  }, [report, setExtraWorkImageURLs]);

  const thumbnails = useMemo(() => {
    if (reportImageURLs === null) return [];
    return reportImageURLs.map((url, index) => {
      return (
        <React.Fragment key={url}>
          <div className="thumbnail" style={{ marginRight: "5%" }}>
            <div className="thumbnail-inner">
              <img src={url} alt="" />
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [reportImageURLs]);

  const extraWorkThumbnails = useMemo(() => {
    if (extraWorkImageURLs === null) return [];
    return extraWorkImageURLs.map((url, index) => {
      return (
        <React.Fragment key={url}>
          <div className="thumbnail" style={{ marginRight: "5%" }}>
            <div className="thumbnail-inner">
              <img src={url} alt="" />
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [extraWorkImageURLs]);

  const projectTotalPrice = useMemo(() => {
    let price = Number(transactionDocument.offer.price);
    if (report && report.extraWork) {
      price += Number(report.extraWork.price);
    }
    return price;
  }, [report, transactionDocument]);

  if (report === null) {
    return (
      <Modal show={visible} onHide={onHide}>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <Loading />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <div className="text-center">
          <h4>Työraportti</h4>
        </div>
        <Box m={2} />
        <p className="input-title">Korjauksen tiedot</p>
        <p className="mb-0">{report.shortReport}</p>
        <Box m={2} />
        <p className="input-title">Kuvat</p>
        <div className="d-flex flex-row flex-wrap justify-content-center">
          {thumbnails}
        </div>
        {report.extraWork && (
          <>
            <Box m={3} />
            <div>
              <h4>Lisätyöt</h4>
            </div>
            <Box m={2} />
            <p className="input-title">Lisätöiden hinta</p>
            <p className="">{report.extraWork.price} €</p>
            <p className="input-title">Lisätöiden tiedot</p>
            <p className="mb-0">{report.extraWork.shortReport}</p>
            <Box m={2} />
            <p className="input-title">Kuvat</p>
            <div className="d-flex flex-row flex-wrap justify-content-center">
              {extraWorkThumbnails}
            </div>
          </>
        )}

        <p className="mb-0">
          <span>Asfalttityön hinta</span>
          {": "}
          {transactionDocument.offer.price} €
        </p>
        <p className="mb-0">
          <span>Lisätöiden hinta</span>
          {": "}
          {report.extraWork ? report.extraWork.price : 0} €
        </p>
        <p>
          <strong>
            <span>Kokonaishinta</span>
            {": "} {projectTotalPrice} €
          </strong>
        </p>

        <Box m={2} />
        {!_.isNil(report.feedback) && (
          <>
            <p className="input-title">Palaute</p>
            <p className="mb-0">{report.feedback.feedback}</p>
            <p className="mb-0">{report.feedback.score}/5</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
