import { Box } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";

const ClientRemoveConfirmationModal = ({
  visible = false,
  onConfirm,
  onCancel,
  checkedCount,
}) => {
  return (
    <Modal show={visible} onHide={onCancel}>
      <Modal.Body>
        <div className="text-center">
          <p>
            Haluatko varmasti poistaa valitut ({checkedCount} kpl) asiakkaat
          </p>
          <div className="d-flex flex-row justify-content-center">
            <button className="btn btn-primary" onClick={onConfirm}>
              Kyllä
            </button>
            <Box m={1} />
            <button className="btn btn-secondary" onClick={onCancel}>
              Peruuta
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClientRemoveConfirmationModal;
