import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Box, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { setClient } from "../../route/admin/largeCustomerSlice";
import RemoveIcon from "../RemoveIcon";
import NewContact from "./NewContact";
import {
  contactEmail,
  contactPhoneNumber,
  validUserEmails,
} from "../../functions/contactUtils";
import EditIcon from "../EditIcon";
import AddButton from "../AddButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebaseApp";
import { collection, doc, setDoc } from "firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";
import Loading from "../Loading";
import { CLIENT } from "../user/userRoles";

/**
 * Renders a modal where the user can update clients (largeCustomers) information.
 * Editing contacts information happens with another modal.
 * @param {*} param0
 * @returns
 */
const ClientCreateModal = ({ visible = false, onHide }) => {
  const { client } = useSelector(state => state.largeCustomer);
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // flag to indicate if the user is adding a new contact
  const [addingNewContact, setAddingNewContact] = useState(false);
  // flag to indicate if the user is editing an already added contact
  const [editContact, setEditContact] = useState({ editing: false, index: -1 });
  const [submitError, setSubmitError] = useState("");

  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({
    name: "",
    emails: [{ email: "", switchState: false, label: "Työ" }],
    numbers: [],
    roles: [CLIENT],
  });

  /**
   * Clears error text when modal is hidden,
   * and sets addingNewContact state to false
   */
  useEffect(() => {
    if (visible === false) {
      setErrorText("");
      setAddingNewContact(false);
    }
  }, [visible]);

  /**
   * Validates client (largeCustomer) document fields
   * @param {*} clientData
   * @returns
   */
  const isValid = clientData => {
    if (_.isEmpty(clientData.name)) {
      setErrorText("Nimi ei voi olla tyhjä!");
      return false;
    } else if (_.isEmpty(clientData.address)) {
      setErrorText("Osoite ei voi olla tyhjä!");
      return false;
    } else if (_.isEmpty(clientData.postalCode)) {
      setErrorText("Postinumeri ei voi olla tyhjä!");
      return false;
    } else if (_.isEmpty(clientData.postalDistrict)) {
      setErrorText("Postitoimipaikka ei voi olla tyhjä!");
      return false;
    }
    return true;
  };

  /**
   * onSave handler, updates this clients document in firebase
   * @returns
   */
  const onSave = async () => {
    // clear errors
    setErrorText("");
    setSubmitError("");
    // exit early if already submitting
    if (submitting) return;

    const fields = [
      "address",
      "billing",
      "contacts",
      "key",
      "name",
      "postalCode",
      "postalDistrict",
      "y_id",
      "log",
    ];

    // if there's any extra fields created for sorting in the table
    // we will filter them out here
    const filteredClient = _.pick(client, fields);

    // validate client doc
    if (!isValid(filteredClient)) {
      return;
    }

    // flag as submitting
    setSubmitting(true);

    // pre-generate client document ID
    const newClientRef = doc(collection(db, largeCustomerCollectionPath));
    const newClientId = newClientRef.id;

    // validate user emails (check for duplicates)
    const userEmails = contacts.reduce((contactArr, contact) => {
      const mainEmail = contact.emails[0].email;
      contactArr.push(mainEmail);
      return contactArr;
    }, []);
    // handle unique email validation
    const validEmails = await validUserEmails(userEmails);
    if (!validEmails.valid) {
      setSubmitError("Sähköposti '" + validEmails.email + "' on jo käytössä");
      return;
    }

    /*else {
      console.log("emails valid", userEmails);
    }*/

    // create a reference to cloud function
    const createNewContact = httpsCallable(
      functions,
      "createLargeCustomerContact",
    );
    // collect generated user IDs
    const generatedUserIds = [];
    // create contact user documents
    for await (const contact of contacts) {
      // get username email
      const mainEmail = contact?.emails[0]?.email;
      if (!mainEmail) {
        setSubmitError(
          `Virheellinen käyttäjätunnus henkilöllä ${contact.name}`,
        );
        return;
      }
      // construct data object for cloud function
      const data = {
        sendEmail: true,
        ...contact,
        email: mainEmail,
        organization: newClientId,
      };
      // send request to cloud function to create a new contact
      try {
        /*
        example of response object:
        {
          data: {
            message: "New user created pHuIi80GGpgJHE6NLLu5Ybo9GV42",
            userId: "pHuIi80GGpgJHE6NLLu5Ybo9GV42"
          }
        }
        */
        const response = await createNewContact(data);
        // push generated ID into array
        generatedUserIds.push(response.data.userId);
      } catch (err) {
        console.log("Error while creating new contact", err);
        setSubmitError("Virhe käyttäjää luodessa " + err.message);
        setSubmitting(false);
        return;
      }
    }

    // update client document
    filteredClient.contacts = generatedUserIds;
    // upload document
    setDoc(newClientRef, filteredClient)
      .then(() => {
        setSubmitting(false);
        // clear contacts
        setContacts([]);
        // onHide hides the modal and clears client
        // by dispatch null to setClient
        onHide();
      })
      .catch(err => {
        setSubmitting(false);
        setSubmitError("Virhe asiakasta lähettäessä");
        console.log("Error while uploading client", err);
      });
  };

  const updateField = (value, field) => {
    const newClient = { ...client };
    newClient[field] = value;
    dispatch(setClient(newClient));
  };

  const updateBillingField = (value, field) => {
    const newClient = { ...client };
    const billing = { ...newClient.billing };
    billing[field] = value;
    newClient.billing = billing;
    dispatch(setClient(newClient));
  };

  if (!client) return null;

  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        {addingNewContact ? (
          <NewContact
            onNewContact={contactData => {
              const _contacts = [...contacts];
              // clear edit state if editing
              if (editContact.editing && editContact.index > -1) {
                _contacts.splice(editContact.index, 1);
                setEditContact({ editing: false, index: -1 });
              }
              _contacts.push(contactData);
              setContacts(_contacts);
              setAddingNewContact(false);
            }}
            onCancel={() => {
              // clear edit state if editing
              if (editContact.editing) {
                setEditContact({ editing: false, index: -1 });
              }

              setAddingNewContact(false);
            }}
            contact={contact}
            setContact={setContact}
            editContact={editContact}
          />
        ) : (
          <div className="client-update-modal">
            <div className="text-center">
              <h4>Luo uusi asiakas</h4>
            </div>
            <Box m={2} />
            <p className="input-title">Yleiset tiedot</p>
            <Box m={1} />
            <Box paddingLeft={1}>
              <TextField
                required
                fullWidth={true}
                label="Nimi"
                value={client.name}
                onChange={e => {
                  updateField(e.target.value, "name");
                }}
              />
              <TextField
                required
                fullWidth={true}
                label="Osoite"
                value={client.address}
                onChange={e => {
                  updateField(e.target.value, "address");
                }}
              />
              <TextField
                required
                fullWidth={true}
                label="Postinumero"
                value={client.postalCode}
                onChange={e => {
                  updateField(e.target.value, "postalCode");
                }}
              />
              <TextField
                required
                fullWidth={true}
                label="Postitoimipaikka"
                value={client.postalDistrict}
                onChange={e => {
                  updateField(e.target.value, "postalDistrict");
                }}
              />
              <TextField
                fullWidth={true}
                label="Y-tunnus"
                value={client.y_id}
                onChange={e => updateField(e.target.value, "y_id")}
              />
            </Box>
            <Box m={2} />
            <p className="input-title">Laskutustiedot</p>
            <Box paddingLeft={1}>
              {/* billing information can be undefined */}
              <TextField
                fullWidth={true}
                label="Laskutusosoite"
                value={client.billing?.billingAddress ?? ""}
                onChange={e => {
                  updateBillingField(e.target.value, "billingAddress");
                }}
              />
              <TextField
                fullWidth={true}
                label="E-lasku osoite"
                value={client.billing?.eBilling ?? ""}
                onChange={e => {
                  updateBillingField(e.target.value, "eBilling");
                }}
              />
              <TextField
                fullWidth={true}
                label="E-lasku tarjoaja"
                value={client.billing?.eBillingProvider ?? ""}
                onChange={e => {
                  updateBillingField(e.target.value, "eBillingProvider");
                }}
              />
            </Box>
            <Box m={2} />
            <p className="input-title">Yhteyshenkilöt</p>
            <Box paddingLeft={1}>
              {/* render added contacts */}
              {contacts.map((contactData, index) => {
                return (
                  <div
                    key={"contactData" + index}
                    className="d-flex flex-column mt-2">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      {contactData.name}
                      <RemoveIcon
                        size={30}
                        onClick={() => {
                          const _contacts = [...contacts];
                          _contacts.splice(index, 1);
                          setContacts(_contacts);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="mb-0">{contactEmail(contactData)}</p>
                      <div className="me-2">
                        <EditIcon
                          size={15}
                          onClick={() => {
                            setContact(contactData);
                            setAddingNewContact(true);
                            setEditContact({ editing: true, index: index });
                          }}
                        />
                      </div>
                    </div>

                    <p className="mb-0">{contactPhoneNumber(contactData)}</p>
                  </div>
                );
              })}
              {!addingNewContact && (
                <AddButton
                  onClick={() => {
                    setContact({
                      name: "",
                      emails: [{ email: "", switchState: false, label: "Työ" }],
                      numbers: [],
                    });
                    setAddingNewContact(true);
                  }}>
                  Lisää uusi yhteyshenkilö
                </AddButton>
              )}
            </Box>
            <Box m={2} />
            {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
            {submitError.length > 0 && (
              <p className="text-danger">{submitError}</p>
            )}
            <div className="text-center">
              {/* show submit button if not submitting */}
              {!submitting && (
                <button className="btn btn-primary" onClick={onSave}>
                  TALLENNA
                </button>
              )}
              {submitting && <Loading />}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ClientCreateModal;
