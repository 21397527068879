import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LargeCustomer,
  LargeCustomerTarget,
  LargeCustomerTransaction,
} from "../../firebase/document-types";

interface FrontpageState {
  clients: LargeCustomer[];
  clientsFetched: boolean;
  targets: LargeCustomerTarget[];
  targetsFetched: boolean;
  transactions: LargeCustomerTransaction[];
  transactionsFetched: boolean;
}

const initialState: FrontpageState = {
  clients: [],
  clientsFetched: false,
  targets: [],
  targetsFetched: false,
  transactions: [],
  transactionsFetched: false,
};

/**
 * Slice that holds data for frontpage elements,
 * I decided to separate this because if the user navigates away
 * front the frontpage and comes back, we'd still have this data
 * available and it wouldn't have to be refetched.
 */
export const frontpageSlice = createSlice({
  name: "frontpage",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<LargeCustomer[]>) => {
      state.clients = action.payload;
    },
    markClientsFetched: (state, action: PayloadAction<boolean>) => {
      state.clientsFetched = action.payload;
    },
    removeClients: state => {
      state.clients = [];
    },
    setTargets: (state, action: PayloadAction<LargeCustomerTarget[]>) => {
      state.targets = action.payload;
    },
    markTargetsFetched: (state, action: PayloadAction<boolean>) => {
      state.targetsFetched = action.payload;
    },
    removeTargets: state => {
      state.targets = [];
    },
    setTransactions: (
      state,
      action: PayloadAction<LargeCustomerTransaction[]>,
    ) => {
      state.transactions = action.payload;
    },
    markTransactionsFetched: (state, action: PayloadAction<boolean>) => {
      state.transactionsFetched = action.payload;
    },
    removeTransactions: state => {
      state.transactions = [];
    },
  },
});

export const {
  setClients,
  setTargets,
  setTransactions,
  removeClients,
  removeTargets,
  removeTransactions,
  markClientsFetched,
  markTargetsFetched,
  markTransactionsFetched,
} = frontpageSlice.actions;

export default frontpageSlice.reducer;
