import { Box, FormControlLabel, TextField, Switch } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import Loading from "../Loading";
import { ADMIN, OFFICE_WORKER, WORKER } from "./userRoles";

/**
 * Renders a modal which can be used to create a new user or update user
 */
const UserUpdateModal = ({
  visible = false,
  user,
  setUser,
  onHide,
  title = "Muokkaa käyttäjän tietoja",
  onComplete,
  submitting = false,
  error = "",
}) => {
  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <div className="text-center">
          <h4>{title}</h4>
        </div>
        <Box m={2} />
        <TextField
          fullWidth={true}
          label="Nimi"
          value={user.name}
          onChange={e => setUser({ ...user, name: e.target.value })}
        />
        <TextField
          fullWidth={true}
          label="Sähköposti (käyttäjätunnus)"
          value={user.email}
          onChange={e => setUser({ ...user, email: e.target.value })}
        />
        <TextField
          fullWidth={true}
          label="Puhelinnumero"
          value={user.phoneNumber}
          onChange={e => setUser({ ...user, phoneNumber: e.target.value })}
        />
        <Box m={1} />
        <FormControlLabel
          label={"Admin"}
          control={
            <Switch
              onChange={e => {
                if (user.roles.includes(ADMIN)) {
                  const oldRoles = [...user.roles];
                  // keep all old roles, remove ADMIN role
                  const newRoles = oldRoles.filter(role => role !== ADMIN);
                  setUser({ ...user, roles: newRoles });
                } else {
                  const newRoles = [ADMIN, OFFICE_WORKER, WORKER];
                  setUser({ ...user, roles: newRoles });
                }
              }}
              checked={user.roles.includes(ADMIN)}
            />
          }
        />
        <Box m={1} />
        <FormControlLabel
          label={"Rajoitettu admin"}
          control={
            <Switch
              onChange={e => {
                if (user.roles.includes(OFFICE_WORKER)) {
                  const oldRoles = [...user.roles];
                  const rolesToFilter = [ADMIN, OFFICE_WORKER];
                  // keep all old roles, remove OFFICE_WORKER role
                  const newRoles = oldRoles.filter(
                    role => !rolesToFilter.includes(role),
                  );
                  setUser({ ...user, roles: newRoles });
                } else {
                  const newRoles = [OFFICE_WORKER, WORKER];
                  setUser({ ...user, roles: newRoles });
                }
              }}
              checked={user.roles.includes(OFFICE_WORKER)}
            />
          }
        />
        <Box m={1} />
        <FormControlLabel
          label={"Työntekijä"}
          control={
            <Switch
              onChange={e => {
                if (user.roles.includes(WORKER)) {
                  const oldRoles = [...user.roles];
                  const rolesToFilter = [ADMIN, OFFICE_WORKER, WORKER];
                  // keep all old roles, remove WORKER role
                  const newRoles = oldRoles.filter(
                    role => !rolesToFilter.includes(role),
                  );
                  setUser({ ...user, roles: newRoles });
                } else {
                  const newRoles = [WORKER];
                  setUser({ ...user, roles: newRoles });
                }
              }}
              checked={user.roles.includes(WORKER)}
            />
          }
        />
        <Box m={1} />
        <p>Vain admin käyttäjät näkevät etusivun taulukot</p>
        <p>Rajoitettu admin käyttäjä voi käyttää hallintapaneelia</p>
        <p>
          Työntekijä ei voi käyttää hallintapaneelia, ja on rajoitettu mobiili
          versiossa
        </p>
        <Box m={1} />
        {/* submit button section */}
        {error.length > 0 && <p className="text-danger">{error}</p>}
        <div className="d-flex">
          {!submitting && (
            <button
              className="btn btn-primary"
              onClick={() => {
                onComplete(user);
              }}>
              Tallenna
            </button>
          )}
          {submitting && <Loading />}
          {submitting && <p>Luomisessa voi kestää hetki.</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserUpdateModal;
