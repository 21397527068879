import { Box } from "@material-ui/core";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import UserTable from "../../components/user/UserTable";
import UserUpdateModal from "../../components/user/UserUpdateModal";
import { db, userCollectionPath } from "../../firebase";
import { functions } from "../../firebase/firebaseApp";

/**
 * Renders a table which shows admin users,
 * renders a modal which can be used to create a new user.
 * @returns
 */
const Users = () => {
  const [users, setUsers] = useState();

  const [newUser, setNewUser] = useState({
    email: "",
    phoneNumber: "",
    isAdmin: true,
    isWorker: true,
    roles: ["worker"],
  });
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  /**
   * Handler for creating a new admin user
   * @param {*} userData
   */
  const onNewUser = userData => {
    setSubmitError("");
    if (submitting) return;
    setSubmitting(true);
    console.log("onNewUser", userData);
    // create a reference to cloud function
    const createUser = httpsCallable(functions, "createUser");
    createUser(userData)
      .then(response => {
        console.log("Created new user", response);
        setSubmitting(false);
        setUserModalVisible(false);
        setNewUser({
          email: "",
          phoneNumber: "",
          isAdmin: true,
          isWorker: true,
          roles: ["office-worker", "worker"],
        });
      })
      .catch(err => {
        console.log("Error while creating user", err);
        setSubmitError("Virhe käyttäjää luodessa");
        setSubmitting(false);
      });
  };

  /**
   * Subscribes to user documents which have "isAdmin" defined as true in their data
   */
  useEffect(() => {
    const q = query(
      collection(db, userCollectionPath),
      where("isAdmin", "==", true),
    );
    const userUnSub = onSnapshot(
      q,
      docSnapshot => {
        const userDocs = [];
        docSnapshot.forEach(doc => {
          userDocs.push(doc.data());
        });
        setUsers(userDocs);
      },
      error => {
        console.warn("Error while fetching users", error);
      },
    );
    return () => {
      if (userUnSub) {
        userUnSub();
      }
    };
  }, [setUsers]);

  if (!users) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <UserUpdateModal
        user={newUser}
        setUser={setNewUser}
        onComplete={userData => {
          onNewUser(userData);
        }}
        onHide={() => setUserModalVisible(false)}
        visible={userModalVisible}
        title="Luo uusi käyttäjä"
        submitting={submitting}
        error={submitError}
      />
      <Box m={5} />
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-primary"
          onClick={() => {
            setUserModalVisible(true);
          }}>
          + HENKILÖ
        </button>
      </div>
      <Box m={2} />
      <UserTable users={users} />
    </>
  );
};

export default Users;
