import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { db, userCollectionPath } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import EditIcon from "../EditIcon";

const ClientInfo = ({ client, showUpdateModal, contacts, setContacts }) => {
  useEffect(() => {
    if (!client) return;
    let isCanceled = false;
    (async () => {
      const contactIds = client.contacts ?? [];
      const docs = [];
      for await (const contactId of contactIds) {
        const docRef = doc(db, userCollectionPath, contactId);
        const docSnap = await getDoc(docRef);
        docs.push({ key: docSnap.id, ...docSnap.data() });
      }
      if (!isCanceled) setContacts(docs);
    })();
    return () => {
      isCanceled = true;
    };
  }, [client, setContacts]);

  const renderContact = (contact, index) => {
    return (
      <div className="contact" key={"contact" + index}>
        <p>{contact.name}</p>
      </div>
    );
  };

  return (
    <div className="col client-info info-container text-center">
      <div
        className="icon-title d-flex flex-row flex-wrap justify-content-evenly align-items-center"
        style={{ marginBottom: "10px" }}>
        <Box m={1} />
        <h3 className="d-flex flex-shrink">{client.name}</h3>

        <EditIcon
          size={24}
          onClick={() => {
            // open ClientUpdateModal
            showUpdateModal();
          }}
        />
      </div>

      <p>{client.address}</p>
      <p>
        {client.postalCode} {client.postalDistrict}
      </p>
      <Box m={0.5} />
      <p>Y-tunnus:</p>
      <p>{client.y_id}</p>
      <Box m={0.5} />
      <p>Suurasiakas</p>
      <Box m={2} />
      <div className="icon-title d-flex flex-row flex-wrap justify-content-evenly align-items-center">
        <Box m={1} />
        <h3>Yhteyshenkilöt</h3>
        {/*}
        <EditIcon
          size={20}
          onClick={() => {
            console.log("Contact edit icon press");
          }}
        />*/}
        <Box m={1} />
      </div>
      {contacts &&
        contacts.map((contact, index) => {
          return renderContact(contact, index);
        })}
    </div>
  );
};

export default ClientInfo;
