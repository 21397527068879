import { createSlice } from "@reduxjs/toolkit";
import { LargeCustomerTarget } from "../../firebase/document-types";

interface TargetState {
  allTargets: LargeCustomerTarget[];
  filteredAllTargets: LargeCustomerTarget[];
  targets: LargeCustomerTarget[];
  target: LargeCustomerTarget | null;
}

const initialState: TargetState = {
  // all targets from firebase (Kohteet page)
  allTargets: [],
  filteredAllTargets: [],
  // all targets of a specific client from firebase
  targets: [],
  // single target
  target: null,
};

export const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    removeTargets: state => {
      state.targets = [];
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    removeTarget: state => {
      state.target = null;
    },
    setAllTargets: (state, action) => {
      state.allTargets = action.payload;
      state.filteredAllTargets = action.payload;
    },
    setAllFilteredTargets: (state, action) => {
      state.filteredAllTargets = action.payload;
    },
    removeAllTargets: state => {
      state.allTargets = [];
    },
  },
});

export const {
  setTargets,
  setTarget,
  removeTargets,
  removeTarget,
  setAllTargets,
  removeAllTargets,
  setAllFilteredTargets,
} = targetSlice.actions;

export default targetSlice.reducer;
