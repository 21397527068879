import React, { useState, useEffect, useMemo, useCallback } from "react";
import TargetTable from "../target/TargetTable";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  collection,
  doc,
  onSnapshot,
  where,
  query,
  updateDoc,
  deleteDoc,
} from "@firebase/firestore";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  userCollectionPath,
} from "../../firebase";
import qs from "qs";
import { setTargets } from "../../route/admin/targetSlice";
import useTargetStatus from "../target/useTargetStatus";
import { lastLogEntry } from "../logging/logUtils";
import moment from "moment";
import Loading from "../Loading";
import ContactInfo from "./ContactInfo";
import ContactUpdateModal from "./ContactUpdateModal";
import { setClient } from "../../route/admin/largeCustomerSlice";
import ContactRemoveConfirmationModal from "../client/ContactRemoveConfirmationModal";

const ContactDetails = ({ location }) => {
  const [contact, setContact] = useState();
  const { targets } = useSelector(state => state.target);
  const { client } = useSelector(state => state.largeCustomer);
  const dispatch = useDispatch();
  const [editedTargets, setEditedTargets] = useState();
  const [checkedItems, setCheckedItems] = useState([]);

  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [contactSubmitting, setContactSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [contactRemoveConfirmShown, setContactRemoveConfirmShown] =
    useState(false);

  /**
   * Gets the current contacts ID from either locations contact field
   * or parses it from query parameters.
   */
  const contactId = useMemo(() => {
    const contactData = location?.contact;
    if (contactData) {
      return contactData.uid;
    } else {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const id = queryParams.id;
      if (!id) {
        console.warn("Contact ID was not defined!");
        return null;
      }
      return id;
    }
  }, [location]);

  /**
   * Subscribes to the contact document
   */
  useEffect(() => {
    // return early if contactId is not defined
    if (!contactId) return;
    const unSub = onSnapshot(
      doc(db, userCollectionPath, contactId),
      docSnapshot => {
        setContact(docSnapshot.data());
      },
      error => {
        console.warn("Error while subscribing to contact data", error);
      },
    );
    return () => {
      unSub();
    };
  }, [setContact, contactId]);

  /**
   * Subscribes to this contacts target documents
   */
  useEffect(() => {
    // return early if contact id isn't defined
    if (_.isNil(contactId)) return;
    const targetQuery = query(
      collection(db, largeCustomerTargetPath),
      where("contact", "==", contactId),
    );
    const targetUnSub = onSnapshot(
      targetQuery,
      docSnapshot => {
        const targetDocs = [];

        docSnapshot.forEach(doc => {
          targetDocs.push({ key: doc.id, ...doc.data() });
        });
        dispatch(setTargets(targetDocs));
      },
      error => {
        console.warn("Error while subcribing to contacts targets", error);
      },
    );
    return () => {
      targetUnSub();
    };
  }, [dispatch, contactId]);

  /**
   * Subscribes to this contacts client document.
   * Used to show organizations name in ContactUpdateModal.
   */
  useEffect(() => {
    if (_.isNil(contact)) return;

    let clientUnSub;
    // only actually subscribe if orgnaization is defined, otherwise
    // we'll get an error
    if (contact.organization) {
      // contacts organization field can be the organization object instead of the key, since
      // ContactUpdateModal is used for both creating a new contact and updating an existing contact.
      // we need to use the key for the query or we'll get an error.
      const orgKey = _.isString(contact.organization)
        ? contact.organization
        : contact.organization.key;
      const clientQuery = query(doc(db, largeCustomerCollectionPath, orgKey));
      clientUnSub = onSnapshot(
        clientQuery,
        docSnapshot => {
          dispatch(setClient({ key: docSnapshot.id, ...docSnapshot.data() }));
        },
        error => {
          console.warn("Error while subscribing to client document", error);
        },
      );
    }

    return () => {
      if (clientUnSub) {
        clientUnSub();
      }
    };
  }, [contact, dispatch]);

  /**
   * Resolves targets latest transactions status
   */
  const targetStateMap = useTargetStatus(targets);

  const removeContact = useCallback(() => {
    if (!contact) {
      console.warn("Tried to remove contact but contact doesn't exist!");
      return;
    }
    const contactRef = doc(db, userCollectionPath, contact.uid);
    deleteDoc(contactRef);
  }, [contact]);

  /**
   * Edits targets by adding extra fields to them from custom hooks
   */
  useEffect(() => {
    const editedTargets = [];
    for (const target of targets) {
      const lastLog = lastLogEntry(target);
      const latestState = targetStateMap[target.key];
      const editedTarget = { ...target, latestState };
      if (!_.isNil(lastLog)) {
        editedTarget.logUser = lastLog.user.name;
        editedTarget.logTime = moment(lastLog.time).format("DD.MM.YYYY");
      }
      editedTargets.push(editedTarget);
    }
    setEditedTargets(editedTargets);
  }, [targets, targetStateMap, setEditedTargets]);

  /**
   * ContactUpdateModal onComplete handler, updates contact data
   * to firebase.
   * @param {*} contactData
   * @returns
   */
  const onUpdateContact = contactData => {
    // since ContactUpdateModal is used for both updating and creating a contact
    // the organization can be the organization object instead of just the key.
    // we can check if organization is just a string, and if not we can assume it's the object,
    // in which case we'll get the key from the object and assign it to the organization field.
    if (!_.isString(contactData.organization)) {
      contactData.organization = contactData.organization.key;
    }
    if (contactSubmitting) return;
    setContactSubmitting(true);
    const contactRef = doc(db, userCollectionPath, contactData.uid);
    updateDoc(contactRef, contactData)
      .then(() => {
        setContactSubmitting(false);
        setContactModalVisible(false);
      })
      .catch(error => {
        console.log("Error while updating contact", error);
        setSubmitError("Virhe päivittäessä tietoja");
        setContactSubmitting(false);
      });
  };

  // return loading indicator of contact isn't defined
  if (_.isNil(contact)) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <ContactRemoveConfirmationModal
        visible={contactRemoveConfirmShown}
        onCancel={() => setContactRemoveConfirmShown(false)}
        onConfirm={() => removeContact()}
      />
      <ContactUpdateModal
        contact={contact}
        setContact={setContact}
        visible={contactModalVisible}
        onHide={() => setContactModalVisible(false)}
        onComplete={updatedContact => {
          onUpdateContact(updatedContact);
        }}
        title="Muokkaa yhteyshenkilöä"
        client={client}
        submitErrorText={submitError}
        submitting={contactSubmitting}
      />
      <div className="d-flex flex-row justify-content-start mt-2">
        <button
          onClick={() => setContactRemoveConfirmShown(true)}
          className="btn btn-primary">
          Poista
        </button>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <ContactInfo
            contact={contact}
            onEditContact={() => setContactModalVisible(true)}
          />
        </div>
        <div className="col-xs-12 col-lg-8">
          <TargetTable
            statesFetched={!_.isEmpty(targetStateMap)}
            targets={editedTargets ?? targets}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
