import React, { useState, useCallback } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SortingType } from "../sort/SortingTypes";
import useSortedRows from "../sort/useSortedRows";
import SortableHeader from "../largeCustomers/SortableHeader";
import Loading from "../Loading";
import _ from "lodash";
import { defaultRowsPerPage, rowsPerPageOptions } from "../util/tableUtil";

const columns = [
  { label: "" },
  { label: "Nimi", property: "name" },
  { label: "Organisaatio", property: "organizationName" },
  { label: "Sähköposti", property: "mainEmail" },
  { label: "Puhelin", property: "mainPhoneNumber" },
  { label: "Avoimet kohteet", property: "openTargets" },
  { label: "Valmiit kohteet", property: "closedTargets" },
];

/**
 * Renders contacts in a sortable table
 * @param {*} contacts array of User documents from firebase
 * @returns
 */
const ContactTable = ({ contacts }) => {
  const history = useHistory();
  const [sortConfig, setSortConfig] = useState({
    propertyName: "name",
    sortType: SortingType.Descending,
  });

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const sortBy = useCallback(
    propertyName => {
      let sortType = SortingType.Descending;
      if (sortConfig.propertyName === propertyName) {
        // flip sorting type
        sortType =
          sortConfig.sortType === SortingType.Ascending
            ? SortingType.Descending
            : SortingType.Ascending;
      }
      setSortConfig({ propertyName: propertyName, sortType });
    },
    [sortConfig],
  );

  const sortedRows = useSortedRows(sortConfig, contacts ?? []);

  const [checkedItems, setCheckedItems] = useState([]);
  const onRowClick = (item, index) => {
    const checked = [...checkedItems];
    // find item from checkedItems
    const foundItemIndex = checked.findIndex(
      checkedItem => checkedItem.key === item.key,
    );
    // if item was found remove it, and find
    // the index of the checkedIndex, and remove that as well
    if (foundItemIndex > -1) {
      // remove
      checked.splice(foundItemIndex, 1);
      setCheckedItems(checked);
    } else {
      // add
      checked.push(item);
      setCheckedItems(checked);
    }
  };

  /**
   * Renders contacts organization name if it's resolved
   * @param {*} contact
   * @returns
   */
  const renderOrganizationName = contact => {
    if (!contact.organizationName) return <Loading />;
    return contact.organizationName ?? "";
  };

  /**
   * Renders contacts open target count if it's resolved
   * @param {*} contact
   * @returns
   */
  const renderOpenCount = contact => {
    if (_.isNil(contact.openTargets)) return <Loading />;
    return contact.openTargets ?? "";
  };

  /**
   * Renders contacts closed target count if it's resolved
   * @param {*} contact
   * @returns
   */
  const renderClosedCount = contact => {
    if (_.isNil(contact.closedTargets)) return <Loading />;
    return contact.closedTargets ?? "";
  };

  const allChecked = useCallback(() => {
    const pageSlice = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    // rows per page can be larger and the number of items in a slice of a pagination.
    return (
      checkedItems.length ===
      (rowsPerPage === pageSlice.length ? rowsPerPage : pageSlice.length)
    );
  }, [sortedRows, rowsPerPage, checkedItems, page]);

  return (
    <>
      <Table>
        <SortableHeader
          columns={columns}
          sortBy={sortBy}
          sortConfig={sortConfig}
          checkAll={() => {
            if (checkedItems.length > 0) {
              setCheckedItems([]);
            } else {
              const itemsInPage = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              );

              setCheckedItems(itemsInPage);
            }
          }}
          allChecked={allChecked()}
        />
        <TableBody>
          {sortedRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((contact, index) => {
              return (
                <TableRow
                  key={"contact" + index}
                  hover
                  onClick={event => {
                    if (event.nativeEvent.target.id === "contactOpenButton") {
                      return;
                    }
                    onRowClick(contact);
                  }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        checkedItems.findIndex(
                          item => item.key === contact.key,
                        ) > -1
                      }
                    />
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>{renderOrganizationName(contact)}</TableCell>
                  <TableCell>{contact.mainEmail}</TableCell>
                  <TableCell>{contact.mainPhoneNumber}</TableCell>
                  <TableCell>{renderOpenCount(contact)}</TableCell>
                  <TableCell>{renderClosedCount(contact)}</TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: "/yhteystieto",
                        search: "?id=" + contact.key,
                        contact: contact,
                      });
                    }}>
                    <button id="contactOpenButton" className="btn btn-light">
                      AVAA
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, newPage) => {
          setPage(newPage);
          setCheckedItems([]);
        }}
        onChangeRowsPerPage={e => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
};

export default ContactTable;
