import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  doc,
} from "@firebase/firestore";
import {
  db,
  largeCustomerCollectionPath,
  userCollectionPath,
} from "../../firebase";
import ContactTable from "../../components/contact/ContactTable";
import { contactEmail, contactPhoneNumber } from "../../functions";
import useOrganizationNameResolver from "../../components/contact/useOrganizationNameResolver";
import useContactTargetCount from "../../components/contact/useContactTargetCount";
import { Box } from "@material-ui/core";
import ContactUpdateModal from "../../components/contact/ContactUpdateModal";
import { functions } from "../../firebase/firebaseApp";
import { httpsCallable } from "firebase/functions";

/**
 * "Yhteyshenkilöt" view
 * @returns
 */
const Contacts = () => {
  const [contacts, setContacts] = useState();
  const [editedContacts, setEditedContacts] = useState();

  const [newContact, setNewContact] = useState({
    emails: [{ email: "", switchState: false, label: "Työ" }],
    numbers: [],
    isAdmin: false,
    name: "",
    organization: null,
  });
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [contactSubmitting, setContactSubmitting] = useState(false);
  const [contactSubmitError, setContactSubmitError] = useState("");

  /**
   * Sends a request to Firbase Cloud Function to create a new contact
   * @param {*} contact Contact data
   * @param {*} contact flag indicating if we should send an email to the contact about their new account
   */
  const createNewContact = (contact, sendEmail) => {
    if (contactSubmitting) return;
    setContactSubmitError("");
    setContactSubmitting(true);
    console.log("Creating new contact", contact);
    const createContact = httpsCallable(
      functions,
      "createLargeCustomerContact",
    );
    // the cloud function expects the following data:
    // sendEmail, a flag indicating if we should send a notification email to the user
    // about their new account
    //
    // email: the username email for the new user, this should always be the 0th index
    // in the emails array of the contact in our case.
    //
    // organization: key (or ID) of the organization (largeCustomer) firebase document
    //
    // name: name of the user,
    //
    // numbers: phone numbers for the user, can be an empty array
    const data = {
      sendEmail: sendEmail,
      ...contact,
      email: contact.emails[0].email,
      organization: contact.organization.key,
    };

    createContact(data)
      .then(response => {
        /* example of response object:
        {
          data: 
            {
              message: "New user created pHuIi80GGpgJHE6NLLu5Ybo9GV42",
              userId: "pHuIi80GGpgJHE6NLLu5Ybo9GV42"
            }
        }
        */
        // add contact ID to largeCustomers document
        const userId = response.data.userId;
        // update organization contacts array
        const orgContacts = [...contact.organization.contacts];
        orgContacts.push(userId);
        const orgRef = doc(
          db,
          largeCustomerCollectionPath,
          contact.organization.key,
        );
        updateDoc(orgRef, { contacts: orgContacts })
          .then(() => {
            setContactSubmitting(false);
            setContactModalVisible(false);
            // clear form
            setNewContact({
              emails: [{ email: "", switchState: false, label: "Työ" }],
              numbers: [],
              isAdmin: false,
              name: "",
              organization: null,
            });
          })
          .catch(error => {
            console.log("Error while updating organization contacts", error);
            setContactSubmitError("Virhe kontakteja päivittäessä");
            setContactSubmitting(false);
          });
      })
      .catch(error => {
        console.log("Error while creating new contact", error);
        if (error.message.includes("auth/email-already-exists")) {
          setContactSubmitError("Sähköposti on jo käytössä!");
        } else {
          setContactSubmitError("Virhe käyttäjää luodessa " + error.message);
        }
        setContactSubmitting(false);
      });
  };

  /**
   * Subscribes to user documents which have "organization" defined in their data
   */
  useEffect(() => {
    const q = query(
      collection(db, userCollectionPath),
      orderBy("organization"),
    );

    const contactUnSub = onSnapshot(
      q,
      docSnapshot => {
        const contactDocs = [];
        docSnapshot.forEach(doc => {
          const data = doc.data();
          const mainEmail = contactEmail(data);
          const mainPhoneNumber = contactPhoneNumber(data);
          contactDocs.push({
            key: doc.id,
            ...data,
            mainEmail: mainEmail,
            mainPhoneNumber: mainPhoneNumber,
          });
        });
        setContacts(contactDocs);
      },
      error => {
        console.warn("Error while fetching contacts", error);
      },
    );
    return () => {
      contactUnSub();
    };
  }, []);

  const organizationMap = useOrganizationNameResolver(contacts);

  const contactCountMap = useContactTargetCount(contacts);

  /**
   * When organizationMap resolves, edit contact documents by adding
   * an organizationName field to them
   */
  useEffect(() => {
    if (!organizationMap) return;
    const edited = [];
    contacts.forEach(contact => {
      const editedContact = { ...contact };
      if (organizationMap) {
        editedContact.organizationName =
          organizationMap[contact.organization]?.name;
      }
      if (contactCountMap) {
        editedContact.openTargets = contactCountMap[contact.uid]?.open;
        editedContact.closedTargets = contactCountMap[contact.uid]?.closed;
      }
      edited.push(editedContact);
    });
    setEditedContacts(edited);
  }, [organizationMap, contactCountMap, contacts, setEditedContacts]);

  if (!contacts) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <ContactUpdateModal
        contact={newContact}
        setContact={setNewContact}
        visible={contactModalVisible}
        onHide={() => setContactModalVisible(false)}
        onComplete={(contact, sendEmail) =>
          createNewContact(contact, sendEmail)
        }
        submitting={contactSubmitting}
        submitErrorText={contactSubmitError}
      />
      <Box m={5} />
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-primary"
          onClick={() => {
            setContactModalVisible(true);
          }}>
          + HENKILÖ
        </button>
      </div>
      <Box m={2} />
      <ContactTable contacts={editedContacts ?? contacts} />
    </>
  );
};

export default Contacts;
