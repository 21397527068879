import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import _ from "lodash";
import RemoveIcon from "../RemoveIcon";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db, largeCustomerCollectionPath } from "../../firebase";
import Loading from "../Loading";
import AddButton from "../AddButton";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Renders a modal which can be used to update a contacts information/create new contact
 * @returns
 */
const ContactUpdateModal = ({
  contact,
  setContact,
  visible = false,
  onHide,
  onComplete,
  submitting,
  submitErrorText,
  title = "Uusi yhteyshenkilö",
  client = null,
}) => {
  // validation errors
  const [errorText, setErrorText] = useState("");
  // email validation error indices
  const [emailErrorIndices, setEmailErrorIndices] = useState([]);
  // phone number validation error indices
  const [numberErrorIndices, setNumberErrorIndices] = useState([]);
  // autocomplete keyword
  const [keyword, setKeyword] = useState("");

  const [orgResults, setOrgResults] = useState([]);
  // flag to indicate whether or not the new contact will recieve an email abotu their
  // new account when submitting
  const [sendNewContactEmail, setSendNewContactEmail] = useState(true);

  /**
   * Submit contact handler
   * @param {*} contact Contact data
   * @param {*} sendEmail flag indicating if we should send email about the account
   */
  const submit = (contact, sendEmail) => {
    setErrorText("");
    console.log("Validating & submitting contact");
    if (isValid(contact)) {
      onComplete(contact, sendEmail);
    }
  };

  /**
   * Validates contact object.
   * At least organization and name should be defined.
   * @param {*} contact
   * @returns
   */
  const isValid = contact => {
    if (_.isNil(contact.organization)) {
      setErrorText("Organisaatio ei voi olla tyhjä!");
      return false;
    } else if (_.isEmpty(contact.name)) {
      setErrorText("Nimi ei voi olla tyhjä!");
      return false;
    }
    // validate email email at index 0 (user name email)
    const mainEmail = contact.emails[0];
    if (!emailRegex.test(mainEmail.email)) {
      setErrorText("Tarkista käyttäjätunnus sähköpostiosoite!");
      return false;
    }
    return true;
  };

  /**
   * Validates an email address using regex
   * Updates emailErrorIndices state to keep track of incorrect emails
   * @param {*} email
   * @param {*} index
   */
  const validateEmail = (email, index) => {
    // push this index to email errors if validation fails
    if (!emailRegex.test(email)) {
      if (!_.includes(emailErrorIndices, index)) {
        setEmailErrorIndices([...emailErrorIndices, index]);
      }
    } else {
      // remove this index from errors if validation passes
      if (_.includes(emailErrorIndices, index)) {
        setEmailErrorIndices(_.filter(emailErrorIndices, index));
      }
    }
  };

  /**
   * Validates a phone number, makes sure it starts with a "+".
   * Updates numberErrorIndices state to keep track of incorrect numbers.
   * @param {*} number
   * @param {*} index
   */
  const validateNumber = (number, index) => {
    if (!_.startsWith(number, "+")) {
      if (!_.includes(numberErrorIndices, index)) {
        setNumberErrorIndices([...numberErrorIndices, index]);
      }
    } else {
      if (_.includes(numberErrorIndices, index)) {
        setNumberErrorIndices(_.filter(numberErrorIndices, index));
      }
    }
  };

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    if (str.length <= 0) return "";
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Queries organizations by name from firebase
   */
  useEffect(() => {
    let isCanceled = false;
    const capitalized = _.startCase(_.toLower(keyword));
    const limitString = constructLimitString(capitalized);
    let q;
    if (capitalized.length === 0) {
      q = query(
        collection(db, largeCustomerCollectionPath),
        orderBy("name"),
        limit(5),
      );
    } else {
      q = query(
        collection(db, largeCustomerCollectionPath),
        where("name", ">=", capitalized),
        where("name", "<", limitString),
        orderBy("name"),
        limit(5),
      );
    }

    if (!isCanceled) {
      (async () => {
        const orgs = await getDocs(q);
        const orgDocs = [];
        orgs.forEach(doc => {
          orgDocs.push({ key: doc.id, ...doc.data() });
        });
        if (!isCanceled) setOrgResults(orgDocs);
      })();
    }
    return () => {
      isCanceled = true;
    };
  }, [keyword, setOrgResults, constructLimitString]);

  return (
    <Modal show={visible} onHide={onHide}>
      <Modal.Body>
        <div className="text-center">
          <h4 className="mb-0">{title}</h4>
        </div>
        <Box m={2} />
        {/* name section */}
        <TextField
          fullWidth={true}
          value={contact.name}
          label="Nimi"
          onChange={e => {
            setContact({ ...contact, name: e.target.value });
          }}
        />
        {/* organization section */}
        {/* if organization is not defined, show a textfield which can be used as
          an autocomplete search, to search for organizations by name */}
        {_.isNil(contact.organization) && (
          <>
            <TextField
              fullWidth={true}
              value={keyword}
              label="Hae organisaatiota"
              placeholder="Hakusana"
              onChange={e => {
                setKeyword(e.target.value);
              }}
            />
            <Box m={2} />
            <div className="shadow">
              {orgResults.map((organization, index) => {
                return (
                  <React.Fragment key={organization.key}>
                    <div className="shadow-sm p-1 d-flex flex-row align-items-center justify-content-between">
                      <p className="mb-0">{organization.name}</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setContact({
                            ...contact,
                            organization: organization,
                          });
                        }}>
                        Valitse
                      </button>
                    </div>
                    <Box m={1} />
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}
        {/* if organization is defined, display the organizations name
          in a disabled text field */}
        {!_.isNil(contact.organization) && (
          <div className="d-flex flex-row align-items-center">
            <TextField
              fullWidth={true}
              disabled={true}
              value={client?.name ?? contact.organization.name}
              label="Organisaatio"
            />
            <RemoveIcon
              size={30}
              onClick={() => {
                setContact({ ...contact, organization: null });
              }}
            />
          </div>
        )}
        <Box m={2} />
        {/* email section */}
        <p className="input-title">Sähköpostiosoitteet</p>
        <Box m={2} />
        {contact.emails?.map((emailObject, index) => {
          let hasError = _.includes(emailErrorIndices, index);
          return (
            <div className="d-flex flex-row" key={"email" + index}>
              <div className="d-flex flex-column flex-fill">
                {index === 0 && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <FormControlLabel
                      label={emailObject.label}
                      control={
                        <Switch
                          onChange={e => {
                            const emails = [...contact.emails];
                            const thisEmail = { ...emails[index] };
                            thisEmail.switchState = !thisEmail.switchState;
                            thisEmail.label =
                              thisEmail.switchState === true ? "Oma" : "Työ";
                            emails[index] = thisEmail;
                            setContact({ ...contact, emails: emails });
                          }}
                          checked={emailObject.switchState}
                        />
                      }
                    />
                    <p className="mb-0">Käyttäjätunnus</p>
                  </div>
                )}
                {index > 0 && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <FormControlLabel
                      label={emailObject.label}
                      control={
                        <Switch
                          onChange={e => {
                            const emails = [...contact.emails];
                            const thisEmail = { ...emails[index] };
                            thisEmail.switchState = !thisEmail.switchState;
                            thisEmail.label =
                              thisEmail.switchState === true ? "Oma" : "Työ";
                            emails[index] = thisEmail;
                            setContact({ ...contact, emails: emails });
                          }}
                          checked={emailObject.switchState}
                        />
                      }
                    />{" "}
                    <RemoveIcon
                      size={30}
                      onClick={() => {
                        const emails = [...contact.emails];
                        emails.splice(index, 1);
                        // remove this email from the emails array
                        setContact({ ...contact, emails });
                        // also remove error index if it's there
                        if (_.includes(emailErrorIndices, index)) {
                          setEmailErrorIndices(
                            _.filter(emailErrorIndices, index),
                          );
                        }
                      }}
                    />
                  </div>
                )}
                <TextField
                  error={hasError}
                  helperText={hasError ? "Tarkista sähköposti" : ""}
                  fullWidth={true}
                  value={emailObject.email}
                  label="Sähköposti"
                  placeholder="matti@meikalainen.fi"
                  onChange={e => {
                    const emails = [...contact.emails];
                    const thisEmail = { ...emails[index] };
                    // push this index to email errors if validation fails
                    validateEmail(e.target.value, index);
                    thisEmail.email = e.target.value;
                    emails[index] = thisEmail;
                    setContact({ ...contact, emails });
                  }}
                />
                <Box m={2} />
              </div>
            </div>
          );
        })}
        <AddButton
          onClick={() => {
            const emails = [...contact.emails];
            emails.push({
              label: "Työ",
              email: "",
              switchState: false,
            });
            setContact({ ...contact, emails: emails });
          }}>
          Lisää sähköposti
        </AddButton>
        <Box m={2} />
        {/* phone number section */}
        <p className="input-title">Puhelinnumerot</p>
        <Box m={2} />
        {contact.numbers?.map((numberObject, index) => {
          let hasError = _.includes(numberErrorIndices, index);
          return (
            <div className="d-flex flex-row" key={"number" + index}>
              <div className="d-flex flex-column flex-fill">
                <FormControlLabel
                  label={numberObject.label}
                  control={
                    <Switch
                      onChange={e => {
                        const numbers = [...contact.numbers];
                        const thisNumber = numbers[index];
                        thisNumber.switchState = !thisNumber.switchState;
                        thisNumber.label =
                          thisNumber.switchState === true ? "Oma" : "Työ";
                        numbers[index] = thisNumber;
                        setContact({ ...contact, numbers });
                      }}
                      checked={numberObject.switchState}
                    />
                  }
                />
                <TextField
                  error={hasError}
                  helperText={
                    hasError
                      ? "Puhelinnumero tulee olla muodossa +358123123"
                      : ""
                  }
                  value={numberObject.number}
                  label="Puhelinnumero"
                  placeholder="+358123123"
                  onChange={e => {
                    const numbers = [...contact.numbers];
                    const thisNumber = numbers[index];
                    // push this numbers index to number errors if validation fails
                    validateNumber(e.target.value, index);
                    thisNumber.number = e.target.value;
                    numbers[index] = thisNumber;
                    setContact({ ...contact, numbers });
                  }}
                />
                <Box m={2} />
              </div>
              <RemoveIcon
                size={30}
                onClick={() => {
                  const numbers = [...contact.numbers];
                  numbers.splice(index, 1);
                  setContact({ ...contact, numbers });
                }}
              />
            </div>
          );
        })}
        <AddButton
          onClick={() => {
            const numbers = [...contact.numbers];
            numbers.push({
              label: "Työ",
              number: "",
              switchState: false,
            });
            setContact({ ...contact, numbers });
          }}>
          Lisää numero
        </AddButton>
        <Box m={2} />
        <FormControlLabel
          label="Käyttäjälle ilmoitus tilistä (vain luonnista)"
          control={
            <Checkbox
              checked={sendNewContactEmail}
              onChange={e => {
                setSendNewContactEmail(e.target.checked);
              }}
            />
          }
        />
        <div className="text-center">
          {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
          {submitErrorText && submitErrorText.length > 0 && (
            <p className="text-danger">{submitErrorText}</p>
          )}
          {!submitting && (
            <button
              className="btn btn-primary"
              onClick={() => submit(contact, sendNewContactEmail)}>
              Tallenna
            </button>
          )}
          {submitting && (
            <>
              <p className="mb-0">Tallennetaan. Luomisessa voi kestää hetki</p>
              <Loading />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUpdateModal;
