import { TableHead, TableRow, TableCell, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#F5F6FA",
  },
}))(TableCell);

/**
 * We could use sortConfig to render an indicator indicating the current sort direction
 * @param {*} sortBy Callback to sort the table
 * @param {*} sortConfig Current sort config, indicating which column is sorted and how
 * @param {*} columns Columns of the table, array of Objects.
 * Expects to find at least "label" property in each object
 * @returns
 */
const FrontpageSortableHeader = ({ sortBy, sortConfig, columns }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          if ("property" in column) {
            return (
              <StyledTableCell
                key={index}
                onClick={() => sortBy(column.property)}>
                <Typography>{column.label}</Typography>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell key={index}>
                <Typography>{column.label}</Typography>
              </StyledTableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default FrontpageSortableHeader;
