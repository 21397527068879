import { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import Loading from "../components/Loading";

// map of some common errors, with the correct error message for the user
const loginErrors = {
  "auth/invalid-email": "Tarkista sähköposti",
  "auth/wrong-password": "Väärä salasana",
  "auth/user-not-found": "Käyttäjää ei löydy",
  "auth/too-many-requests": "Liian monta yritystä",
};

const Login = ({ loginError = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorText, setErrorText] = useState("");

  if (submitting) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }
  return (
    <div className="max-h-centered-container">
      <div className="login-container shadow">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Sähköposti
          </label>
          <input
            onChange={event => {
              const val = event.target.value;
              setEmail(val);
            }}
            type="email"
            className="form-control"
            id="email"
            placeholder="matti@meikalainen.fi"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Salasana
          </label>
          <input
            onChange={event => {
              const val = event.target.value;
              setPassword(val);
            }}
            type="password"
            className="form-control"
            id="password"
          />
        </div>
        {errorText.length > 0 && <p className="text-danger">{errorText}</p>}
        {loginError.length > 0 && <p className="text-danger">{loginError}</p>}
        <button
          className="btn btn-primary"
          onClick={() => {
            setSubmitting(true);
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password)
              .then(() => {
                setSubmitting(false);
              })
              .catch(error => {
                setSubmitting(false);
                console.log("Code", error.code);
                const errorMsg = loginErrors[error.code] ?? "Tuntematon virhe";

                setErrorText(errorMsg);
              });
          }}>
          Kirjaudu
        </button>
      </div>
    </div>
  );
};

export default Login;
