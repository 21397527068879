import { useState, useMemo, useEffect } from "react";
import {
  CORNER_ROCK_DAMAGE,
  MANHOLE_DAMAGE,
  ROAD_SIGN_DAMAGE,
  ASPHALT_DAMAGE,
  PAINT_DAMAGE,
  tabLabels,
} from "./imageClassifications";
import StyledTab from "../tabs/StyledTab";
import StyledTabs from "../tabs/StyledTabs";
import React from "react";
import ThumbnailEditIcon from "../ThumbnailEditIcon";
import ThumbnailRemoveIcon from "../ThumbnailRemoveIcon";
import _ from "lodash";
import AngleLeftIcon from "../AngleLeftIcon";
import AngleRightIcon from "../AngleRightIcon";
import RejectedIcon from "./RejectedIcon";

const ALL = "Kaikki kuvat";
const classifications = [
  ALL,
  tabLabels[ASPHALT_DAMAGE],
  tabLabels[ROAD_SIGN_DAMAGE],
  tabLabels[CORNER_ROCK_DAMAGE],
  tabLabels[MANHOLE_DAMAGE],
  tabLabels[PAINT_DAMAGE],
];

const tabMap = {
  1: tabLabels[ASPHALT_DAMAGE],
  2: tabLabels[ROAD_SIGN_DAMAGE],
  3: tabLabels[CORNER_ROCK_DAMAGE],
  4: tabLabels[MANHOLE_DAMAGE],
  5: tabLabels[PAINT_DAMAGE],
};

const IMAGE_PAGE_SIZE = 3;

const iconLeft = {
  paddingBottom: "0px",
  paddingTop: "10px",
};

const iconRight = {
  paddingBottom: "0px",
  paddingTop: "10px",
};

const ClassifiedImages = ({
  images,
  onRemoveImage,
  onEditImage,
  onClickImage,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  // image carousel page
  const [filePage, setFilePage] = useState(0);

  /**
   * Selects correct image array or arrays (tab === 0) based on selectedTab
   */
  const selectedImages = useMemo(() => {
    if (selectedTab === 0) {
      return Object.keys(images).reduce((imageArray, classificationKey) => {
        return _.concat(imageArray, images[classificationKey]);
      }, []);
    } else {
      return images[tabMap[selectedTab]] ?? [];
    }
  }, [selectedTab, images]);

  /**
   * Resets image page to 0 if images array changes
   */
  useEffect(() => {
    setFilePage(0);
  }, [images]);

  /**
   *
   * @param {*} image Classified image object from Firebase, with added downloadURL field
   * @param {*} index
   * @returns
   */
  const renderImage = (image, index) => {
    return (
      <React.Fragment key={"classifiedImage" + index}>
        <div className="thumbnail">
          <div className="thumbnail-inner" style={{ position: "relative" }}>
            {image?.rejected && <RejectedIcon />}
            <img
              src={image.downloadURL ?? image.imageURL}
              onClick={() => onClickImage(image)}
              alt=""
            />
          </div>
          <div className="d-flex flex-row mt-1">
            <ThumbnailEditIcon onClick={() => onEditImage(image, index)} />
            <div className="mx-1" />
            <ThumbnailRemoveIcon onClick={() => onRemoveImage(image, index)} />
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div style={iconLeft}>
          <AngleLeftIcon
            size={30}
            onClick={() => {
              if (filePage > 0) {
                setFilePage(filePage - 1);
              }
            }}
          />
        </div>
        <div className="classified-images">
          <StyledTabs
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            variant="fullWidth"
            value={selectedTab}
            onChange={(e, value) => {
              // change tab and reset pagination
              setSelectedTab(value);
              setFilePage(0);
            }}>
            {classifications.map((classification, index) => {
              return <StyledTab key={classification} label={classification} />;
            })}
          </StyledTabs>

          <div className="d-flex flex-row align-items-center">
            <div
              className={
                selectedImages.length > 2
                  ? "d-flex justify-content-between"
                  : "d-flex"
              }>
              {selectedImages
                .slice(filePage, filePage + IMAGE_PAGE_SIZE)
                .map((image, index) => {
                  return renderImage(image, index);
                })}
            </div>
          </div>
        </div>
        <div style={iconRight}>
          <AngleRightIcon
            size={30}
            onClick={() => {
              if (filePage < selectedImages.length - IMAGE_PAGE_SIZE) {
                setFilePage(filePage + 1);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ClassifiedImages;
