import React, { useEffect, useState, useCallback } from "react";
import qs from "qs";
import { useSelector, useDispatch } from "react-redux";
import { setClient } from "../../route/admin/largeCustomerSlice";
import { setTarget, setTargets } from "../../route/admin/targetSlice";
import {
  onSnapshot,
  doc,
  collection,
  where,
  query,
  orderBy,
  limit,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
} from "@firebase/firestore";
import {
  db,
  largeCustomerCollectionPath,
  largeCustomerTargetPath,
  largeCustomerTransactionPath,
  userCollectionPath,
} from "../../firebase";
import { Box } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import ClientInfo from "./ClientInfo";
import TargetTable from "../target/TargetTable";
import useTargetStatus from "../target/useTargetStatus";
import _ from "lodash";
import ClientUpdateModal from "./ClientUpdateModal";
import TargetUpdateModal from "../target/TargetUpdateModal";
import moment from "moment";
import { addDoc } from "firebase/firestore";
import {
  appendLog,
  currentUserLogInfo,
  lastLogEntry,
  logEvents,
} from "../logging/logUtils";
import TargetImportModal from "../target/TargetImportModal";
import {
  CREATED,
  REPORTED,
  REPORT_CREATED,
  WAITING,
} from "../transaction/TransactionStates";
import MassFunctionResultModal from "../target/MassFunctionResultModal";
import { sendTransactionsPdfEmail } from "../transaction/transactionUtil";
import { contactEmail } from "../../functions";
import Loading from "../Loading";
import { downloadLatestOffers, targetsToExcelData } from "../target/targetUtil";
import MassOfferLinks from "../MassOfferLinks";
import XLSX from "xlsx";

const TAG = "[ClientDetails]";

const ClientDetails = ({ location }) => {
  const { client } = useSelector(state => state.largeCustomer);
  const { targets, target } = useSelector(state => state.target);
  const dispatch = useDispatch();

  const [contacts, setContacts] = useState();
  // client modal states
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  // target modal states
  const [targetModalVisible, setTargetModalVisible] = useState(false);
  const [targetSubmitting, setTargetSubmitting] = useState(false);
  const [targetSubmitError, setTargetSubmitError] = useState("");

  const [importModalVisible, setImportModalVisible] = useState(false);

  const [checkedItems, setCheckedItems] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [massOfferSubmitting, setMassOfferSubmitting] = useState(false);
  /**
   * errors: array of objects with "key" and "message" field.
   * key is the firebase document UID.
   * sent: array of firebase document UIDs
   */
  const [massSubmitResults, setMassSubmitResults] = useState({
    errors: [],
    sent: [],
  });
  const [showMassSubmitResults, setShowMassSubmitResults] = useState(false);

  const [massTxSubmitting, setMassTxSubmitting] = useState(false);
  const [massReportSubmitting, setMassReportSubmitting] = useState(false);
  const [massRemoveSubmitting, setMassRemoveSubmitting] = useState(false);
  const [massExcelSubmitting, setMassExcelSubmitting] = useState(false);

  // mass export latest offers to PDF & cloud
  // "vie korjausehdotukset" button
  const [massExportSubmitting, setMassExportSubmitting] = useState(false);
  const [massOfferLinks, setMassOfferLinks] = useState([]);

  /**
   * Sets client to the state, uses either the client
   * found in router location parameters, or loads it from firebase
   * by parsing the ID from URL query parameters
   */
  useEffect(() => {
    let clientUnSub;
    const client = location?.client;
    if (client) {
      dispatch(setClient(client));
    } else {
      // parse query params
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      //console.log("query params", queryParams);
      //console.log("ID:", queryParams.id);
      // get id
      const id = queryParams.id;
      if (!id) {
        console.warn("Client ID was not defined!");
        return;
      }
      // query largeCustomer based on ID
      clientUnSub = onSnapshot(
        doc(db, largeCustomerCollectionPath, id),
        docSnapshot => {
          const data = docSnapshot.data();
          const key = docSnapshot.id;
          dispatch(setClient({ key: key, ...data }));
        },
        error => {
          console.warn("Error while fetching client data", error);
        },
      );
    }
    return () => {
      if (clientUnSub) {
        clientUnSub();
      }
      // when user navigates away, remove client from redux
      dispatch(setClient(null));
    };
  }, [dispatch, location]);

  /**
   * Subscribe to target documents
   */
  useEffect(() => {
    if (!client) return;
    const key = client.key;

    const q = query(
      collection(db, largeCustomerTargetPath),
      where("organization", "==", key),
    );

    const unsub = onSnapshot(
      q,
      docSnapshot => {
        const targetDocs = [];
        docSnapshot.forEach(doc => {
          const data = doc.data();
          const lastLog = lastLogEntry(data);
          let targetDoc = {
            key: doc.id,
            ...data,
            // combine address with postalCode and district
            wholeAddress:
              data.address + ", " + data.postalCode + " " + data.postalDistrict,
          };
          // add last editor and last edited time if this document
          // has log entries
          if (!_.isNil(lastLog)) {
            targetDoc.logUser = lastLog.user.name;
            targetDoc.logTime = moment(lastLog.time).format("DD.MM.YYYY");
          }
          targetDocs.push(targetDoc);
        });
        dispatch(setTargets(targetDocs));
      },
      error => {
        console.warn("Error while fetching targets", error);
      },
    );
    return () => {
      unsub();
    };
  }, [client, dispatch]);

  const targetStateMap = useTargetStatus(targets);

  /**
   * Mass operation to send created offers to all eligible selections
   */
  const massSendOffer = useCallback(() => {
    if (massOfferSubmitting) return;
    setMassSubmitResults({
      errors: [],
      sent: [],
    });
    setMassOfferSubmitting(true);
    (async () => {
      // map all transaction documents to target keys
      let targetTransactions = {};
      // map any errors that might happen for a selected target by key
      // errors include: no transactions found,
      // invalid transaction (no price, state too high, no offer object)
      // and PDF sending errors
      let targetErrors = {};
      for await (const checkedTarget of checkedItems) {
        //console.log("checked target", checkedTarget);
        const q = query(
          collection(db, largeCustomerTransactionPath),
          where("target", "==", checkedTarget.key),
          // fetch the newest transaction
          orderBy("updatedAt", "desc"),
          limit(1),
        );
        const docs = await getDocs(q);
        if (docs.size > 0) {
          // there'll only be 1 document, because we're limiting the query to 1
          docs.forEach(doc => {
            targetTransactions[checkedTarget.key] = {
              key: doc.id,
              ...doc.data(),
            };
          });
        } else {
          targetErrors[checkedTarget.key] = "Kohteella ei ole tilauksia";
        }
      }

      // map all valid transactions by target key
      let validTransactions = {};
      // validate each transaction
      for (const [targetKey, txDoc] of Object.entries(targetTransactions)) {
        if (txDoc.offer) {
          if (txDoc.state < WAITING) {
            if (txDoc.offer.price > 0) {
              validTransactions[targetKey] = txDoc;
            } else {
              if (!targetErrors.hasOwnProperty(targetKey)) {
                targetErrors[targetKey] =
                  "Viimeisimmässä tilauksessa ei ole hintaa";
              }
            }
          } else {
            if (!targetErrors.hasOwnProperty(targetKey)) {
              targetErrors[targetKey] = "Viimeisin tilaus on jo käsittelyssä";
            }
          }
        } else {
          if (!targetErrors.hasOwnProperty(targetKey)) {
            targetErrors[targetKey] =
              "Viimeisimmässä tilauksessa ei ole tarjousta";
          }
        }
      }

      let sent = [];
      for await (const [targetKey, validTxDoc] of Object.entries(
        validTransactions,
      )) {
        const currentTargetDoc = checkedItems.find(
          checkedTarget => checkedTarget.key === targetKey,
        );

        if (currentTargetDoc) {
          const contacts = currentTargetDoc.contacts
            ? currentTargetDoc.contacts
            : [currentTargetDoc.contact];
          for await (const contact of contacts) {
            // get contact of the target for the email address
            const contactDocSnap = await getDoc(
              doc(db, userCollectionPath, contact),
            );
            // if contact is not found, add error and skip this target
            if (!contactDocSnap.exists) {
              targetErrors[targetKey] =
                "Kohteen yhteyshenkilön tietoja ei löytynyt";
              continue;
            }

            const contactDoc = contactDocSnap.data();

            const resultObject = await sendTransactionsPdfEmail(
              currentTargetDoc,
              validTxDoc,
              contactEmail(contactDoc),
            );
            if (resultObject.success === true) {
              const docRef = doc(
                db,
                largeCustomerTransactionPath,
                validTxDoc.key,
              );
              await updateDoc(docRef, {
                state: WAITING,
              });
              if (!sent.includes(targetKey)) sent.push(targetKey);
            } else {
              console.warn(
                "[massSendOffer]",
                "Failed to send PDF to target",
                targetKey,
              );
              const returnedErrorMessage = resultObject.message ?? "";
              let errorMessage =
                "PDF lähetys epäonnistui, kartoitusta ei päivitetty.";
              if (returnedErrorMessage) {
                errorMessage += " Virhe: " + returnedErrorMessage;
              }
              targetErrors[targetKey] = errorMessage;
            }
          }
        } else {
          targetErrors[targetKey] =
            "PDF lähetys epäonnistui, kartoitusta ei päivitetty";
        }
      }

      const errors = Object.keys(targetErrors).map(key => {
        return { key: key, message: targetErrors[key] };
      });

      setMassSubmitResults({
        errors: errors,
        sent: sent,
      });

      setMassOfferSubmitting(false);
      setShowMassSubmitResults(true);
    })();
  }, [
    checkedItems,
    massOfferSubmitting,
    setMassOfferSubmitting,
    setMassSubmitResults,
    setShowMassSubmitResults,
  ]);

  /**
   * Mass operation to create a new TX for all selections
   */
  const massCreateNewTx = useCallback(() => {
    if (massTxSubmitting) return;
    setMassSubmitResults({
      errors: [],
      sent: [],
    });
    setMassTxSubmitting(true);
    console.log(checkedItems);
    // checkedItem here is a target document
    let promises = [];
    for (const checkedItem of checkedItems) {
      const newTx = {
        additionalInfo: "",
        log: [],
        offer: {},
        organization: checkedItem.organization,
        pictures: {
          classified: [],
          classifiedNotUploaded: [],
          unclassified: [],
        },
        state: CREATED,
        target: checkedItem.key,
        updatedAt: moment().unix(),
      };
      promises.push(
        addDoc(collection(db, largeCustomerTransactionPath), newTx),
      );
    }
    Promise.all(promises)
      .then(result => {
        setMassSubmitResults({
          sent: checkedItems,
          errors: [],
        });
        setMassTxSubmitting(false);
        setShowMassSubmitResults(true);
      })
      .catch(err => {
        console.warn("Error while mass creating TXs", err);
        setMassTxSubmitting(false);
      });
  }, [
    checkedItems,
    massTxSubmitting,
    setMassTxSubmitting,
    setShowMassSubmitResults,
  ]);

  const massSendReport = useCallback(() => {
    if (massReportSubmitting) return;
    setMassSubmitResults({
      errors: [],
      sent: [],
    });
    setMassReportSubmitting(true);
    //console.log(checkedItems);
    (async () => {
      // map all tx documents to target keys
      let targetTransactions = {};
      // map any errors that might happen for a selected target by key
      // errors include: no transactions found, invalid transaction (state too high)
      let targetErrors = {};
      for await (const checkedTarget of checkedItems) {
        const q = query(
          collection(db, largeCustomerTransactionPath),
          where("target", "==", checkedTarget.key),
          orderBy("updatedAt", "desc"),
          limit(1),
        );
        const docs = await getDocs(q);
        if (docs.size > 0) {
          docs.forEach(doc => {
            targetTransactions[checkedTarget.key] = {
              key: doc.id,
              ...doc.data(),
            };
          });
        } else {
          targetErrors[checkedTarget.key] = "Kohteella ei ole tilauksia";
        }
      }

      // map all valid transactions by target key
      let validTransactions = {};
      for (const [targetKey, txDoc] of Object.entries(targetTransactions)) {
        if (txDoc.state === REPORT_CREATED) {
          validTransactions[targetKey] = txDoc;
        } else {
          if (txDoc.state > REPORT_CREATED) {
            targetErrors[targetKey] = "Viimeisin tilaus on jo raportoitu";
          } else {
            targetErrors[targetKey] = "Viimeisin tilaus on vielä kesken";
          }
        }
      }

      let sent = [];
      for await (const [targetKey, validTxDoc] of Object.entries(
        validTransactions,
      )) {
        const docRef = doc(db, largeCustomerTransactionPath, validTxDoc.key);
        await updateDoc(docRef, { state: REPORTED });
        sent.push(targetKey);
      }
      const errors = Object.keys(targetErrors).map(key => {
        return { key: key, message: targetErrors[key] };
      });
      setMassSubmitResults({
        errors: errors,
        sent: sent,
      });
      setMassReportSubmitting(false);
      setShowMassSubmitResults(true);
    })();
  }, [
    checkedItems,
    setMassSubmitResults,
    setShowMassSubmitResults,
    massReportSubmitting,
    setMassReportSubmitting,
  ]);

  const massExportExcel = useCallback(() => {
    if (massExcelSubmitting) return;
    setMassExcelSubmitting(true);
    // get excel data
    targetsToExcelData(checkedItems)
      .then(data => {
        const workSheet = XLSX.utils.json_to_sheet(data);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Kohteet");
        XLSX.writeFile(workBook, "kohteet.xlsx");
        setMassExcelSubmitting(false);
      })
      .catch(err => {
        setMassExcelSubmitting(false);
        console.error("Error while exporting excel", err);
      });
  }, [checkedItems, setMassExcelSubmitting, massExcelSubmitting]);

  const massRemoveTarget = useCallback(() => {
    if (massRemoveSubmitting) return;
    setMassSubmitResults({
      errors: [],
      sent: [],
    });
    setMassRemoveSubmitting(true);
    (async () => {
      let deleted = [];
      for await (const checkedTarget of checkedItems) {
        await deleteDoc(doc(db, largeCustomerTargetPath, checkedTarget.key));
        deleted.push(checkedTarget.key);
      }
      setMassSubmitResults({
        errors: [],
        sent: deleted,
      });
      setMassRemoveSubmitting(false);
      setShowMassSubmitResults(true);
    })();
  }, [
    checkedItems,
    massRemoveSubmitting,
    setMassRemoveSubmitting,
    setMassSubmitResults,
    setShowMassSubmitResults,
  ]);

  /**
   * Attempts to add an extra field (transaction state) called
   * "latestState" to all target documents, data is fetched
   * using useTargetStatus. This field can be later sorted in the table.
   */
  useEffect(() => {
    if (_.isEmpty(targetStateMap)) return;
    let isCanceled = false;
    const editedTargets = [];
    for (const target of targets) {
      const state = targetStateMap[target.key] ?? null;
      editedTargets.push({ ...target, latestState: state });
    }
    if (!_.isEqual(targets, editedTargets) && !isCanceled) {
      dispatch(setTargets(editedTargets));
    }
    return () => {
      isCanceled = true;
    };
  }, [targetStateMap, dispatch, targets]);

  /**
   * New target submit handler
   * @param {*} targetDoc
   * @returns
   */
  const onTargetSubmit = targetDoc => {
    // exit early if already submitting
    if (targetSubmitting) return;
    // set submitting
    //console.log("submitting target...");
    setTargetSubmitting(true);
    (async () => {
      targetDoc = appendLog(
        logEvents.LOG_CREATE,
        null,
        null,
        targetDoc,
        await currentUserLogInfo(),
      );
      addDoc(collection(db, largeCustomerTargetPath), targetDoc)
        .then(() => {
          //console.log("successfully submitted target");
          setTargetSubmitting(false);
          setTargetModalVisible(false);
          dispatch(setTarget(null));
        })
        .catch(error => {
          console.log("Error while submitting target", error);
          setTargetSubmitting(false);
          setTargetSubmitError("Virhe lähettäessä kohdetta");
        });
    })();
  };

  const openMenu = e => {
    setMenuAnchor(e.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    //setMenuAnchor(null);
  };

  if (!client) {
    return null;
  }

  return (
    <>
      <ClientUpdateModal
        visible={updateModalVisible}
        onHide={() => setUpdateModalVisible(false)}
        contacts={contacts}
      />
      <TargetImportModal
        visible={importModalVisible}
        onHide={() => {
          setImportModalVisible(false);
        }}
        client={client}
      />
      <MassFunctionResultModal
        results={massSubmitResults}
        isVisible={showMassSubmitResults}
        checkedItems={checkedItems}
        onHide={() => {
          setShowMassSubmitResults(false);
          setCheckedItems([]);
        }}
      />
      {target !== null && (
        <TargetUpdateModal
          isVisible={targetModalVisible}
          target={target}
          setTarget={targetDoc => {
            dispatch(setTarget(targetDoc));
          }}
          onHide={() => setTargetModalVisible(false)}
          onSubmit={targetDoc => {
            console.log("target onSubmit");
            onTargetSubmit(targetDoc);
          }}
          client={client}
          submitting={targetSubmitting}
          targetSubmitError={targetSubmitError}
        />
      )}

      <Box m={5} />
      <div className="row mb-4">
        <div className="d-flex flex-row-reverse align-items-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              const newTarget = {
                additionalInfo: "",
                address: "",
                contact: "",
                createdAt: moment().unix(),
                name: "",
                organization: client.key,
                postalCode: "",
                postalDistrict: "",
              };
              dispatch(setTarget(newTarget));
              setTargetModalVisible(true);
            }}>
            LUO KOHDE
          </button>
          <button
            className="btn btn-transparent me-5"
            onClick={() => {
              console.log(TAG, "Import targets");
              setImportModalVisible(true);
            }}>
            TUO KOHTEITA
          </button>

          <button className="btn btn-transparent me-5" onClick={openMenu}>
            MASSATOIMINNOT
          </button>

          <button
            disabled={massExportSubmitting}
            className="btn btn-transparent me-5 d-flex flex-row align-items-center"
            onClick={async () => {
              if (massExportSubmitting) return;
              setMassExportSubmitting(true);

              try {
                const offerLinks = await downloadLatestOffers(checkedItems);
                setMassExportSubmitting(false);
                setMassOfferLinks(offerLinks);
              } catch (error) {
                console.error("Error while mass exporting offers", error);
                setMassExportSubmitting(false);
              }
            }}>
            VIE KORJAUSEHDOTUKSET
            {massExportSubmitting && <Loading />}
          </button>

          {(massOfferSubmitting ||
            massTxSubmitting ||
            massRemoveSubmitting ||
            massReportSubmitting ||
            massExcelSubmitting) && (
            <div className="me-2 spinner-grow text-primary" role="status" />
          )}

          <Menu
            anchorEl={menuAnchor}
            onClose={closeMenu}
            open={menuOpen}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}>
            <MenuItem
              onClick={() => {
                closeMenu();
                massSendOffer();
              }}>
              Lähetä tarjous
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                massCreateNewTx();
              }}>
              Luo uudet kartoitukset
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                massSendReport();
              }}>
              Lähetä raportti
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                massExportExcel();
                console.log("dl excel");
              }}>
              Lataa excel muodossa
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                massRemoveTarget();
              }}>
              Poista valitut kohteet
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Box m={2} />
      {Object.keys(massOfferLinks).length > 0 && (
        <MassOfferLinks massOfferLinks={massOfferLinks} />
      )}
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <ClientInfo
            contacts={contacts}
            setContacts={setContacts}
            client={client}
            showUpdateModal={() => setUpdateModalVisible(true)}
          />
        </div>
        <div className="col-xs-12 col-lg-8">
          <TargetTable
            targets={targets}
            // if targetStateMap is empty (it should only be empty when the states haven't been fetched yet)
            // return false, if there's something return true
            statesFetched={!_.isEmpty(targetStateMap)}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
          />
        </div>
      </div>
    </>
  );
};

ClientDetails.whyDidYouRender = true;

export default ClientDetails;
