import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db, userCollectionPath } from "../../firebase";

/**
 * Contact selector for NewTargetModal
 * @returns
 */
const NewTargetContact = ({ clientKey, onContact }) => {
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);

  /**
   * Constructs a string which is lexicographically greater than
   * the string given as a parameter. This is done by "incrementing the last character by 1".
   * Used for the autocomplete system to prevent some additional results from showing up
   */
  const constructLimitString = useCallback(str => {
    if (str.length <= 0) return "";
    const lastChar = str.slice(-1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const newString = str.slice(0, str.length - 1) + nextChar;
    return newString;
  }, []);

  /**
   * Queries firebase for users
   */
  useEffect(() => {
    let isNotCanceled = true;
    const capitalized = _.startCase(_.toLower(keyword));
    const limitString = constructLimitString(capitalized);
    let q;
    if (capitalized.length === 0) {
      q = query(
        collection(db, userCollectionPath),
        where("organization", "==", clientKey),
        orderBy("name"),
        limit(5),
      );
    } else {
      q = query(
        collection(db, userCollectionPath),
        where("name", ">=", capitalized),
        where("name", "<", limitString),
        where("organization", "==", clientKey),
        orderBy("name"),
        limit(5),
      );
    }
    if (isNotCanceled) {
      (async () => {
        const users = await getDocs(q);
        const userDocs = [];
        users.forEach(doc => {
          userDocs.push(doc.data());
        });
        if (isNotCanceled) setResults(userDocs);
      })();
    }
    return () => {
      isNotCanceled = false;
    };
  }, [constructLimitString, keyword, setResults, clientKey]);

  return (
    <>
      <TextField
        fullWidth={true}
        onChange={e => setKeyword(e.target.value)}
        label="Hae yhteyshenkilöä"
        placeholder="Hakusana"
      />
      {results.map((contact, index) => {
        return (
          <React.Fragment key={contact.uid}>
            <div className="shadow-sm p-1 d-flex flex-row align-items-center justify-content-between">
              <p className="mb-0">{contact.name}</p>
              <button
                className="btn btn-primary"
                onClick={() => onContact(contact)}>
                Valitse
              </button>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NewTargetContact;
